import { Consolidation } from '@returnmates/client-core/src/graphql/generated/api'
import formatPhone from '@returnmates/client-core/src/utils/formatPhone'
import moment from 'moment'

export interface FotmattedTripData extends Consolidation {
  phone: {
    value: string
    formattedValue: string
  }
  date: string
  destination: string
  name: string
  displayType: string
}

export const getFormattedData = (queryData: Consolidation[]): FotmattedTripData[] =>
  // @ts-ignore
  queryData.map(data => ({
    ...data,
    phone: {
      value: data.address?.phone,
      formattedValue: formatPhone(data.address?.phone || ''),
    },
    date: data.createdAt ? moment.unix(data.createdAt).format('MM-DD-yyyy') : null,
    expectedDeliveryDate: data.expectedDeliveryDate
      ? moment(data.expectedDeliveryDate).format('MM-DD-yyyy')
      : null,
    destination: `${data.address?.city}, ${data.address?.street} `,
    name: data.address?.name,
    partnerName: data.partner?.name,
    carrierName: data.carrier?.name,
    locationName: data.locationName || '',
    hubOriginSortCode: data.hubOriginSortCode || '',
    hubDestinationSortCode: data.hubDestinationSortCode || '',
  }))
