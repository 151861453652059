import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  cellIconButton: {
    padding: 0,
    marginRight: 10,
  },
}))

export default useStyles
