import clsx from 'clsx'
import { memo, ReactNode } from 'react'

import FilteredItem from './FilteredItem'
import useStyles from './styles'

interface Props {
  activeStatuses: Array<{ key: string; label: string }>
  onFilteredItemRemove: (val: string) => void
  labelFiler?: {
    activeLabelStatuses?: Array<{ key: string; label: string }>
    onFilteredLabelRemove: (val: string) => void
  }
  className?: string
  children?: ReactNode
}

function FilterBar({
  activeStatuses,
  onFilteredItemRemove,
  labelFiler,
  className,
  children,
}: Props) {
  const classes = useStyles({})

  return (
    <div className={clsx(classes.filterBar, className)}>
      {activeStatuses.map(filteredItem => (
        <FilteredItem
          key={filteredItem.key}
          filteredKey={filteredItem.key}
          filteredItem={filteredItem.label}
          onFilterItemRemove={onFilteredItemRemove}
        />
      ))}
      {labelFiler?.activeLabelStatuses?.map(filteredItem => (
        <FilteredItem
          key={filteredItem.key}
          filteredKey={filteredItem.key}
          filteredItem={filteredItem.label}
          onFilterItemRemove={labelFiler.onFilteredLabelRemove}
          filterContainerClasses={clsx(
            classes.labelFilter,
            classes[`labelType${filteredItem.key}`],
          )}
        />
      ))}
      {children}
    </div>
  )
}

export default memo(FilterBar)
