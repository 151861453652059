import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { memo } from 'react'
import { Field } from 'react-final-form'

import useStyles from '../styles'

interface Props {
  fieldName: string
}

function LocationScansRow({ fieldName }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.createdAt`}>
          {({ input }) => <p>{moment(input.value * 1000).format('MM-DD-YYYY HH:mm')}</p>}
        </Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.locationName`}>{({ input }) => <p>{input.value}</p>}</Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.userEmail`}>
          {({ input }) => (input.value ? <p>{input.value}</p> : '--')}
        </Field>
      </TableCell>
    </TableRow>
  )
}

export default memo(LocationScansRow)
