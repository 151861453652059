import { Tooltip } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import clsx from 'clsx'
import moment from 'moment'
import { memo, useCallback } from 'react'
import { Field } from 'react-final-form'
import { useDispatch } from 'react-redux'

import useStyles from '../styles'
import TrackIdField from './TrackIdField'

interface Props {
  fieldName: string
}

function ScansRow({ fieldName }: Props): JSX.Element {
  const classes = useStyles()
  const dispatch = useDispatch()

  const copyToClipboard = useCallback(
    text => () => {
      navigator.clipboard.writeText(text)

      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.SUCCESS,
          message: `${text} was successfully copied to the clipboard`,
        }),
      )
    },
    [dispatch],
  )

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.createdAt`}>
          {({ input }) => (
            <p className={classes.scanDate}>
              {moment(input.value * 1000).format('MM-DD-YYYY HH:mm')}
            </p>
          )}
        </Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.trackingId`}>
          {({ input }) => (input.value ? <TrackIdField input={input} field={fieldName} /> : '--')}
        </Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.type`}>{({ input }) => <p>{input.value}</p>}</Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.hubSortCode`}>
          {({ input }) => <p>{input.value || '-'}</p>}
        </Field>
      </TableCell>
      <TableCell className={clsx(classes.bodyCell, classes.user)}>
        <Field name={`${fieldName}.user.email`}>
          {({ input }) => (
            <Tooltip title={input.value}>
              <p onClick={copyToClipboard(input.value)}>{input.value || '-'}</p>
            </Tooltip>
          )}
        </Field>
      </TableCell>
    </TableRow>
  )
}

export default memo(ScansRow)
