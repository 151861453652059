import { FormLabel } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { INBOUND_CONSOLIDATION_STATUS_MAP } from '@returnmates/client-core/src/constants/trip'
import {
  AdminPackage,
  Consolidation,
  ConsolidationStatus,
  Package,
} from '@returnmates/client-core/src/graphql/generated/api'
import {
  getCarriers,
  getPackingSlip,
  getRetailers,
} from '@returnmates/client-core/src/selectors/admin'
import { getConsolidationPackages } from '@returnmates/client-core/src/selectors/packages'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import formatPhone from '@returnmates/client-core/src/utils/formatPhone'
import { printBarcode } from '@returnmates/client-core/src/utils/printBarcode'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import clsx from 'clsx'
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DetailsSection from '../../../components/DetailsSection'
import PlusIcon from '../../../components/images/icons/plus'
import UnderscorePencil from '../../../components/images/icons/underscorePencil'
import PackagesTable from '../../../components/PackagesTable'
import StatusSelect from '../../../components/StatusSelect'
import { printDensityOptions } from '../../OutboundConsolidationsTable/constants'
import { getRMLabel } from '../../OutboundConsolidationsTable/utils/labels'
import UploadLabelField from '../../UploadLabelField'
import { formattedPackageColumns } from '../constants'
import useStyles from '../styles'
import DownloadPDFLink from './PDFDoc/DownloadPDFLink'

interface Props {
  row: Consolidation & { date: string }
  onEditDetailsClick?: () => void
  onEditAddressClick?: () => void
  setPackageToRemove?: ({
    consolidationId,
    consolidationPackageId,
  }: {
    consolidationId: string
    consolidationPackageId?: string
  }) => void
  onEditPackageClick?: (params: Package) => void
  isPackagesLoaded?: boolean
  setCurrentTrip?: (params: Consolidation) => void
  onFleetInProgress: Array<string>
  setOnFleetInProgress: Dispatch<SetStateAction<string[]>>
  isNewPackageModalOpen: boolean
  setIsNewPackageModalOpen: Dispatch<SetStateAction<boolean>>
  setIsRemovePackageModalOpen: Dispatch<SetStateAction<boolean>>
  currentRowData?: Consolidation
  setRowData: (row: Consolidation) => void
  isOuterLoading?: boolean
  setPackageConsolidationId?: (consolidationId: string | null) => void
  fetchConsolidationPackages?: () => void
}

function PickupDetails({
  row,
  onEditAddressClick,
  setIsNewPackageModalOpen,
  setIsRemovePackageModalOpen,
  onEditPackageClick: onEditPackageClickProps,
  currentRowData,
  setRowData,
  setPackageToRemove,
  onEditDetailsClick,
  isOuterLoading,
  setPackageConsolidationId,
  fetchConsolidationPackages,
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const packages = useSelector(getConsolidationPackages)
  const [isLabelUploaded, setIsLabelUploaded] = useState(false)
  const [error, setError] = useState<string>()
  const [statusValue, setStatusValue] = useState<ConsolidationStatus>(
    row.status as ConsolidationStatus,
  )
  const [isLoading, setIsLoading] = useState(Boolean(isOuterLoading))
  const [labelUrl, setLabelUrl] = useState<string>()
  const [printDensity, setPrintDensity] = useState<printDensityOptions>(printDensityOptions.dpi203)
  const labelUrlRef = useRef(row.labelUrl)

  const labelUrlProp = useMemo(() => ({ value: labelUrl, onChange: setLabelUrl }), [labelUrl])

  const carriers = useSelector(getCarriers)
  const retailers = useSelector(getRetailers)
  const currentPackingSlip = useSelector(getPackingSlip)

  const printZplCode = useMemo(() => getRMLabel({ row }, printDensity), [row, printDensity])

  const printLabel = useCallback(() => {
    printBarcode(printZplCode)
  }, [printZplCode])

  const onEditPackageClick = useCallback(
    async (row: AdminPackage) => {
      setIsLoading(true)

      try {
        const pack: Package = await createAsyncAction(
          dispatch,
          actions.getPackage.request({ id: row.id }),
        )

        if (row.id) {
          await createAsyncAction(
            dispatch,
            actions.packageTrackingList.request({ packageId: row.id }),
          )
        }

        if (pack) {
          if (setPackageConsolidationId) {
            setPackageConsolidationId(currentRowData?.id || null)
          }

          onEditPackageClickProps?.({
            ...pack,
          })
        }
      } catch (err) {
        const { message } = err as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, setPackageConsolidationId, onEditPackageClickProps, currentRowData?.id],
  )

  const onRemovePackage = useCallback(
    id => {
      const currentPackage = packages?.find(p => p.id === id)

      setIsRemovePackageModalOpen(true)
      setPackageToRemove?.({
        consolidationId: row.id,
        consolidationPackageId: currentPackage?.id,
      })
    },
    [packages, row.id, setIsRemovePackageModalOpen, setPackageToRemove],
  )

  const onEditDetails = useCallback(() => {
    onEditDetailsClick?.()
  }, [onEditDetailsClick])

  const onEditAddressDetails = useCallback(() => {
    onEditAddressClick?.()
  }, [onEditAddressClick])

  const openNewPackageModal = useCallback(() => {
    setIsNewPackageModalOpen(true)
  }, [setIsNewPackageModalOpen])

  useEffect(() => {
    if (setRowData && row) {
      setRowData(row)
    }
  }, [row, setRowData])

  const updateConsolidation = useCallback(
    async ({ status, labelUrl }: { status?: ConsolidationStatus; labelUrl?: string | null }) => {
      try {
        await createAsyncAction(
          dispatch,
          actions.updateConsolidation.request({
            id: row.id,
            input: {
              status,
              labelUrl,
            },
          }),
        )

        await fetchConsolidationPackages?.()
      } catch (err) {
        const { message } = err as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      }
    },
    [dispatch, row.id, fetchConsolidationPackages],
  )

  const fetchPackingSlip = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        actions.getPackingSlipByConsolidationId.request({ id: row.id }),
      )
    } catch (err) {
      const { message } = err as Error

      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    }
  }, [dispatch, row.id])

  const formattedPhone = useMemo(() => formatPhone(row.address?.phone || ''), [row.address])

  const handleUpdateConsolidation = useCallback(
    status => {
      if (status) {
        updateConsolidation({ status })
      }
    },
    [updateConsolidation],
  )

  const handleRemoveFile = useCallback(() => {
    setLabelUrl('')
    setIsLabelUploaded(false)

    updateConsolidation({
      labelUrl: null,
    })
  }, [updateConsolidation])

  const handlePrintDensityUpdate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPrintDensity(event.target.value as printDensityOptions)
  }, [])

  useEffect(() => {
    if (!carriers) {
      dispatch(actions.getCarriers.request())
    }
    if (!retailers) {
      dispatch(actions.getRetailers.request())
    }
  }, [carriers, dispatch, retailers])

  useEffect(() => {
    if (row.labelUrl) {
      setLabelUrl(row.labelUrl)
    }
  }, [row.labelUrl])

  useEffect(() => {
    if (isLabelUploaded && labelUrlRef.current !== labelUrl) {
      updateConsolidation({
        labelUrl,
      })

      labelUrlRef.current = labelUrl
    }
  }, [isLabelUploaded, labelUrl, updateConsolidation])

  useEffect(() => {
    fetchPackingSlip()
  }, [currentPackingSlip, fetchPackingSlip])

  return (
    <div className={classes.rowDetailsWrapper}>
      <div className={classes.mainDetails}>
        <DetailsSection
          sectionTitle="Details"
          icon={UnderscorePencil}
          onIconClick={onEditDetails}
          className={classes.detailsSection}
        >
          <div className={classes.detailsBody}>
            <div className={classes.detailsGroup}>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Status</div>
                <div className={classes.detailsData}>
                  <StatusSelect
                    value={statusValue}
                    onSubmit={handleUpdateConsolidation}
                    onChangeStatusValue={setStatusValue}
                    values={INBOUND_CONSOLIDATION_STATUS_MAP}
                  />
                </div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Created Date</div>
                <div className={classes.detailsData}>{row.date || '-'}</div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Expected Departure Date</div>
                <div className={classes.detailsData}>{row.expectedDeliveryDate || '-'}</div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Partner</div>
                <div className={classes.detailsData}>{row.partner?.name}</div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Weight</div>
                <div className={classes.detailsData}>{row.weight || '-'}</div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}># of Packages</div>
                <div className={classes.detailsData}>{row.packageCount || 0}</div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Carrier</div>
                <div className={classes.detailsData}>{row.carrier?.name || '-'}</div>
              </div>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Carrier Tracking ID</div>
                <div className={classes.detailsData}>{row.trackingId || '-'}</div>
              </div>
            </div>
          </div>
        </DetailsSection>
        <DetailsSection sectionTitle="Labels & Manifest" className={classes.mediaSection}>
          <div className={classes.mediaInner}>
            <div className={classes.mediaBlock}>
              <p className={clsx(classes.detailsLabel)}>
                {isLabelUploaded ? 'Label:' : 'Upload label:'}
              </p>
              <UploadLabelField
                labelUrl={labelUrlProp}
                setError={setError}
                setIsLabelUploaded={setIsLabelUploaded}
                error={error}
                dndStyles={classes.dndStyles}
                dropZoneLabel={
                  <p className={classes.dropZoneLabel}>
                    Drag & Drop or <span> Upload</span>
                  </p>
                }
                onRemoveFile={handleRemoveFile}
              />
            </div>
            <div className={classes.mediaBlock}>
              <p className={clsx(classes.detailsLabel)}>Packing Slip:</p>
              <DownloadPDFLink
                details={{
                  ...row,
                  currentPackingSlip,
                }}
              />
            </div>
            <div className={classes.mediaBlock}>
              <FormLabel className={clsx(classes.detailsLabel)}>Print Density</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  checked={printDensity === printDensityOptions.dpi203}
                  onChange={handlePrintDensityUpdate}
                  value={printDensityOptions.dpi203}
                  control={<Radio />}
                  label={printDensityOptions.dpi203}
                />
                <FormControlLabel
                  checked={printDensity === printDensityOptions.dpi300}
                  onChange={handlePrintDensityUpdate}
                  value={printDensityOptions.dpi300}
                  control={<Radio />}
                  label={printDensityOptions.dpi300}
                />
              </RadioGroup>
            </div>
            <div className={classes.mediaBlock}>
              <p className={clsx(classes.detailsLabel)}>RM Label:</p>
              <p className={classes.PDFLink} onClick={printLabel}>
                Print RM Label
              </p>
            </div>
          </div>
        </DetailsSection>
        <DetailsSection
          sectionTitle="Destination"
          icon={UnderscorePencil}
          onIconClick={onEditAddressDetails}
        >
          <div className={clsx(classes.detailsBody, classes.addressDetails)}>
            <div className={classes.topRow}>
              <div className={classes.detailsGroup}>
                <div className={classes.detailsDataRow}>
                  <div className={classes.detailsLabel}>Name</div>
                  <div className={classes.detailsData}>{row.address?.name || '-'}</div>
                </div>
                <div className={classes.detailsDataRow}>
                  <div className={classes.detailsLabel}>Phone</div>
                  <div className={classes.detailsData}>{formattedPhone || '-'}</div>
                </div>
                <div className={classes.detailsDataRow}>
                  <div className={classes.detailsLabel}>Street</div>
                  <div className={classes.detailsData}>{row.address?.street}</div>
                </div>
                {row.address?.unit ? (
                  <div className={classes.detailsDataRow}>
                    <div className={classes.detailsLabel}>Building</div>
                    <div className={classes.detailsData}>{row.address.unit}</div>
                  </div>
                ) : null}
                <div className={classes.detailsDataRow}>
                  <div className={classes.detailsLabel}>City/State/Zip</div>
                  <div className={classes.detailsData}>
                    {`${row.address?.city}, ${row.address?.state}, ${row.address?.zipCode}` || '-'}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.fullWidthRow}>
              <div className={classes.detailsDataRow}>
                <div className={classes.detailsLabel}>Instructions</div>
                <div className={classes.detailsData}>{row.address?.instructions || '-'}</div>
              </div>
            </div>
          </div>
        </DetailsSection>
      </div>
      <div className={classes.packagesInfo}>
        <DetailsSection
          className={classes.packagesTable}
          sectionTitle="Packages"
          icon={PlusIcon}
          onIconClick={openNewPackageModal}
        >
          <PackagesTable
            packages={packages || []}
            columns={formattedPackageColumns}
            showToolbar={false}
            canDeleteRow
            editOnRowClick
            isLoading={isLoading}
            setEditableRow={onEditPackageClick}
            onRemovePackage={onRemovePackage}
            canSelectRow={false}
          />
        </DetailsSection>
      </div>
    </div>
  )
}

export default memo(PickupDetails)
