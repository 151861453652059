import {
  AddInboundConsolidationInput,
  AddOutboundConsolidationInput,
} from '@returnmates/client-core/src/type/consolidation'
import inboundValidation from '@returnmates/client-core/src/utils/validation/createConsolidation'
import outboundValidation from '@returnmates/client-core/src/utils/validation/createOutboundConsolidation'
import Button from '@returnmates/ui-core/src/components/Button'
import ErrorBlock from '@returnmates/ui-core/src/components/ErrorBlock'
import Modal from '@returnmates/ui-core/src/components/Modal'
import clsx from 'clsx'
import { memo } from 'react'
import { Form } from 'react-final-form'

import useStyles from '../styles'
import InboundConsolidationModalDetails from './InboundConsolidationModalDetails'
import OutboundConsolidationModalDetails from './OutboundConsolidationModalDetails'

interface Props {
  onClose: () => void
  isOpen: boolean
  onCreate: (input: AddInboundConsolidationInput | AddOutboundConsolidationInput) => void
  initialValues: AddInboundConsolidationInput | AddOutboundConsolidationInput | null
  loading: boolean
  isInboundModal: boolean
}

function AddConsolidationModal({
  isOpen,
  onClose,
  onCreate,
  initialValues,
  loading,
  isInboundModal,
}: Props) {
  const classes = useStyles()

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={448}>
      <div className={classes.modalWrapper}>
        <p className={classes.modalHeader}>Add Consolidation</p>
        <Form
          onSubmit={onCreate}
          initialValues={initialValues}
          validate={isInboundModal ? inboundValidation : outboundValidation}
          render={({ handleSubmit, submitError }) => (
            <>
              <ErrorBlock message={submitError} />
              {isInboundModal ? (
                <InboundConsolidationModalDetails />
              ) : (
                <OutboundConsolidationModalDetails />
              )}
              <div className={classes.modalButtons}>
                <Button
                  className={clsx(classes.button, classes.cancelButton, classes.modalCancelButton)}
                  onClick={onClose}
                  label="Cancel"
                  cancelButton
                />
                <Button
                  className={clsx(classes.button, classes.saveButton, classes.modalSaveButton)}
                  onClick={handleSubmit}
                  label="Add Consolidation"
                  isLoading={loading}
                />
              </div>
            </>
          )}
        />
      </div>
    </Modal>
  )
}

export default memo(AddConsolidationModal)
