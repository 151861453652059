interface Props {
  className?: string
  onClick?: () => void
}

const Delete = ({ className, onClick }: Props): JSX.Element => (
  <svg
    width="18"
    height="18"
    className={className}
    onClick={onClick}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8.5" fill="#A7B4C8" stroke="white" />
    <path
      d="M12.5 5.5L5.5 12.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 5.5L12.5 12.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Delete
