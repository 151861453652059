import { PagingPanel } from '@devexpress/dx-react-grid-material-ui'
import TablePagination from '@mui/material/TablePagination'
import { memo, useCallback } from 'react'

const Pager = ({
  currentPage,
  onCurrentPageChange: onCurrentPageChangeProps,
  pageSize,
  onPageSizeChange,
  pageSizes,
  totalCount,
}: PagingPanel.ContainerProps) => {
  const onRowsPerPageChange = useCallback(
    e => {
      const { value } = e.target
      onPageSizeChange(value)
    },
    [onPageSizeChange],
  )

  const onCurrentPageChange = useCallback(
    (_, page) => {
      onCurrentPageChangeProps(page)
    },
    [onCurrentPageChangeProps],
  )

  return (
    <div>
      <TablePagination
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={currentPage}
        onPageChange={onCurrentPageChange}
        rowsPerPageOptions={pageSizes}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </div>
  )
}

export default memo(Pager)
