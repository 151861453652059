interface Props {
  className?: string
}

const MapMarkerActive = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="16"
    height="24"
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.95542 1.84923C6.40246 0.427396 9.41027 0.452248 11.8343 1.91433C14.2346 3.40619 15.6934 6.06873 15.6799 8.93287C15.6239 11.7782 14.0597 14.4528 12.1044 16.5205C10.9758 17.7192 9.71333 18.7792 8.3427 19.6788C8.20154 19.7604 8.04692 19.8151 7.88646 19.84C7.73203 19.8335 7.58164 19.7878 7.44884 19.7073C5.35632 18.3556 3.52053 16.6302 2.02981 14.6141C0.782418 12.9312 0.0737347 10.898 0 8.7906L0.00558457 8.48402C0.107433 5.73325 1.59577 3.22029 3.95542 1.84923ZM8.85614 6.439C7.86136 6.01615 6.71445 6.24588 5.95092 7.02092C5.18738 7.79596 4.95784 8.96342 5.36946 9.9782C5.78109 10.993 6.75268 11.6549 7.8306 11.6549C8.53676 11.66 9.21557 11.3771 9.71579 10.8694C10.216 10.3616 10.4961 9.67116 10.4936 8.95183C10.4973 7.85385 9.85091 6.86184 8.85614 6.439Z"
      fill="#635DF6"
    />
    <path
      d="M7.83999 23.2001C10.9328 23.2001 13.44 22.6986 13.44 22.0801C13.44 21.4615 10.9328 20.9601 7.83999 20.9601C4.7472 20.9601 2.23999 21.4615 2.23999 22.0801C2.23999 22.6986 4.7472 23.2001 7.83999 23.2001Z"
      fill="#635DF6"
    />
  </svg>
)

export default MapMarkerActive
