import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  ERROR,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  pickups: {
    padding: '30px 35px',
    position: 'relative',
    '&  td': {
      cursor: 'pointer',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pickupsGroup: {
    position: 'relative',
    marginTop: 16,
    transition: 'all 0.3s',
  },
  showFilterPanel: {
    marginTop: 60,
  },
  appBar: {
    backgroundColor: '#FFFFFF',
    color: MIDNIGHT_EXPRESS,
    boxShadow: 'none',
  },
  tab: {
    minWidth: 'auto',
    marginRight: '45px',
    textTransform: 'capitalize',
    '& > span': {
      alignItems: 'flex-start',
    },
  },
  tabPanel: {
    padding: 0,
  },
  hidden: {
    display: 'none',
  },
  headerButtons: {
    zIndex: 2,
  },
  buttonGroup: {
    display: 'flex',
    '& > button': {
      height: '36px',
    },
  },
  modalButtons: {
    display: 'flex',
    marginTop: '30px',
  },
  addTripButton: {
    padding: '10px 23px',
  },
  plusIcon: {
    '& path': {
      stroke: '#FFFFFF',
    },
  },
  modalWrapper: {
    padding: '55px 25px 15px',
    overflowX: 'hidden',
  },
  modalHeader: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '130%',
    color: MIDNIGHT_EXPRESS,
    textAlign: 'center',
  },
  button: {
    textTransform: 'capitalize',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '130px',
  },
  cancelButton: {
    marginRight: '10px',
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  modalCancelButton: {
    minWidth: '150px',
  },
  saveButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  modalSaveButton: {
    width: '100%',
  },
  modalDetailsRow: {
    marginTop: '30px',
    textAlign: 'left',
    '&:last-child': {
      marginBottom: '30px',
    },
  },
  modalGroupRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalDetailsTitle: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    marginBottom: '15px',
  },
  modalDetailsTitleSwap: {
    verticalAlign: 'middle',
  },
  instructionInputs: {
    height: '100px',
    '& div': {
      height: '100%',
      '& textarea:first-of-type': {
        height: '100% !important',
        boxSizing: 'border-box',
      },
    },
  },
  toggleButton: {
    padding: '7px 20px',
  },
  error: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    textAlign: 'start',
  },
  datePicker: {
    '& > div': {
      border: `1px solid ${SOLITUDE}`,
      height: '48px',
      '& > div': {
        display: 'flex',
      },
    },
  },
  optionList: {
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '8px',
    boxSizing: 'border-box',
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
  },
  optionPrimary: {
    fontFamily: POPPINS,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    cursor: 'pointer',
  },
  optionSecondary: {
    fontWeight: 500,
    fontSize: 13,
  },
  errorHandler: {
    position: 'relative',
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
  },
  checkbox: {
    color: NEON_BLUE_LIGHT,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: NEON_BLUE_LIGHT,
    },
  },
  modalDetailsRowSwap: {
    marginTop: '10px',
    textAlign: 'left',
    '&:last-child': {
      marginBottom: '30px',
    },
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    fontSize: 14,
    lineHeight: '150%',
    marginBottom: 10,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: 10,
    '& svg': {
      marginRight: 6,
      marginBottom: 1,
    },
  },
  paperList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 305,
  },
  submitError: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
  },
  newAddressDropdown: {
    zIndex: 3,
    backgroundColor: '#FFFFFF',
    padding: '10px 30px',
    '& > div': {
      maxWidth: '100%',
    },
    '& input': {
      padding: '7px 20px',
    },
  },
}))

export default useStyles
