import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Button from '@returnmates/ui-core/src/components/Button'
import clsx from 'clsx'
import moment from 'moment'
import { memo, useCallback, useEffect, useState } from 'react'

import CalendarIcon from '../images/icons/calendar'
import SingleDatePicker from '../SingleDatePicker'
import useStyles from './styles'

interface Props {
  onDateUpdate: (isScheduled?: boolean) => void
  setDateForUpdate: (val: string) => void
  dateForUpdate: string
  isLoading: boolean
  selectedScheduledTrips?: string[]
  minDate?: string | null
  maxDate?: string | null
  unavailableDates?: Array<string>
  show?: boolean
}

function Calendar({
  onDateUpdate,
  setDateForUpdate,
  dateForUpdate,
  isLoading,
  selectedScheduledTrips,
  show = true,
}: Props) {
  const classes = useStyles()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [isScheduled, setIsScheduled] = useState(true)

  const toggleDatePicker = useCallback(() => {
    setShowDatePicker(!showDatePicker)
  }, [showDatePicker])

  const onCancel = useCallback(() => {
    setShowDatePicker(false)
  }, [])

  const onDateChange = useCallback(
    date => {
      setDateForUpdate(moment(date).format('YYYY-MM-DD'))
    },
    [setDateForUpdate],
  )

  const handleChange = useCallback(() => {
    setIsScheduled(isScheduled => !isScheduled)
  }, [])

  const onDateApply = useCallback(() => {
    setShowDatePicker(false)
    onDateUpdate(isScheduled)
  }, [isScheduled, onDateUpdate])

  useEffect(() => {
    if (!selectedScheduledTrips?.length) {
      setIsScheduled(false)
    } else {
      setIsScheduled(true)
    }
  }, [selectedScheduledTrips])

  return (
    <div className={clsx(classes.calendarWrapper, !show && classes.hidden)}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title="Change Package Date"
      >
        <IconButton className={classes.iconButton} onClick={toggleDatePicker} disabled={isLoading}>
          <CalendarIcon className={classes.calendarIcon} />
        </IconButton>
      </Tooltip>
      {showDatePicker ? (
        <div className={classes.toolbarDatePicker}>
          <SingleDatePicker
            value={dateForUpdate as string}
            onChange={onDateChange}
            disablePast
            isStatic
            momentFormat="YYYY-MM-DD"
          />
          <div className={classes.scheduleContainer}>
            {selectedScheduledTrips?.length ? (
              <>
                <Checkbox checked={isScheduled} onClick={handleChange} color="primary" />
                <span className={classes.scheduleText}>Schedule OnFleet Tasks</span>
              </>
            ) : null}
          </div>
          <div className={classes.modalButtons}>
            <Button
              className={clsx(classes.button, classes.cancelButton)}
              onClick={onCancel}
              label="Cancel"
            />
            <Button
              className={clsx(classes.button, classes.applyButton)}
              onClick={onDateApply}
              label="Save"
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default memo(Calendar)
