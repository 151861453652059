interface Props {
  className?: string
}

const CancelIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.25" stroke="#E42000" strokeWidth="1.5" />
    <path
      d="M15.8 8.6L8.59998 15.8"
      stroke="#E42000"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.59998 8.6L15.8 15.8"
      stroke="#E42000"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CancelIcon
