interface Props {
  className?: string
}

const ActiveQr = ({ className }: Props): JSX.Element => (
  <svg
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.239 11.9588C22.659 11.9588 23 11.6244 23 11.2115C23 10.7997 22.659 10.4663 22.239 10.4663H21.177V6.90671C21.177 4.75135 19.39 3 17.194 3H15.601C15.18 3 14.839 3.33438 14.839 3.74722C14.839 4.15907 15.18 4.49345 15.601 4.49345H17.194C18.551 4.49345 19.655 5.57506 19.655 6.90671V10.4663H17.032V9.47491C17.042 8.23445 16.027 7.2205 14.762 7.20874H9.238C7.974 7.2205 6.959 8.23445 6.969 9.47491V10.4663H4.346V6.90279C4.346 5.57604 5.447 4.4964 6.8 4.49443L8.428 4.49345C8.848 4.49345 9.189 4.15907 9.189 3.74722C9.188 3.33438 8.848 3 8.427 3L6.798 3.00098C4.607 3.00294 2.823 4.75331 2.823 6.90279V10.4663H1.762C1.342 10.4663 1 10.7997 1 11.2115C1 11.6244 1.342 11.9588 1.762 11.9588H22.239ZM14.7625 17.2573C15.3675 17.2573 15.9485 17.0191 16.3715 16.5945C16.7975 16.1659 17.0335 15.5903 17.0325 14.9912V13.7458C17.0275 13.5997 16.9085 13.481 16.7595 13.4771H7.2395C7.0915 13.482 6.9725 13.6007 6.96941 13.7458V14.9912C6.9585 16.2316 7.9745 17.2446 9.2385 17.2573H14.7625ZM17.1936 20.9997C19.3906 20.9997 21.1766 19.2484 21.1766 17.094V15.2377C21.1766 14.8258 20.8366 14.4905 20.4156 14.4905C19.9956 14.4905 19.6546 14.8258 19.6546 15.2377V17.094C19.6546 18.4246 18.5506 19.5072 17.1936 19.5072H15.6006C15.1806 19.5072 14.8396 19.8406 14.8396 20.2525C14.8396 20.6653 15.1806 20.9997 15.6006 20.9997H17.1936ZM8.4277 21C8.8477 21 9.1887 20.6656 9.1887 20.2528C9.1887 19.8409 8.8487 19.5065 8.4277 19.5065L6.7987 19.5056C5.4467 19.5036 4.3467 18.424 4.3467 17.0972V15.238C4.3467 14.8261 4.0047 14.4908 3.5847 14.4908C3.1647 14.4908 2.8237 14.8261 2.8237 15.238V17.0972C2.8237 19.2467 4.6067 20.9971 6.7977 20.999L8.4277 21Z"
      fill="#635DF6"
    />
  </svg>
)

export default ActiveQr
