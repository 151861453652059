import { DataTypeProvider } from '@devexpress/dx-react-grid'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import {
  LABEL_TYPE_MAP,
  PACKAGE_CONDITION_MAP,
  PACKAGE_STATUS_MAP,
} from '@returnmates/client-core/src/constants/trip'
import {
  AdminPackage,
  LabelType,
  PackageCondition,
  PackageDelayReasonType,
  PackageStatus,
  ScanType,
  TripStatus,
  TripType,
} from '@returnmates/client-core/src/graphql/generated/api'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import CheckboxIcon from '@returnmates/ui-core/src/components/images/checkboxIcon'
import ExternalLink from '@returnmates/ui-core/src/components/images/icons/externalLink'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getStatusIcons } from '../../pages/Main/constants'
import CellIcon from '../CellIcon'
import ActiveCar from '../images/icons/activeCar'
import ActivePackageIcon from '../images/icons/activePackage'
import Car from '../images/icons/car'
import PackageIcon from '../images/icons/package'
import TrackingIdLink from '../TrackingIdLink'
import useStyles from './styles'

function ProviderFormatter({ value, column, row }: DataTypeProvider.ValueFormatterProps) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [location, setLocation] = useState<{ latitude: number; longitude: number } | null>(null)
  const [locationError, setLocationError] = useState<null | string>('')
  const [isPackageBtnActive, setIsPackageBtnActive] = useState(false)
  const [isDepartureBtnActive, setIsDepartureBtnActive] = useState(false)

  const { status, id, tripType, carrierName, trackingId } = row as AdminPackage

  const openFile = useCallback(
    e => {
      if (value.url) {
        e.preventDefault()
        e.stopPropagation()

        window.open(value.url, '_blank')?.focus()
      }
    },
    [value],
  )

  const processScan = useCallback(
    async type => {
      try {
        await createAsyncAction(
          dispatch,
          actions.processQRCodeScan.request({
            packageId: id,
            type,
            latitude: location?.latitude || 0,
            longitude: location?.longitude || 0,
          }),
        )
      } catch (err) {
        const { message } = err as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      }
    },
    [dispatch, id, location],
  )

  const updatePackage = useCallback(
    async status => {
      try {
        await createAsyncAction(
          dispatch,
          actions.updatePackage.request({
            id: row.id,
            input: {
              labelType: row.labelType?.label as LabelType,
              status,
              condition: row.condition,
              tripId: row.tripId,
            },
          }),
        )
      } catch (err) {
        const { message } = err as Error
        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      }
    },
    [dispatch, row],
  )

  const checkLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position =>
          setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude }),
        () => {
          const geolocationErrorShown = sessionStorage.getItem('geolocationErrorShown')

          if (!geolocationErrorShown) {
            sessionStorage.setItem('geolocationErrorShown', 'true')
            setLocationError('You should enable geolocation')
          }
        },
        { timeout: 1000, enableHighAccuracy: false },
      )
    } else {
      setLocationError("Geolocation isn't available on your device")
    }
  }, [])

  const onDepartureBtnClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      checkLocation()

      if (isDepartureBtnActive) {
        setIsDepartureBtnActive(false)
        updatePackage(PackageStatus.DEPARTED)
        processScan(ScanType.DEPARTURE)
      }
    },
    [checkLocation, isDepartureBtnActive, processScan, updatePackage],
  )

  const onDeliveryBtnClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      checkLocation()

      if (isPackageBtnActive) {
        setIsPackageBtnActive(false)
        updatePackage(PackageStatus.DELIVERED)
        processScan(ScanType.CARRIER)
      }
    },
    [checkLocation, isPackageBtnActive, processScan, updatePackage],
  )

  useEffect(() => {
    checkLocation()

    return () => {
      setLocation(null)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (locationError) {
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: locationError,
        }),
      )
    }
  }, [dispatch, locationError])

  useEffect(() => {
    const isDepartureActive = Boolean(
      status === PackageStatus.RECEIVED && tripType === TripType.PICKUP,
    )

    setIsDepartureBtnActive(isDepartureActive)
  }, [status, tripType])

  useEffect(() => {
    const isPackageActive = Boolean(
      status === PackageStatus.DEPARTED &&
        tripType === TripType.PICKUP &&
        (trackingId || carrierName?.toUpperCase() === 'AMAZON') &&
        carrierName,
    )

    setIsPackageBtnActive(isPackageActive)
  }, [carrierName, status, trackingId, tripType])

  return (
    <>
      {column.name === 'status' ? (
        <div className={classes.iconCell}>
          <CellIcon iconComponent={getStatusIcons(value as TripStatus | PackageStatus)} />
          <span>
            {row.status === PackageStatus.DELAYED &&
            row?.packageDelayReason === PackageDelayReasonType.HUB_MISSORT
              ? 'Delayed (Mis-sort)'
              : PACKAGE_STATUS_MAP[value as PackageStatus]}
          </span>
        </div>
      ) : null}
      {column.name === 'labelType' ? (
        <div
          className={clsx(
            {
              [classes.linkFormatterHyperlink]: value.url,
              [classes.noUrl]: value.label === LabelType.DIGITAL && !value.url,
            },
            classes.linkFormatterWrapper,
          )}
          onClick={openFile}
        >
          {LABEL_TYPE_MAP[value.label as LabelType]}
        </div>
      ) : null}
      {column.name === 'condition' ? PACKAGE_CONDITION_MAP[value as PackageCondition] : null}
      {column.name === 'carrier' ? (
        <div className={classes.carrierCell}>
          {value}
          {value && row.trackingId ? (
            <TrackingIdLink value={row.trackingId} carrier={value} icon={ExternalLink} />
          ) : null}
        </div>
      ) : null}
      {column.name === 'requiresBox' ? (
        <div className={classes.linkIcon}>
          {!value ? (
            <div className={classes.linkIcon}>
              <CellIcon iconComponent={CheckboxIcon} />
            </div>
          ) : null}
        </div>
      ) : null}
      {column.name === 'actionButton' ? (
        status === PackageStatus.DEPARTED || status === PackageStatus.DELIVERED ? (
          <div className={classes.linkIcon} onClick={onDeliveryBtnClick}>
            <CellIcon
              iconComponent={isPackageBtnActive ? ActivePackageIcon : PackageIcon}
              tooltipTitle="DELIVERED TO CARRIER"
              disabled={!isPackageBtnActive}
              disabledIconStyles={classes.disabledIcon}
            />
          </div>
        ) : (
          <div className={classes.linkIcon} onClick={onDepartureBtnClick}>
            <CellIcon
              iconComponent={isDepartureBtnActive ? ActiveCar : Car}
              tooltipTitle="DEPARTED WAREHOUSE"
              disabled={!isDepartureBtnActive}
              disabledIconStyles={classes.disabledIcon}
            />
          </div>
        )
      ) : null}
    </>
  )
}

export default memo(ProviderFormatter)
