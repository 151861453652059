interface Props {
  className?: string
  strokeColor?: string
  width?: string
  height?: string
}

const CloseIcon = ({ className, strokeColor, width, height }: Props): JSX.Element => (
  <svg
    className={className}
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke={strokeColor || '#A7B4C8'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke={strokeColor || '#A7B4C8'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CloseIcon
