import Button from '@returnmates/ui-core/src/components/Button'
import ErrorBlock from '@returnmates/ui-core/src/components/ErrorBlock'
import clsx from 'clsx'
import { memo, ReactNode } from 'react'

import CloseIcon from '../images/icons/close'
import FormHeaderFields from './FormHeaderFields'
import useStyles from './styles'

interface Props {
  onClose: () => void
  tripSection: string
  children: ReactNode
  handleSubmit: () => void
  headerFields: Array<{ label: string; value: string }>
  error?: string
  isLoading?: boolean
  width?: number
}

function FormSidebar({
  onClose,
  tripSection,
  headerFields,
  children,
  handleSubmit,
  error,
  isLoading,
  width,
}: Props) {
  const classes = useStyles({ width })

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          <div className={classes.title}>Edit {tripSection}</div>

          {headerFields.map(field => (
            <FormHeaderFields key={field.value} label={field.label} value={field.value} />
          ))}
        </div>
        <div className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <ErrorBlock message={error} className={classes.errorBlock} />
      <div className={classes.detailsBlock}>{children}</div>
      <div className={classes.actionButtons}>
        <Button
          className={clsx(classes.button, classes.cancelButton)}
          onClick={onClose}
          label="Cancel"
          cancelButton
        />
        <Button
          className={clsx(classes.button, classes.saveButton)}
          onClick={handleSubmit}
          label="Save"
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default memo(FormSidebar)
