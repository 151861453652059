import { makeStyles } from '@mui/styles'
import {
  ERROR,
  GRAYISH_BLUE,
  NEON_BLUE_LIGHT,
  SLATE_GREY,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

const useStyles = makeStyles(() => ({
  iconCell: {
    display: 'flex',
    alignItems: 'center',
  },
  linkFormatterHyperlink: {
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
  },
  linkFormatterWrapper: {},
  carrierCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderTop: `1px solid ${SOLITUDE}`,
    padding: '15px 0',
    '& > *': {
      marginTop: 5,
    },
  },
  receivedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: GRAYISH_BLUE,
  },
  receivedIcon: {
    marginRight: 0,
    color: NEON_BLUE_LIGHT,
  },
  disabledIcon: {
    cursor: 'not-allowed',
    pointerEvents: 'all',
    color: 'rgba(167, 180, 200, .4)',
    opacity: 0.6,
  },
  filterPanel: {
    position: 'absolute',
    top: -50,
    right: 0,
    width: 'calc(100% - 250px)',
  },
  noUrl: {
    color: ERROR,
  },
  undersCheckbox: {
    marginLeft: '15px',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  printLabelsContainer: {
    position: 'relative',
    marginLeft: '15px',
  },
  printLabelsButton: {
    background: NEON_BLUE_LIGHT,
    borderRadius: '20px',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    width: 145,
    height: '38px',
    fontSize: '13px',
  },
  '@keyframes fadeInDown': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  popupContainer: {
    width: 200,
    position: 'absolute',
    top: 45,
    zIndex: 999,
    background: '#FFFEFE',
    border: '1px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '20px',
    padding: '10px 5px 5px 5px',
    animation: `$fadeInDown 0.3s ease-out`,
  },
  counterWrap: {
    maxHeight: 30,
  },
  counterButton: {
    borderRadius: 0,
    maxHeight: 'inherit',
    padding: '0 20px',
  },
  counterInput: {
    '& > div': {
      borderRadius: 0,
    },
  },
  closeIconContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    '& > svg': {
      height: 16,
      cursor: 'pointer',
    },
  },
  radioItemContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      padding: 7,
    },
  },
  radioGroupContainer: {},
  printButton: {
    background: NEON_BLUE_LIGHT,
    borderRadius: '20px',
    marginBottom: '10px',
    height: '20px',
    marginTop: 15,
    fontSize: 13,
  },
  printOption: {
    fontSize: 13,
  },
  printOptionDisabled: {
    color: SLATE_GREY,
  },
}))

export default useStyles
