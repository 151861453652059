import { getCarriers, getPartners } from '@returnmates/client-core/src/selectors/admin'
import { getLocations } from '@returnmates/client-core/src/selectors/location'
import SearchToolbar from '@returnmates/ui-core/src/components/SearchToolbar'
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Context } from '../../../pages/Main'
import CheckboxFilter from '../../CheckboxFilter'
import FilterBar from '../../FilterBar'
import { checkboxFilter, ConsolidationFilter, searchFields } from '../constants'
import useStyles from '../styles'

interface Props {
  searchPlaceholder?: string
  isLoading: boolean
  isFilterActive?: boolean
  setIsFilterActive?: (val: boolean) => void
  onFilterUpdate?: (val: ConsolidationFilter) => void
}

function Toolbar({ searchPlaceholder, isFilterActive, setIsFilterActive, onFilterUpdate }: Props) {
  const classes = useStyles()
  const { currentHubId } = useContext(Context)
  const [searchValue, setSearchValue] = useState<string | null>(null)
  const [searchField, setSearchField] = useState<string>('consolidationId')
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: boolean }>({})

  const partners = useSelector(getPartners)
  const carriers = useSelector(getCarriers)
  const locations = useSelector(getLocations)

  const partnerValues = useMemo<Array<{ key: string; label: string }>>(() => {
    const partnerOptions = partners.reduce((acc, partner) => {
      if (partner.code !== 'rm') {
        return [
          ...acc,
          {
            key: partner.code as string,
            label: partner.name as string,
          },
        ]
      }

      return acc
    }, [])

    return [{ key: 'rm', label: 'Returnmates' }, ...partnerOptions]
  }, [partners])

  const carrierValues = useMemo<Array<{ key: string; label: string }>>(() => {
    const carrierOptions = carriers?.map(carrier => ({
      key: carrier.id as string,
      label: carrier.name as string,
    }))

    return [...(carrierOptions || [])]
  }, [carriers])

  const locationValues = useMemo(
    () =>
      locations?.map(location => ({
        key: location.id,
        label: location.name,
      })),
    [locations],
  )

  const statusFilter = useMemo(
    () =>
      checkboxFilter.values.reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [selectedValues],
  )

  const partnerFilter = useMemo(
    () =>
      partnerValues.reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [selectedValues, partnerValues],
  )

  const carrierFilter = useMemo(
    () =>
      carrierValues.reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [selectedValues, carrierValues],
  )

  const locationFilter = useMemo(
    () =>
      (locationValues || []).reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [locationValues, selectedValues],
  )

  const handleChangeSearch = useCallback(e => {
    const searchValue = e.target.value
    setSearchValue(searchValue)
  }, [])

  const onSearchFieldChange = useCallback((searchField: string) => {
    setSearchField(searchField)
  }, [])

  const handleClearSearch = useCallback(() => {
    setSearchValue('')
  }, [])

  const onChangeFilter = useCallback(
    newSelection => {
      setSelectedValues({
        ...selectedValues,
        ...newSelection,
      })
    },
    [selectedValues],
  )

  const commonFilterValues = useMemo(
    () => [...checkboxFilter.values, ...partnerValues, ...carrierValues, ...(locationValues || [])],
    [partnerValues, carrierValues, locationValues],
  )

  const onFilteredItemRemove = useCallback(
    val => {
      const itemToRemove = commonFilterValues.find(item => item.key === val)?.key

      if (itemToRemove) {
        onChangeFilter({
          [itemToRemove]: false,
        })
      }
    },
    [commonFilterValues, onChangeFilter],
  )

  const activeFilters = useMemo(
    () =>
      Object.keys(selectedValues).reduce((acc, item) => {
        const value = commonFilterValues.find(val => val.key === item)
        if (selectedValues[item] && value) {
          return [
            ...acc,
            {
              key: value?.key,
              label: value?.label,
            },
          ]
        }
        return acc
      }, []),
    [commonFilterValues, selectedValues],
  )

  useEffect(() => {
    if (activeFilters.length) {
      setIsFilterActive?.(true)
    } else {
      setIsFilterActive?.(false)
    }
  }, [activeFilters, setIsFilterActive])

  const updateFilters = useCallback(() => {
    onFilterUpdate?.({
      status: statusFilter,
      partnerCode: partnerFilter,
      carrierId: carrierFilter,
      locationId: locationFilter,
      [searchField]: searchValue ? searchValue : null,
    })
  }, [
    onFilterUpdate,
    statusFilter,
    partnerFilter,
    carrierFilter,
    locationFilter,
    searchField,
    searchValue,
  ])

  useEffect(() => {
    updateFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilterUpdate, statusFilter, partnerFilter, carrierFilter, locationFilter])

  useEffect(() => {
    if (!searchValue?.length) {
      updateFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  return (
    <div className={classes.toolbar}>
      <SearchToolbar
        value={searchValue}
        onInputChange={handleChangeSearch}
        onSearchFieldChange={onSearchFieldChange}
        clearSearch={handleClearSearch}
        placeholder={searchPlaceholder}
        isShowSearchButton
        onSearch={updateFilters}
        searchField={searchField}
        searchFields={searchFields}
        width={500}
      />
      <CheckboxFilter
        values={checkboxFilter.values}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        isAllAvailable
        onlyOneSelect
        label="Status"
      />
      <CheckboxFilter
        values={partnerValues}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        isAllAvailable
        onlyOneSelect
        label="Partner"
      />
      <CheckboxFilter
        values={carrierValues}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        isAllAvailable
        onlyOneSelect
        label="Carrier"
      />
      <CheckboxFilter
        values={locationValues || []}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        onlyOneSelect
        isAllAvailable
        disabled={!currentHubId}
        label="Location"
      />
      {isFilterActive ? (
        <FilterBar
          activeStatuses={activeFilters}
          onFilteredItemRemove={onFilteredItemRemove}
          className={classes.filterPanel}
        />
      ) : null}
    </div>
  )
}

export default memo(Toolbar)
