import FileCopyIcon from '@mui/icons-material/FileCopy'
import IconButton from '@mui/material/IconButton'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import useStyles from './styles'

interface Props {
  label: string
  value: string
}

function FormHeaderFields({ label, value }: Props) {
  const classes = useStyles({})
  const dispatch = useDispatch()

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value || '')

    dispatch(
      actions.addSnackBar.request({
        type: SnackBarStatuses.SUCCESS,
        message: `${label} was successfully copied to the clipboard`,
      }),
    )
  }, [dispatch, value, label])

  return (
    <div className={classes.idGroup} onClick={copyToClipboard}>
      <div className={classes.id}>
        {label} : {value}
      </div>
      <IconButton>
        <FileCopyIcon className={classes.copyIcon} />
      </IconButton>
    </div>
  )
}

export default memo(FormHeaderFields)
