import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { memo } from 'react'
import { Field } from 'react-final-form'

import useStyles from '../styles'

interface Props {
  fieldName: string
}

function ScansRow({ fieldName }: Props): JSX.Element {
  const classes = useStyles()

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.createdAt`}>
          {({ input }) => <p>{moment(input.value * 1000).format('MM-DD-YYYY HH:mm')}</p>}
        </Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.trackingId`}>{({ input }) => input.value || '--'}</Field>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <Field name={`${fieldName}.type`}>{({ input }) => <p>{input.value}</p>}</Field>
      </TableCell>
    </TableRow>
  )
}

export default memo(ScansRow)
