import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { DateRangePicker, Range } from 'react-date-range'
import ReactDOM from 'react-dom'

import { staticRanges } from '../../constants/staticRanges'
import PrevNextArrow from '../images/icons/prevArrow'
import HeaderLabel from './components/HeaderLabel'
import useStyles from './styles'

const rangeColor = ['#635DF6']

interface Props {
  setCurrentDateRange: (range: Range[]) => void
  currentDateRange: Range[]
  isOperationPage?: boolean
}

function DatePicker({ setCurrentDateRange, currentDateRange, isOperationPage }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles({ isOpen, isOperationPage })
  const ref = useRef<HTMLDivElement>(null)

  const onChange = useCallback(
    range => {
      setCurrentDateRange([range.selection])
    },
    [setCurrentDateRange],
  )

  useEffect(() => {
    if (ref.current) {
      const arrowPrevContainer = ref.current.children[0].children[1].children[1].children[0]
      const arrowNextContainer = ref.current.children[0].children[1].children[1].children[2]

      ReactDOM.render(<PrevNextArrow />, arrowPrevContainer)
      ReactDOM.render(<PrevNextArrow className={classes.nextArrow} />, arrowNextContainer)
    }
  }, [classes.dateRangeTitle, classes.nextArrow])

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('click', checkIfClickedOutside)

    return () => {
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [isOpen])

  useEffect(() => {
    if (ref.current) {
      const inputs = ref.current.children[0].children[1].children[0]
      inputs.addEventListener('click', () => setIsOpen(true))

      return () => {
        inputs.removeEventListener('click', () => setIsOpen(true))
      }
    }
  }, [isOpen])

  return (
    <div className={classes.rangeDatepicker} ref={ref}>
      <DateRangePicker
        onChange={onChange}
        months={2}
        moveRangeOnFirstSelection={false}
        ranges={currentDateRange}
        direction="horizontal"
        showMonthAndYearPickers={false}
        rangeColors={rangeColor}
        className={classes.datePicker}
        staticRanges={staticRanges}
        editableDateInputs
        dateDisplayFormat="MM-dd-yyyy"
        headerContent={<HeaderLabel />}
      />
    </div>
  )
}

export default memo(DatePicker)
