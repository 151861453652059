import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import isJsonString from '@returnmates/client-core/src/utils/isJsonString'
import stringToUuid from '@returnmates/client-core/src/utils/stringToUuid'
import Button from '@returnmates/ui-core/src/components/Button'
import ErrorBlock from '@returnmates/ui-core/src/components/ErrorBlock'
// import Input from '@returnmates/ui-core/src/components/Input'
import Modal from '@returnmates/ui-core/src/components/Modal'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import { ConsolidationPackageInput } from '../constants'
import useStyles from '../styles'
import PackageModalDetails from './PackageModalDetails'

interface Props {
  onPackageCreate: (packageData: ConsolidationPackageInput, withReplacing?: boolean) => void
  onClose: () => void
  isOpen: boolean
  consolidationId?: string
  partnerCode?: string
  validation?: { [key: string]: string } | null
  setPackageSubmitError?: (val: { [key: string]: string } | null) => void
  isLoading?: boolean
  failedPackageIds: { [key: string]: string } | {}
  succedPackageIds?: string[]
  resetScannedIdsState: () => void
}

function AddNewPackageModal({
  isOpen,
  onClose,
  onPackageCreate,
  consolidationId,
  validation,
  setPackageSubmitError,
  isLoading,
  failedPackageIds,
  succedPackageIds,
  resetScannedIdsState,
}: Props) {
  const classes = useStyles()
  const barcode = useRef<string>('')
  const interval = useRef<number>()
  const dispatch = useDispatch()
  // **Please don't remove this. It needs for testing of the scan locally
  // const [packagesIdsToTestScan, setPackagesIdsToTestScan] = useState<string>('')

  const initialState = useMemo<{
    consolidationId?: string
    packageId?: string
  }>(
    () => ({
      consolidationId: consolidationId,
    }),
    [consolidationId],
  )

  const customValidation = useCallback(() => {
    return { ...validation }
  }, [validation])

  const setTouched = useCallback((_, state) => {
    const field = state.fields['consolidationPackageId']
    field.touched = true
  }, [])

  const setValues = useCallback(
    (_, state) => {
      const { lastSubmittedValues } = state.formState

      onPackageCreate(lastSubmittedValues, true)
    },
    [onPackageCreate],
  )

  const handleAddPackage = useCallback(
    values => {
      onPackageCreate(values, false)
    },
    [onPackageCreate],
  )

  const handleBarcode = useCallback(
    barcodeVal => {
      const barcodeToJSON = barcodeVal.replace(
        /(\w+:)|(\w+ :)/g,
        (matchedStr: string) => '"' + matchedStr.substring(0, matchedStr.length - 1) + '":',
      )

      const parsedValidJSON: {
        package_ids?: Array<string>
        package_id?: string
      } = isJsonString(barcodeToJSON)

      const isMultiPackageLabel = Boolean(parsedValidJSON.package_ids?.length)

      if (isMultiPackageLabel) {
        const formattedUuidPackages = parsedValidJSON.package_ids?.map(packId =>
          stringToUuid(packId),
        )

        dispatch(
          actions.scanBarcode.request({
            packageIds: formattedUuidPackages,
          }),
        )
      } else {
        if (parsedValidJSON.package_id) {
          dispatch(
            actions.scanBarcode.request({
              packageId: stringToUuid(parsedValidJSON.package_id),
            }),
          )
        } else {
          dispatch(
            actions.scanBarcode.request({
              scannedId: stringToUuid(barcodeVal),
            }),
          )
        }
      }

      barcode.current = ''
    },
    [dispatch],
  )

  const checkIsScanning = useCallback(
    e => {
      e.stopPropagation()

      if (interval.current) {
        clearInterval(interval.current)
      }

      if (e.code === 'Enter') {
        if (barcode.current && barcode.current.length) {
          resetScannedIdsState()
          handleBarcode(barcode.current)
        }
        barcode.current = ''
        return
      }
      if (e.key !== 'Shift') {
        barcode.current = barcode.current + e.key
      }

      interval.current = +setInterval(() => (barcode.current = ''), 200)
    },
    [handleBarcode, resetScannedIdsState],
  )

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', checkIsScanning)

      return () => {
        document.removeEventListener('keydown', checkIsScanning)
      }
    }
  }, [checkIsScanning, isOpen])

  useEffect(() => {
    if (!isOpen) {
      dispatch(
        actions.scanBarcode.request({
          packageIds: null,
        }),
      )
    }
  }, [dispatch, isOpen])

  // **Please don't remove this. It needs for testing of the scan locally

  // const handlePackageIdsInput = useCallback(val => {
  //   setPackagesIdsToTestScan(val)
  // }, [])

  // const simulateConsScan = useCallback(() => {
  //   let JSONpayload = ''

  //   const packageIds = packagesIdsToTestScan.split(',').map(packId => packId.trim())

  //   if (packageIds.length === 1) {
  //     JSONpayload = JSON.stringify({
  //       package_id: packageIds[0],
  //     })
  //   } else {
  //     JSONpayload = JSON.stringify({
  //       package_ids: packageIds,
  //     })
  //   }

  //   const keyEvent = [...JSONpayload.split(''), '']
  //   keyEvent.forEach((val, i) => {
  //     const ev = new KeyboardEvent('keydown', {
  //       bubbles: true,
  //       cancelable: true,
  //       key: val,
  //       shiftKey: true,
  //       code: i === keyEvent.length - 1 ? 'Enter' : '',
  //     })
  //     document.dispatchEvent(ev)
  //   }, [])
  // }, [packagesIdsToTestScan])

  // useEffect(() => {
  //   if (!isOpen) {
  //     setPackagesIdsToTestScan('')
  //   }
  // }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={448}>
      <div className={classes.newPackageModal}>
        {/* <div className={classes.simulateScanBlock}>
          <Input
            value={packagesIdsToTestScan}
            onChange={handlePackageIdsInput}
            placeholder="Enter comma separated package IDs"
          />
          <Button
            className={clsx(classes.button, classes.simulateScanBtn)}
            onClick={simulateConsScan}
            label="SIMULATE SCAN"
            fullWidth
            disabled={!packagesIdsToTestScan.length}
          />
        </div> */}
        <p className={classes.modalHeader}>Add Package</p>
        <p className={classes.modalSubHeader}>This package will be added to the Consolidation</p>
        <Form
          onSubmit={handleAddPackage}
          initialValues={initialState}
          validate={customValidation}
          mutators={{ setTouched, setValues }}
          render={({ handleSubmit, submitError, form }) => (
            <>
              <ErrorBlock message={submitError} />
              <PackageModalDetails
                handleSubmit={handleSubmit}
                setPackageSubmitError={setPackageSubmitError}
                form={form}
                failedPackageIds={failedPackageIds}
                succedPackageIds={succedPackageIds}
              />
              {validation && Object.keys(validation).length ? (
                <div className={classes.buttonsGroup}>
                  <Button
                    className={clsx(
                      classes.button,
                      classes.cancelButton,
                      classes.modalCancelButton,
                    )}
                    onClick={onClose}
                    label="Cancel"
                    cancelButton
                  />
                  <Button
                    className={clsx(classes.button, classes.saveButton, classes.modalSaveButton)}
                    onClick={form.mutators.setValues}
                    label="Confirm"
                    isLoading={isLoading}
                  />
                </div>
              ) : null}
            </>
          )}
        />
      </div>
    </Modal>
  )
}

export default memo(AddNewPackageModal)
