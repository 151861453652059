import {
  ClaimReasons,
  LabelType,
  PackageStatus,
} from '@returnmates/client-core/src/graphql/generated/api'

export const statusesToChangeToDelivered = [
  PackageStatus.RECEIVED,
  PackageStatus.DRIVER_LAUNCHED,
  PackageStatus.DRIVER_ARRIVING,
]

export const digitalLabels = [LabelType.DIGITAL, LabelType.AMAZON]

export const claimReasons = [
  {
    key: ClaimReasons.LOST_DRIVER,
    label: 'Lost In Driver Possession',
  },
  {
    key: ClaimReasons.LOST_MM,
    label: 'Lost In Transit During MM',
  },
  {
    key: ClaimReasons.LOST_WAREHOUSE,
    label: 'Lost Package In Warehouse',
  },
  {
    key: ClaimReasons.MISDELIVERED_ADDRESS,
    label: 'Misdelivery - Wrong Address',
  },
  {
    key: ClaimReasons.MISDELIVERED_IMPROPER_POD,
    label: 'Misdelivery - Improper POD',
  },
  {
    key: ClaimReasons.MISDELIVERED_UNSAFE,
    label: 'Misdelivery - Unsafe Deliverly Location',
  },
  {
    key: ClaimReasons.DAMAGED_DRIVER,
    label: 'Package Damaged In Driver Possession',
  },
  {
    key: ClaimReasons.DAMAGED_MM,
    label: 'Package Damaged During MM',
  },
  {
    key: ClaimReasons.DAMAGED_WAREHOUSE,
    label: 'Package Damaged In Warehouse',
  },
]
