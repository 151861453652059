import {
  ConsolidationIcon,
  ConsolidationIconActive,
} from '@returnmates/ui-core/src/components/imageComponents/sidebarIcons/'

import ActiveAuditsIcon from '../components/images/icons/activeAuditsIcon'
import ActiveDashboardIcon from '../components/images/icons/activeDashboard'
import ActiveLocationIcon from '../components/images/icons/activeLocation'
import ActiveManifestIcon from '../components/images/icons/activeManifest'
import ActiveOperationIcon from '../components/images/icons/activeOperation'
import ActivePackageIcon from '../components/images/icons/activePackage'
import ActivePartnerIcon from '../components/images/icons/activePartner'
import ActivePrinterIcon from '../components/images/icons/activePrinter'
import ActiveQrIcon from '../components/images/icons/activeQr'
import ActiveUsersIcon from '../components/images/icons/activeUsers'
import AuditsIcon from '../components/images/icons/auditsIcon'
import Car from '../components/images/icons/car'
import CarNeon from '../components/images/icons/carNeon'
import DashboardIcon from '../components/images/icons/dashboard'
import Forum from '../components/images/icons/forum'
import ForumNeon from '../components/images/icons/forumNeon'
import LocationIcon from '../components/images/icons/location'
import ManifestIcon from '../components/images/icons/manifest'
import MapMarker from '../components/images/icons/mapMarker'
import MapMarkerActive from '../components/images/icons/mapMarkerActive'
import OperationIcon from '../components/images/icons/operation'
import PackageIcon from '../components/images/icons/package'
import PartnerIcon from '../components/images/icons/partner'
import PrinterIcon from '../components/images/icons/printer'
import QrIcon from '../components/images/icons/qr'
import UsersIcon from '../components/images/icons/users'
import {
  AUDITS,
  COMMUNICATIONS,
  CONSOLIDATIONS,
  LOCATIONS,
  MAIN,
  MANIFEST,
  OPERATIONS,
  PACKAGE_LOG,
  PARTNERS,
  PRINT,
  QR,
  TRIPS_LOG,
  USERS,
  ZIP_CODES,
} from './paths'

const sidebarItems = [
  {
    icon: DashboardIcon,
    activeIcon: ActiveDashboardIcon,
    path: MAIN,
  },
  {
    icon: Car,
    activeIcon: CarNeon,
    path: TRIPS_LOG,
  },
  {
    icon: AuditsIcon,
    activeIcon: ActiveAuditsIcon,
    path: AUDITS,
  },
  {
    icon: PackageIcon,
    activeIcon: ActivePackageIcon,
    path: PACKAGE_LOG,
  },
  {
    icon: ConsolidationIcon,
    activeIcon: ConsolidationIconActive,
    path: CONSOLIDATIONS,
  },
  {
    icon: UsersIcon,
    activeIcon: ActiveUsersIcon,
    path: USERS,
  },
  {
    icon: MapMarker,
    activeIcon: MapMarkerActive,
    path: ZIP_CODES,
  },
  {
    icon: QrIcon,
    activeIcon: ActiveQrIcon,
    path: QR,
  },
  {
    icon: ManifestIcon,
    activeIcon: ActiveManifestIcon,
    path: MANIFEST,
  },
  {
    icon: OperationIcon,
    activeIcon: ActiveOperationIcon,
    path: OPERATIONS,
  },
  {
    icon: Forum,
    activeIcon: ForumNeon,
    path: COMMUNICATIONS,
  },
  {
    icon: PartnerIcon,
    activeIcon: ActivePartnerIcon,
    path: PARTNERS,
  },
  {
    icon: LocationIcon,
    activeIcon: ActiveLocationIcon,
    path: LOCATIONS,
  },
  {
    icon: PrinterIcon,
    activeIcon: ActivePrinterIcon,
    path: PRINT,
  },
]

export default sidebarItems
