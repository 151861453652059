import { Document, Page, Text, View } from '@react-pdf/renderer'
import formatPhone from '@returnmates/client-core/src/utils/formatPhone'
import { memo } from 'react'

import { PackingDetails } from './DownloadPDFLink'
import styles from './styles'

interface Props {
  details: PackingDetails
}

function PackingSlip({ details }: Props) {
  return (
    <Document>
      <Page size="A4" wrap={false} style={styles.page}>
        <View style={styles.frame} fixed />
        <View style={styles.section}>
          <View style={styles.topMargin} fixed />
          <Text style={styles.mainTitle}>Returnmates Packing Slip</Text>

          <View style={styles.consolidationSection}>
            <View style={styles.destinationInfo}>
              <Text style={styles.destinationText}>
                Hub Destination: {details.hubDestinationSortCode}
              </Text>
              <Text style={styles.destinationText}>Destination:</Text>
              <Text style={styles.destinationText}>{details.address.name}</Text>
              <Text style={styles.destinationText}>{details.address.street}</Text>
              <Text
                style={styles.destinationText}
              >{`${details.address.city}, ${details.address.state} ${details.address.zipCode}`}</Text>
            </View>
            <View style={styles.consolidationTable}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{`Consolidation: ${details.id}`}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableColBottom}>
                  <Text
                    style={styles.tableCell}
                  >{`Estimated Shipping Date: ${details.expectedDeliveryDate || '-'}`}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.packagesSection}>
            <View style={styles.packageTable}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader1}>
                  <Text style={styles.tableCellHeader}>Package ID</Text>
                </View>
                <View style={styles.tableColHeader2}>
                  <Text style={styles.tableCellHeader}>Customer</Text>
                </View>
                <View style={styles.tableColHeader3}>
                  <Text style={styles.tableCellHeader}>Package Description</Text>
                </View>
              </View>
              {details.currentPackingSlip
                ? details.currentPackingSlip.map(pack => (
                    <View style={styles.tableRow} key={pack.packageId} wrap={false}>
                      <View style={styles.tableCol1}>
                        <Text style={styles.tableCell1}>{pack.packageId}</Text>
                      </View>
                      <View style={styles.tableCol2}>
                        <Text style={styles.tableCell2}>{`${pack.address?.name}`}</Text>
                        <Text style={styles.tableCell2}>
                          {formatPhone(pack.address?.phone || '')}
                        </Text>
                        <Text style={styles.tableCell2}>{`${pack.customerEmail}`}</Text>
                      </View>
                      <View style={styles.tableCol3}>
                        <Text style={styles.tableCell}>{pack.packageDescription}</Text>
                      </View>
                    </View>
                  ))
                : ''}
            </View>
            <View style={styles.bottomMargin} fixed />
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber} of ${totalPages}`}
            fixed
          />
        </View>
      </Page>
    </Document>
  )
}

export default memo(PackingSlip)
