import { Refresh } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { memo } from 'react'

import useStyles from './styles'
import { SectionHeaderProps } from './types'

function SectionHeader({ onRefresh, count = null, title }: SectionHeaderProps): JSX.Element {
  const classes = useStyles()
  return (
    <div className={classes.title}>
      {title}

      {count !== null && <span>{count.toLocaleString()}</span>}

      {onRefresh && (
        <IconButton onClick={onRefresh} className={classes.refreshButton}>
          <Refresh />
        </IconButton>
      )}
    </div>
  )
}

export default memo(SectionHeader)
