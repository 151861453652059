import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import { Auth } from 'aws-amplify'
import clsx from 'clsx'
import { memo, useCallback, useMemo, useState } from 'react'
import { Range } from 'react-date-range'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { MAIN } from '../../constants/paths'
import sidebarItems from '../../constants/sidebarItems'
import { todayDateFilter } from '../../constants/staticRanges'
import {
  auditorPaths,
  cognitoGroups,
  managerPaths,
  operationsPaths,
  saverPaths,
  warehousePaths,
} from '../../constants/userPermissions'
import LogoutIcon from '../images/icons/logout'
import NavLinkWrap from './NavLinkWrap'
import useStyles from './styles'

interface Props {
  userRole: string
  setCurrentDateRange: (range: Range[]) => void
}

const { admin, manager, operations, warehouse, auditor, saver } = cognitoGroups

function Sidebar({ userRole, setCurrentDateRange }: Props) {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [newActiveLink, setNewActiveLink] = useState(0)

  const handleIsActive = useCallback((location, to, index): boolean => {
    const path = to.split('/')[1]

    if (location.includes(path) && to !== MAIN) {
      setNewActiveLink(index)
      return location.includes(path)
    }

    if (location === MAIN) {
      setNewActiveLink(0)
      return location.includes(path)
    }

    return false
  }, [])

  const sidebarMenuItems = useMemo(() => {
    switch (userRole) {
      case admin:
        return sidebarItems
      case manager:
        return sidebarItems.filter(item => managerPaths.includes(item.path))
      case operations:
        return sidebarItems.filter(item => operationsPaths.includes(item.path))
      case warehouse:
        return sidebarItems.filter(item => warehousePaths.includes(item.path))
      case auditor:
        return sidebarItems.filter(item => auditorPaths.includes(item.path))
      case saver:
        return sidebarItems.filter(item => saverPaths.includes(item.path))
      default:
        return sidebarItems
    }
  }, [userRole])

  const logout = useCallback(async () => {
    Auth.signOut()

    await createAsyncAction(dispatch, actions.logout.request())

    navigate(MAIN)
  }, [navigate, dispatch])

  const handleClick = useCallback(() => {
    setCurrentDateRange(todayDateFilter)
  }, [setCurrentDateRange])

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.linksList}>
          {sidebarMenuItems.map((item, index) => (
            <NavLinkWrap
              onClick={handleClick}
              handleIsActive={handleIsActive}
              //@ts-ignore
              item={item}
              index={index}
              newActiveLink={newActiveLink}
              key={index}
            />
          ))}
          <Link to={MAIN} className={clsx(classes.link, classes.logoutLink)} onClick={logout}>
            <LogoutIcon className={classes.linkIcon} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(Sidebar)
