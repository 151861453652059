import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { PACKAGE_STATUS_MAP } from '@returnmates/client-core/src/constants/trip'
import {
  PackageStatus,
  PackageTrackingList,
} from '@returnmates/client-core/src/graphql/generated/api'
import moment from 'moment'
import { memo, useMemo } from 'react'

import useStyles from '../styles'

interface Props {
  tracking: PackageTrackingList
}

function TrackingRow({ tracking }: Props): JSX.Element {
  const classes = useStyles()

  const getRescheduledString = useMemo(() => {
    if (tracking.publicStatus === 'Scheduled') {
      return tracking.publicStatus
    }
    const startIndex = tracking.description.indexOf('This trip has been')

    if (startIndex !== -1) {
      const modifiedString = tracking.description
        .slice(startIndex + 'This trip has been'.length)
        .trim()

      return modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1)
    } else {
      return tracking.description
    }
  }, [tracking])

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.bodyCell}>
        <p>{moment(tracking.createdAt * 1000).format('MM-DD-YYYY HH:mm')}</p>
      </TableCell>
      <TableCell className={classes.bodyCell}>
        <p>
          {tracking.status
            ? PACKAGE_STATUS_MAP[tracking.status as PackageStatus]
            : getRescheduledString}
        </p>
      </TableCell>
    </TableRow>
  )
}

export default memo(TrackingRow)
