import { Table, TableHeaderRowProps } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'

import useStyles from '../styles'

const HeadComponent = (props: TableHeaderRowProps) => {
  const classes = useStyles()

  return <Table.TableHead className={classes.tableHead} {...props} />
}

export default memo(HeadComponent)
