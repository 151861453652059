import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { printPackageTrackingIdError } from '@returnmates/client-core/src/constants/errors'
import { SourceType } from '@returnmates/client-core/src/constants/sourceType'
import { AdminPackage, CreatePolybag } from '@returnmates/client-core/src/graphql/generated/api'
import {
  getOnfleetWorkers,
  getPackageServiceTypes,
  getPartners as getPartnersSelector,
} from '@returnmates/client-core/src/selectors/admin'
import { getLocations } from '@returnmates/client-core/src/selectors/location'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import { CustomAdminPackage } from '@returnmates/client-core/src/type/pickups'
import camelToUnderscore from '@returnmates/client-core/src/utils/camelToUnderscore'
import cleanIds from '@returnmates/client-core/src/utils/cleanIds'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import getUniqueList from '@returnmates/client-core/src/utils/getUniqueList'
import {
  groupPackagesByAttribute,
  hydrateMultipleLabelTemplate,
  hydrateSingleLabelTemplate,
} from '@returnmates/client-core/src/utils/hydrateLabelTemplate'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import SearchToolbar from '@returnmates/ui-core/src/components/SearchToolbar'
import moment from 'moment'
import {
  memo,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'

import { Context } from '../../../pages/Main'
import { EditType } from '../../../pages/Main/types'
import { Filter } from '../../../pages/Packages'
import Calendar from '../../Calendar'
import CheckboxFilter from '../../CheckboxFilter'
import FilterCheckbox from '../../CheckboxFilter/FilterCheckbox'
import FilterBar from '../../FilterBar'
import {
  checkboxFilter,
  consolidationsValue,
  dropoffSearchFields,
  labelTypeFilter,
  NORDSTROM_PARTNER,
  pickupSearchFields,
  unsortedValue,
} from '../constants'
import useStyles from '../styles'
import { getNordstromLabelTemplate, hydratePolybagLabels } from '../utils'
import PrintLabels from './PrintLabels'

interface PartnerGroup {
  labelTemplate?: string | null
  labelPackageCount?: number
  labelPackageGroup?: string | null
  packages: AdminPackage[] | CustomAdminPackage[]
}
interface Props {
  setSelectedRowsProp?: (values: string[]) => void
  selectedRowsProp?: string[]
  onFilterUpdate?: (val: Filter) => void
  refreshPackages?: () => void
  defaultFilter?: {
    [key: string]: boolean
  }
  isLoading: boolean
  packagesData: Array<AdminPackage>
  isTripUpdatedLoading: boolean
  setIsTripUpdateLoading: (val: boolean) => void
  tabIndex?: EditType
}

function Toolbar({
  selectedRowsProp,
  setSelectedRowsProp,
  onFilterUpdate,
  refreshPackages,
  isLoading,
  defaultFilter,
  packagesData,
  isTripUpdatedLoading,
  setIsTripUpdateLoading,
  tabIndex,
}: Props) {
  const classes = useStyles()
  const { currentHubId } = useContext(Context)
  const [searchValue, setSearchValue] = useState('')
  const [searchField, setSearchField] = useState('addressName')
  const [dateForUpdate, setDateForUpdate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [isHideConsolidationChecked, setIsHideConsolidationChecked] = useState(false)
  const [isHideUnsortedChecked, setIsHideUnsortedChecked] = useState(true)
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: boolean }>({
    ...defaultFilter,
  })
  const [selectedLabelValues, setSelectedLabelValues] = useState<{ [key: string]: boolean }>({})
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Array<string>>([])

  const packagesToPrint = useRef<AdminPackage[]>([])

  const partners = useSelector(getPartnersSelector)
  const onfleetWorkers = useSelector(getOnfleetWorkers)
  const packageServiceTypes = useSelector(getPackageServiceTypes)
  const locations = useSelector(getLocations)
  const dispatch = useDispatch()

  const driverValues = useMemo(
    () => onfleetWorkers?.map(driver => ({ key: driver.id, label: driver.name })),
    [onfleetWorkers],
  )

  const partnerValues = useMemo<Array<{ key: string; label: string }>>(() => {
    const partnerOptions = partners.reduce((acc, partner) => {
      if (partner.code !== 'rm') {
        return [
          ...acc,
          {
            key: partner.code as string,
            label: partner.name as string,
          },
        ]
      }

      return acc
    }, [])

    return [{ key: 'rm', label: 'Returnmates' }, ...partnerOptions]
  }, [partners])

  const locationValues = useMemo(
    () =>
      locations?.map(location => ({
        key: location.id,
        label: location.name,
      })),
    [locations],
  )

  const packageServiceTypesValues = useMemo(
    () =>
      packageServiceTypes?.map(packageServiceType => ({
        key: packageServiceType.id,
        label: packageServiceType.id,
      })),
    [packageServiceTypes],
  )

  const printBarcode = useCallback(
    async (zpl: string | Array<string>) => {
      console.log(zpl)
      try {
        const browserPrint = new ZebraBrowserPrintWrapper()
        const defaultPrinter = await browserPrint.getDefaultPrinter()
        browserPrint.setPrinter(defaultPrinter)
        const printerStatus = await browserPrint.checkPrinterStatus()

        if (printerStatus.isReadyToPrint) {
          if (typeof zpl === 'string') {
            browserPrint.print(zpl)
          } else {
            zpl.forEach(template => browserPrint.print(template))
          }
        } else {
          dispatch(
            actions.addSnackBar.request({
              type: SnackBarStatuses.ERROR,
              message: printerStatus.errors,
            }),
          )
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    [dispatch],
  )

  const onDateUpdate = useCallback(
    async createTask => {
      setIsTripUpdateLoading(true)

      try {
        await createAsyncAction(
          dispatch,
          actions.reschedulePackages.request({
            packageIds: selectedRows,
            date: moment(dateForUpdate).format('YYYY-MM-DD'),
            createTask,
            updateSource: SourceType.WILSON,
          }),
        )

        setSelectedRowsProp?.([])
        setDateForUpdate(moment(new Date()).format('YYYY-MM-DD'))
        refreshPackages?.()
      } catch (err) {
        const { message } = err as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      } finally {
        setIsTripUpdateLoading(false)
      }
    },
    [
      setIsTripUpdateLoading,
      dispatch,
      selectedRows,
      dateForUpdate,
      setSelectedRowsProp,
      refreshPackages,
    ],
  )

  const defaultLabelTemplate = useMemo(() => partners.find(p => p.code === 'rm')?.labelTemplate, [
    partners,
  ])

  const getPackageTrackingIdZplTemplate = useCallback(
    (trackingId: string) =>
      `^XA^LH10,10^LS0^MTD^PW1200^CF0,60^FO20,50^FD^CF0,40^FO20,133^FS^FO20,273^FS^FB887,6,22,L^FO20,325^FS^FO20,40^FDPACKAGE TRACKING ID: ${trackingId} ^FS^FO20,100^BY2,2,120^BC,,N^FD${trackingId}^FS^XZ`,
    [],
  )

  const handleTrackingIdPrint = useCallback(() => {
    const selectedPackages = [...packagesToPrint.current]
    const packagesTrackingId: string[] = []
    const packagesWithoutTrackingId: string[] = []
    const labelsToPrint: string[] = []

    selectedPackages.forEach(pack =>
      pack.trackingId
        ? packagesTrackingId.push(pack.trackingId)
        : packagesWithoutTrackingId.push(pack.id),
    )

    packagesTrackingId.forEach(trackindId =>
      labelsToPrint.push(getPackageTrackingIdZplTemplate(trackindId)),
    )

    printBarcode(labelsToPrint)

    if (packagesWithoutTrackingId.length) {
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: printPackageTrackingIdError(packagesWithoutTrackingId),
        }),
      )
    }

    setSelectedRowsProp?.([])
  }, [dispatch, getPackageTrackingIdZplTemplate, printBarcode, setSelectedRowsProp])

  const handlePackageIDLabelsPrint = useCallback(() => {
    const selectedPackages = [...packagesToPrint.current]

    const packagesByPartner = selectedPackages.reduce(
      (acc: { [key: string]: PartnerGroup }, pack: CustomAdminPackage) => {
        const { partner } = pack

        if (partner) {
          const currentPartner = partners.find(p => p.code === partner)

          if (!acc[partner]) {
            acc[partner] = {
              labelTemplate: currentPartner?.labelTemplate ?? defaultLabelTemplate,
              labelPackageCount: currentPartner?.labelPackageCount,
              labelPackageGroup: currentPartner?.labelPackageGroup,
              packages: [],
            }
          }

          acc[partner] = {
            ...acc[partner],
            packages: [...acc[partner].packages, pack],
          }
        } else {
          acc['rm'] = {
            ...acc['rm'],
            packages: [...acc['rm'].packages, pack],
          }
        }

        return acc
      },
      {},
    )

    const labelsToPrint: string[] = []

    Object.keys(packagesByPartner).forEach(partnerName => {
      const currentPartner = packagesByPartner[partnerName]
      if (currentPartner.labelPackageGroup) {
        const groupsByAttribute = groupPackagesByAttribute(
          currentPartner.packages,
          currentPartner.labelPackageGroup,
          currentPartner.labelPackageCount || 1,
        )

        const hydratedMultipleLabels = groupsByAttribute.map(packGroup => {
          const pck = packGroup[0]
          const modifiedPartnerAttr: { rma_id?: string; order_number?: string } = {}
          let labelTemplate = currentPartner.labelTemplate ?? (defaultLabelTemplate as string)
          const packagePartnerAttributes = pck.partnerAttributes
            ? JSON.parse(pck.partnerAttributes)
            : null

          for (const key in packagePartnerAttributes) {
            //@ts-ignore
            modifiedPartnerAttr[camelToUnderscore(key)] = packagePartnerAttributes[key]
          }

          if (pck.partnerCode?.toLocaleLowerCase() === NORDSTROM_PARTNER) {
            labelTemplate = getNordstromLabelTemplate(
              labelTemplate,
              modifiedPartnerAttr?.rma_id,
              modifiedPartnerAttr?.order_number,
            )
          }

          return hydrateMultipleLabelTemplate(
            labelTemplate,
            packGroup,
            currentPartner.labelPackageCount || 1,
          )
        })

        labelsToPrint.push(...hydratedMultipleLabels)
      } else {
        const isMultipleLabel = currentPartner.labelTemplate?.includes('{Package[')

        if (isMultipleLabel) {
          const hydratedMultipleLabels = currentPartner.packages.map(pack =>
            hydrateMultipleLabelTemplate(
              currentPartner.labelTemplate ?? (defaultLabelTemplate as string),
              [pack],
              currentPartner.labelPackageCount || 1,
            ),
          )

          labelsToPrint.push(...hydratedMultipleLabels)
        } else {
          const hydratedSingleLabels = currentPartner.packages.map(pack =>
            hydrateSingleLabelTemplate(currentPartner.labelTemplate as string, pack),
          )

          labelsToPrint.push(...hydratedSingleLabels)
        }
      }
    })

    printBarcode(labelsToPrint)

    setSelectedRowsProp?.([])
  }, [printBarcode, setSelectedRowsProp, partners, defaultLabelTemplate])

  const handlePolybagIDsPrint = useCallback(
    async count => {
      try {
        const createdPolybags: CreatePolybag[] = await createAsyncAction(
          dispatch,
          actions.createPolybags.request({ count }),
        )

        const createdPolybagsIds = createdPolybags.reduce((acc, polybag) => {
          if (polybag.success) {
            return [...acc, polybag.id]
          }

          return acc
        }, [])

        if (createdPolybagsIds && createdPolybagsIds.length) {
          const hydratedPolybagIDsLabels = hydratePolybagLabels(createdPolybagsIds)

          dispatch(
            actions.addSnackBar.request({
              type: SnackBarStatuses.SUCCESS,
              message: `The printing of ${createdPolybags.length} polybag(s) has started`,
            }),
          )

          printBarcode(hydratedPolybagIDsLabels)
        }
      } catch (err) {
        const { message } = err as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      }
    },
    [dispatch, printBarcode],
  )

  const handleChangeSearch = useCallback(e => {
    const searchValue = e.target.value
    setSearchValue(searchValue)
  }, [])

  const onSearchFieldChange = useCallback((searchField: string) => {
    setSearchField(searchField)
  }, [])

  const handleClearSearch = useCallback(() => {
    setSearchValue('')
  }, [])

  const onHideConsolidationsSelect = useCallback(() => {
    setIsHideConsolidationChecked(val => !val)
  }, [])

  const onHideUnsortedSelect = useCallback(() => {
    setIsHideUnsortedChecked(val => !val)
  }, [])

  const onChangeLabelFilter = useCallback(
    newSelection => {
      setSelectedLabelValues({
        ...selectedLabelValues,
        ...newSelection,
      })
    },
    [selectedLabelValues],
  )

  const onChangeFilter = useCallback(
    newSelection => {
      setSelectedValues({
        ...selectedValues,
        ...newSelection,
      })
    },
    [selectedValues],
  )

  const statusFilter = useMemo(
    () =>
      checkboxFilter.values.reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return [...acc, item.key]
        }
        return acc
      }, []),
    [selectedValues],
  )

  const labelFilter = useMemo(
    () =>
      labelTypeFilter.values.reduce((acc, item) => {
        if (selectedLabelValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [selectedLabelValues],
  )

  const driverFilter = useMemo(
    () =>
      (driverValues || []).reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [driverValues, selectedValues],
  )

  const partnerFilter = useMemo(
    () =>
      partnerValues.reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [selectedValues, partnerValues],
  )

  const serviceTypeFilter = useMemo(
    () =>
      (packageServiceTypesValues || []).reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [packageServiceTypesValues, selectedValues],
  )

  const locationFilter = useMemo(
    () =>
      (locationValues || []).reduce((acc, item) => {
        if (selectedValues[item.key]) {
          return item.key
        }
        return acc
      }, null),
    [locationValues, selectedValues],
  )

  const searchFields = useMemo(
    () => (tabIndex === EditType.Pickup ? pickupSearchFields : dropoffSearchFields),
    [tabIndex],
  )

  const searchFieldPlaceholder = useMemo(
    () => searchFields.find(f => f.key === searchField)?.label,
    [searchField, searchFields],
  )

  useEffect(() => {
    updateFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    driverFilter,
    isHideConsolidationChecked,
    labelFilter,
    onFilterUpdate,
    partnerFilter,
    statusFilter,
    serviceTypeFilter,
    locationFilter,
    isHideUnsortedChecked,
  ])

  useEffect(() => {
    if (!searchValue.length) {
      updateFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const updateFilters = useCallback(() => {
    const filtersObject = {
      status: statusFilter,
      labelType: labelFilter,
      partnerCode: partnerFilter,
      workerId: driverFilter,
      hideConsolidation: isHideConsolidationChecked,
      hideUnsorted: isHideUnsortedChecked,
      serviceType: serviceTypeFilter,
      locationId: locationFilter,
    }

    if (searchValue.length) {
      Object.assign(filtersObject, { [searchField]: searchValue })
    }

    onFilterUpdate?.(filtersObject)
  }, [
    driverFilter,
    isHideConsolidationChecked,
    labelFilter,
    onFilterUpdate,
    partnerFilter,
    searchValue,
    statusFilter,
    searchField,
    serviceTypeFilter,
    locationFilter,
    isHideUnsortedChecked,
  ])

  const commonFilterValues = useMemo(
    () => [
      ...checkboxFilter.values,
      ...labelTypeFilter.values,
      ...(driverValues || []),
      ...partnerValues,
      ...(locationValues || []),
      ...(packageServiceTypesValues || []),
    ],
    [driverValues, partnerValues, locationValues, packageServiceTypesValues],
  )

  const onFilteredItemRemove = useCallback(
    val => {
      const itemToRemove = commonFilterValues.find(item => item.key === val)?.key

      if (itemToRemove) {
        onChangeFilter({
          [itemToRemove]: false,
        })
      }
    },
    [commonFilterValues, onChangeFilter],
  )

  const onFilteredLabelRemove = useCallback(
    val => {
      const itemToRemove = commonFilterValues.find(item => item.key === val)?.key

      if (itemToRemove) {
        onChangeLabelFilter({
          [itemToRemove]: false,
        })
      }
    },
    [commonFilterValues, onChangeLabelFilter],
  )

  const activeFilters = useMemo(
    () =>
      Object.keys(selectedValues).reduce((acc, item) => {
        const value = commonFilterValues.find(val => val.key === item)
        if (selectedValues[item] && value) {
          return [
            ...acc,
            {
              key: value.key,
              label: value?.label,
            },
          ]
        }
        return acc
      }, []),
    [commonFilterValues, selectedValues],
  )

  const activeLabelFilters = useMemo(
    () =>
      Object.keys(selectedLabelValues).reduce((acc, item) => {
        const value = commonFilterValues.find(val => val.key === item)
        if (selectedLabelValues[item] && value) {
          return [
            ...acc,
            {
              key: value.key,
              label: value?.label,
            },
          ]
        }
        return acc
      }, []),
    [commonFilterValues, selectedLabelValues],
  )

  useEffect(() => {
    if (activeFilters.length || activeLabelFilters.length) {
      setIsFilterActive(true)
    } else {
      setIsFilterActive(false)
    }
  }, [activeFilters, activeLabelFilters])

  const updateRefsOnUnselect = useCallback(
    (tripsRef: RefObject<AdminPackage[]>) => {
      return tripsRef?.current?.filter(pack => selectedRows?.includes(pack.id)) || []
    },
    [selectedRows],
  )

  useEffect(() => {
    selectedRows?.reduce(
      (acc, selectedPackId) => {
        const selectedPackageOnCurrentPage = packagesData?.find(pack => pack.id === selectedPackId)

        packagesToPrint.current = updateRefsOnUnselect(packagesToPrint)

        if (selectedPackageOnCurrentPage) {
          const currentPackagesToPrint = [...acc.packagesToPrint, selectedPackageOnCurrentPage]

          const uniqueSelectedPackagesList = getUniqueList(
            [...packagesToPrint.current, ...currentPackagesToPrint],
            'id',
          )

          packagesToPrint.current = uniqueSelectedPackagesList

          return {
            ...acc,
            packagesToPrint: currentPackagesToPrint,
          }
        } else {
          return acc
        }
      },
      {
        packagesToPrint: [],
      },
    )
  }, [packagesData, selectedRows, updateRefsOnUnselect])

  useEffect(() => {
    setSelectedRows(cleanIds(selectedRowsProp))
  }, [selectedRowsProp])

  return (
    <div className={classes.toolbar}>
      <SearchToolbar
        value={searchValue}
        onInputChange={handleChangeSearch}
        onSearchFieldChange={onSearchFieldChange}
        clearSearch={handleClearSearch}
        placeholder={`Search package by ${searchFieldPlaceholder}`}
        isShowSearchButton
        onSearch={updateFilters}
        searchField={searchField}
        searchFields={searchFields}
        width={500}
      />

      <Calendar
        onDateUpdate={onDateUpdate}
        setDateForUpdate={setDateForUpdate}
        dateForUpdate={dateForUpdate}
        isLoading={isTripUpdatedLoading}
        selectedScheduledTrips={selectedRows}
        show={Boolean(selectedRows?.length)}
      />

      <PrintLabels
        onPackageIDLabelsClick={handlePackageIDLabelsPrint}
        handleTrackingIdPrint={handleTrackingIdPrint}
        handlePolybagIDsPrint={handlePolybagIDsPrint}
        showOptions={Boolean(selectedRows?.length)}
        showPolybagOption={tabIndex === EditType.Pickup}
      />
      <FilterCheckbox
        isSelected={isHideConsolidationChecked}
        val={consolidationsValue}
        select={onHideConsolidationsSelect}
        className={classes.undersCheckbox}
        disabled={isLoading}
      />
      <FilterCheckbox
        isSelected={isHideUnsortedChecked}
        val={unsortedValue}
        select={onHideUnsortedSelect}
        className={classes.undersCheckbox}
        disabled={isLoading}
      />
      <CheckboxFilter
        values={checkboxFilter.values}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        isAllAvailable
        label="Status"
      />
      <CheckboxFilter
        values={labelTypeFilter.values}
        selectedValues={selectedLabelValues}
        onChange={onChangeLabelFilter}
        isAllAvailable
        onlyOneSelect
        label="Label Type"
      />
      <CheckboxFilter
        values={partnerValues}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        isAllAvailable
        onlyOneSelect
        label="Partner"
      />
      <CheckboxFilter
        values={driverValues || []}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        isAllAvailable
        onlyOneSelect
        withSearch
        searchPlaceholder="Search driver"
        label="Driver"
      />
      <CheckboxFilter
        values={packageServiceTypesValues || []}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        onlyOneSelect
        isAllAvailable={false}
        label="Service Type"
      />
      <CheckboxFilter
        values={locationValues || []}
        selectedValues={selectedValues}
        onChange={onChangeFilter}
        onlyOneSelect
        isAllAvailable
        disabled={!currentHubId}
        label="Location"
      />
      {isFilterActive ? (
        <FilterBar
          activeStatuses={activeFilters}
          onFilteredItemRemove={onFilteredItemRemove}
          labelFiler={{
            activeLabelStatuses: activeLabelFilters,
            onFilteredLabelRemove: onFilteredLabelRemove,
          }}
          className={classes.filterPanel}
        />
      ) : null}
    </div>
  )
}

export default memo(Toolbar)
