interface Props {
  className?: string
}

const Partner = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9205 10.3024L18.4167 5.24184C18.2561 5.00984 17.9047 4.93115 17.6298 5.06568L15.53 6.09328L13.6386 5.50358C13.569 5.48188 13.4985 5.46367 13.4274 5.44836C12.8949 5.33362 12.3304 5.39801 11.8487 5.63373L8.72217 7.1638C8.03158 7.50174 7.79498 8.25024 8.19368 8.8356C8.59237 9.42095 9.47544 9.6215 10.166 9.28355L13.5969 7.60454C13.9543 7.42965 14.3946 7.42965 14.752 7.60454C14.752 7.60454 21.6643 10.9884 21.6865 11L21.7895 10.9281C22.0087 10.7752 22.0642 10.5099 21.9205 10.3024V10.3024Z"
      fill="#A7B4C8"
    />
    <path
      d="M21.3301 11.8251L14.2521 8.05598L10.7161 9.93892C10.3034 10.1587 9.84534 10.271 9.38121 10.271C9.14852 10.271 8.91435 10.2427 8.6836 10.1858C7.99249 10.0149 7.41484 9.60615 7.05714 9.03464C6.69941 8.46319 6.60441 7.79739 6.7896 7.15996C6.94246 6.63372 7.27158 6.17918 7.73027 5.85084L6.27084 5.07366C5.97985 4.91869 5.60751 5.01649 5.44866 5.2896L2.07078 11.0982C1.91884 11.3595 2.01928 11.6849 2.29773 11.8331L2.60713 11.9978L3.15018 11.1303C3.6007 10.4105 4.44065 9.96341 5.34223 9.96341C5.78461 9.96341 6.22159 10.0716 6.60587 10.2762C6.82694 10.3939 7.02413 10.539 7.19345 10.7064C7.46714 10.6142 7.75948 10.5647 8.05876 10.5647C8.50114 10.5647 8.93808 10.6729 9.32241 10.8775C9.90756 11.1891 10.3262 11.6923 10.501 12.2943C10.5203 12.3605 10.5357 12.4271 10.5486 12.4937C10.792 12.5456 11.0271 12.6306 11.2452 12.7468C11.8304 13.0583 12.2491 13.5615 12.4239 14.1635C12.4432 14.2297 12.4586 14.2963 12.4715 14.363C12.7149 14.4148 12.95 14.4998 13.1681 14.6159C14.3761 15.2593 14.7915 16.6891 14.0941 17.8033L13.3526 18.9879L14.9421 19.8343C15.5826 20.1754 16.4017 19.973 16.7715 19.3822C17.1414 18.7913 16.9219 18.0359 16.2813 17.6947L17.3123 18.2437C17.9529 18.5849 18.772 18.3824 19.1418 17.7916C19.5116 17.2008 19.2921 16.4453 18.6516 16.1042L19.3389 16.4702C19.9795 16.8113 20.7986 16.6089 21.1684 16.018C21.5382 15.4272 21.3188 14.6717 20.6782 14.3306L19.9909 13.9646C20.6314 14.3058 21.4505 14.1033 21.8203 13.5125C22.1902 12.9217 21.9707 12.1662 21.3301 11.8251Z"
      fill="#E8EAEE"
    />
    <path
      d="M13.31 16.3164C12.6503 15.9139 11.8066 16.1528 11.4257 16.8499L11.8344 16.1019C12.2153 15.4047 11.9893 14.5133 11.3295 14.1108C10.6698 13.7083 9.82611 13.9471 9.44518 14.6443L9.8539 13.8962C10.2348 13.1991 10.0088 12.3076 9.34901 11.9051C8.68925 11.5026 7.8456 11.7415 7.46467 12.4386L7.05594 13.1866C7.43683 12.4895 7.21081 11.598 6.55101 11.1955C5.89125 10.793 5.0476 11.0319 4.66667 11.729L3.18504 14.4407C2.80411 15.1378 3.03018 16.0293 3.68994 16.4318C4.3497 16.8343 5.19339 16.5954 5.57428 15.8982C5.19335 16.5954 5.41941 17.4869 6.07918 17.8894C6.73894 18.2919 7.58263 18.053 7.96352 17.3558C7.58259 18.053 7.80865 18.9444 8.46845 19.3469C9.12822 19.7494 9.97186 19.5106 10.3528 18.8134C9.97186 19.5105 10.1979 20.402 10.8577 20.8045C11.5175 21.207 12.3611 20.9681 12.742 20.2709L13.8149 18.3073C14.1959 17.6104 13.9698 16.7189 13.31 16.3164V16.3164Z"
      fill="#A7B4C8"
    />
  </svg>
)

export default Partner
