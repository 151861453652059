interface Props {
  className?: string
}

const Manifest = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8089 9.021C18.3574 9.021 17.7594 9.011 17.0149 9.011C15.199 9.011 13.7059 7.508 13.7059 5.675V2.459C13.7059 2.206 13.503 2 13.2525 2H7.96436C5.49604 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59109 22 8.1703 22H16.0455C18.5059 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6238 9.016 19.1168 9.021 18.8089 9.021Z"
      fill="#A7B4C8"
    />
    <path
      opacity="0.4"
      d="M16.0843 2.56737C15.7853 2.25637 15.2633 2.47037 15.2633 2.90137V5.53837C15.2633 6.64437 16.1733 7.55437 17.2793 7.55437C17.9773 7.56237 18.9453 7.56437 19.7673 7.56237C20.1883 7.56137 20.4023 7.05837 20.1103 6.75437C19.0553 5.65737 17.1663 3.69137 16.0843 2.56737Z"
      fill="#A7B4C8"
    />
    <path
      d="M15.1053 12.7093C14.8133 12.4203 14.3433 12.4183 14.0513 12.7113L12.4623 14.3083V9.48133C12.4623 9.07033 12.1283 8.73633 11.7173 8.73633C11.3063 8.73633 10.9723 9.07033 10.9723 9.48133V14.3083L9.3823 12.7113C9.0913 12.4183 8.6203 12.4203 8.3293 12.7093C8.0373 12.9993 8.0373 13.4703 8.3263 13.7623L11.1893 16.6383H11.1903C11.2583 16.7063 11.3393 16.7613 11.4303 16.7993C11.5203 16.8363 11.6183 16.8563 11.7173 16.8563C11.8173 16.8563 11.9153 16.8363 12.0053 16.7983C12.0943 16.7613 12.1753 16.7063 12.2433 16.6393L12.2453 16.6383L15.1073 13.7623C15.3973 13.4703 15.3973 12.9993 15.1053 12.7093Z"
      fill="white"
    />
  </svg>
)

export default Manifest
