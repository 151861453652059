import { makeStyles } from '@mui/styles'
import { NEON_BLUE_LIGHT } from '@returnmates/client-core/src/constants/colors'
import { LabelType } from '@returnmates/client-core/src/graphql/generated/api'

import { POPPINS } from '../../constants/styles/fonts'
import { LABEL_TYPE_COLORS_MAP } from './constants'

type Props = {
  labelType?: string
}

const useStyles = makeStyles(() => ({
  filterBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 'calc(100% - 300px)',
  },
  filteredItem: {
    backgroundColor: '#FFFFFF',
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    borderRadius: '28px',
    padding: '8px 13px',
    fontFamily: POPPINS,
    fontWeight: 600,
    lineHeight: '160%',
    fontSize: '12px',
    color: NEON_BLUE_LIGHT,
    '& > span': {
      marginRight: '3px',
    },
  },
  filteredItemTitle: {
    color: NEON_BLUE_LIGHT,
  },
  removeFilter: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  removeIcon: {
    transform: 'rotate(45deg)',
    '& > path': {
      stroke: NEON_BLUE_LIGHT,
    },
  },
  labelFilter: {
    marginLeft: '12px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 17,
    padding: '8px 13px',
    boxSizing: 'border-box',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    textAlign: 'center',
    '& span': {
      color: 'inherit',
    },
  },
  removeLabelIcon: {
    transform: 'rotate(45deg)',
    '& > path': {
      stroke: ({ labelType }: Props) =>
        LABEL_TYPE_COLORS_MAP[(labelType as LabelType) || LabelType.CONSOLIDATED],
    },
  },
  [`labelType${LabelType.DIGITAL}`]: {
    background: 'rgba(75, 209, 160, 0.1)',
    color: `${LABEL_TYPE_COLORS_MAP[LabelType.DIGITAL]}`,
  },
  [`labelType${LabelType.CONSOLIDATED}`]: {
    background: 'rgba(75, 209, 160, 0.1)',
    color: `${LABEL_TYPE_COLORS_MAP[LabelType.CONSOLIDATED]}`,
  },
  [`labelType${LabelType.PHYSICAL}`]: {
    background: 'rgba(99, 93, 246, 0.1)',
    color: `${LABEL_TYPE_COLORS_MAP[LabelType.PHYSICAL]}`,
  },
  [`labelType${LabelType.AMAZON}`]: {
    background: 'rgba(242, 157, 57, 0.1)',
    color: `${LABEL_TYPE_COLORS_MAP[LabelType.AMAZON]}`,
  },
}))

export default useStyles
