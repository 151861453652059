import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SLATE_GREY,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

interface Props {
  width?: number
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: ({ width }: Props) => (width ? width : '500px'),
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
  },
  headerInfo: {
    fontFamily: POPPINS,
    lineHeight: '160%',
  },
  header: {
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '0 0 auto',
    backgroundColor: ALICE_BLUE,
    padding: '13px 30px',
    borderBottom: `1px solid ${SOLITUDE}`,
    width: 'inherit',
    zIndex: 15,
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: MIDNIGHT_EXPRESS,
  },

  actionButtons: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    display: 'flex',
    flex: '0 0 auto',
    borderTop: `1px solid ${SOLITUDE}`,
    padding: '16px 28px',
    width: 'inherit',
  },
  button: {
    textTransform: 'capitalize',
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '130px',
  },
  cancelButton: {
    marginRight: '10px',
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  saveButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  id: {
    fontWeight: 500,
    fontSize: 14,
    color: RAVEN,
  },
  closeButton: {
    cursor: 'pointer',
    alignSelf: 'flex-start',
  },
  detailsBlock: {
    flex: '1 1 auto',
    padding: '30px 35px 30px 30px',
    marginBottom: '75px',
    overflowX: 'hidden',
  },
  errorBlock: {
    margin: '30px 35px 0 30px',
  },
  idGroup: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  copyIcon: {
    color: SLATE_GREY,
  },
}))

export default useStyles
