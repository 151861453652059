interface Props {
  className?: string
}

const EraserIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 9.84C20.25 9.38 20.07 8.94 19.74 8.61L15.39 4.26C14.71 3.58 13.61 3.58 12.93 4.26L4.26 12.93C3.93 13.26 3.75 13.7 3.75 14.16C3.75 14.62 3.93 15.06 4.26 15.39L8.61 19.74C8.95 20.08 9.39 20.25 9.84 20.25C9.88 20.25 9.93 20.25 9.97 20.24C9.99 20.24 10 20.24 10.02 20.24H18.01C18.42 20.24 18.76 19.9 18.76 19.49C18.76 19.08 18.42 18.74 18.01 18.74H12.06L19.73 11.07C20.06 10.74 20.24 10.3 20.24 9.84H20.25ZM5.32 14.33C5.26 14.27 5.25 14.2 5.25 14.16C5.25 14.12 5.25 14.05 5.32 13.99L9.12 10.19L13.81 14.88L10.01 18.68C9.92 18.77 9.77 18.77 9.67 18.68L5.32 14.32V14.33ZM18.68 10.02L14.88 13.82L10.19 9.13L13.99 5.33C14.08 5.24 14.23 5.24 14.33 5.33L18.68 9.69C18.74 9.75 18.75 9.82 18.75 9.86C18.75 9.9 18.75 9.97 18.68 10.03V10.02Z"
      fill="#635DF6"
    />
  </svg>
)

export default EraserIcon
