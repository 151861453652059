interface Props {
  className?: string
}

const ActiveManifest = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8089 7.021C15.3574 7.021 14.7594 7.011 14.0149 7.011C12.199 7.011 10.7059 5.508 10.7059 3.675V0.459C10.7059 0.206 10.503 0 10.2525 0H4.96436C2.49604 0 0.5 2.026 0.5 4.509V15.284C0.5 17.889 2.59109 20 5.1703 20H13.0455C15.5059 20 17.5 17.987 17.5 15.502V7.471C17.5 7.217 17.298 7.012 17.0465 7.013C16.6238 7.016 16.1168 7.021 15.8089 7.021Z"
      fill="#635DF6"
    />
    <path
      d="M13.0843 0.56737C12.7853 0.25637 12.2633 0.47037 12.2633 0.90137V3.53837C12.2633 4.64437 13.1733 5.55437 14.2793 5.55437C14.9773 5.56237 15.9453 5.56437 16.7673 5.56237C17.1883 5.56137 17.4023 5.05837 17.1103 4.75437C16.0553 3.65737 14.1663 1.69137 13.0843 0.56737Z"
      fill="#635DF6"
    />
    <path
      d="M12.1053 10.7093C11.8133 10.4203 11.3433 10.4183 11.0513 10.7113L9.4623 12.3083V7.48133C9.4623 7.07033 9.1283 6.73633 8.7173 6.73633C8.3063 6.73633 7.9723 7.07033 7.9723 7.48133V12.3083L6.3823 10.7113C6.0913 10.4183 5.6203 10.4203 5.3293 10.7093C5.0373 10.9993 5.0373 11.4703 5.3263 11.7623L8.1893 14.6383H8.1903C8.2583 14.7063 8.3393 14.7613 8.4303 14.7993C8.5203 14.8363 8.6183 14.8563 8.7173 14.8563C8.8173 14.8563 8.9153 14.8363 9.0053 14.7983C9.0943 14.7613 9.1753 14.7063 9.2433 14.6393L9.2453 14.6383L12.1073 11.7623C12.3973 11.4703 12.3973 10.9993 12.1053 10.7093Z"
      fill="white"
    />
  </svg>
)

export default ActiveManifest
