import { Checkbox, Radio } from '@mui/material'
import clsx from 'clsx'
import { memo, useCallback } from 'react'

import useStyles from './styles'

interface Props {
  isSelected: boolean
  val: { key: string; label: string }
  select: (id: string) => void
  className?: string
  disabled?: boolean
  onlyOneSelect?: boolean
}

function CheckboxFilter({
  val,
  isSelected,
  select: selectProp,
  className,
  disabled,
  onlyOneSelect,
}: Props): JSX.Element {
  const styles = useStyles({})

  const select = useCallback(() => {
    selectProp(val.key)
  }, [val.key, selectProp])

  return (
    <div className={clsx(styles.checkboxWrapper, className)}>
      {onlyOneSelect ? (
        <Radio
          checked={isSelected}
          onChange={select}
          color="primary"
          disabled={disabled}
          className={styles.checkbox}
        />
      ) : (
        <Checkbox
          checked={isSelected}
          onClick={select}
          className={styles.checkbox}
          disabled={disabled}
          color="primary"
        />
      )}

      <span className={styles.checkboxWrapperLabels}>{val.label}</span>
    </div>
  )
}

export default memo(CheckboxFilter)
