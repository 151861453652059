import { memo } from 'react'

const FormattedValueFormatter = ({
  value,
}: {
  value?: {
    value?: string
    formattedValue: string
  }
}) => <div>{value?.formattedValue}</div>

export default memo(FormattedValueFormatter)
