import { Hub } from '@returnmates/client-core/src/graphql/generated/api'
import { getHubs } from '@returnmates/client-core/src/selectors/hubs'
import AutocompleteInput from '@returnmates/ui-core/src/components/AutocompleteInput'
import ErrorBlock from '@returnmates/ui-core/src/components/ErrorBlock'
import { HTMLAttributes, memo, useCallback, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { useSelector } from 'react-redux'

import CalendarIcon from '../../../components/images/icons/calendar'
import SingleDatePicker from '../../../components/SingleDatePicker'
import useStyles from '../styles'

function OutboundConsolidationModalDetails() {
  const classes = useStyles()
  const [error] = useState<string>('')

  const hubs = useSelector(getHubs)
  const { input: hubOriginInput } = useField('hubIdOrigin')
  const { input: hubDestinationInput } = useField('hubIdDestination')

  const filterHubOptions = useCallback(
    (arr: Array<Hub>, { inputValue }) =>
      arr.filter(
        hub =>
          hub.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
          hub.id?.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [],
  )

  const onHubOriginSelect = useCallback(
    (_, value) => {
      if (value) {
        hubOriginInput.onChange(value.id)
      } else {
        hubOriginInput.onChange('')
      }
    },
    [hubOriginInput],
  )

  const onHubDestinationSelect = useCallback(
    (_, value) => {
      if (value) {
        hubDestinationInput.onChange(value.id)
      } else {
        hubDestinationInput.onChange('')
      }
    },
    [hubDestinationInput],
  )

  return (
    <>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Hub Origin</p>
        <ErrorBlock message={error} />
        <Field name="hubIdOrigin">
          {({ meta }) => (
            <AutocompleteInput
              options={hubs || []}
              filterOptions={filterHubOptions}
              onChange={onHubOriginSelect}
              getOptionLabel={(option: Hub) => option.name || ''}
              renderOption={(events: HTMLAttributes<HTMLElement>, option: Hub) => (
                <p
                  {...events}
                  key={option.id}
                  className={`${events.className} ${classes.optionPrimary}`}
                >
                  {option.name}
                </p>
              )}
              placeholder="Select hub..."
              meta={meta}
            />
          )}
        </Field>
      </div>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Hub Destination</p>
        <ErrorBlock message={error} />
        <Field name="hubIdDestination">
          {({ meta }) => (
            <AutocompleteInput
              options={hubs || []}
              filterOptions={filterHubOptions}
              onChange={onHubDestinationSelect}
              getOptionLabel={(option: Hub) => option.name || ''}
              renderOption={(events: HTMLAttributes<HTMLElement>, option: Hub) => (
                <p
                  {...events}
                  key={option.id}
                  className={`${events.className} ${classes.optionPrimary}`}
                >
                  {option.name}
                </p>
              )}
              placeholder="Select hub..."
              meta={meta}
            />
          )}
        </Field>
      </div>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Expected Departure Date</p>
        <Field name="expectedDeliveryDate">
          {({ input, meta }) => (
            <SingleDatePicker
              placeholder="MM-DD-YYYY"
              value={input.value}
              onChange={input.onChange}
              disablePast
              fullWidth
              keyboardIcon={CalendarIcon}
              className={classes.datePicker}
              error={meta.touched && meta.error}
              helperText={meta.error}
            />
          )}
        </Field>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  )
}

export default memo(OutboundConsolidationModalDetails)
