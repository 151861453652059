import { makeStyles } from '@mui/styles'
import { MIDNIGHT_EXPRESS, RAVEN, SOLITUDE } from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  detailsSection: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '8px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderCollapse: 'collapse',
    '&:not(:first-child)': {
      marginLeft: '20px',
    },
  },
  detailsHeaderWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${SOLITUDE}`,
    padding: '7px 25px',
    backgroundColor: RAVEN,
    width: '100%',
  },
  detailsHeader: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '160%',
    color: '#FFFFFF',
  },
  iconButton: {
    padding: 0,
    marginLeft: '11px',
    '& path': {
      stroke: '#FFFFFF',
    },
  },
  detailsBody: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 25px 25px',
    height: '100%',
  },
  detailsGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '355px',
    '&:not(:first-child)': {
      marginLeft: '20px',
    },
  },
  detailsDataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: '15px',
    },
  },
  detailsType: {
    fontFamily: POPPINS,
    fontSize: 13,
    lineHeight: '150%',
    color: RAVEN,
  },
  detailsData: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    paddingLeft: '15px',
  },
}))

export default useStyles
