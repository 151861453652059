import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'
import { POPPINS } from '@returnmates/ui-core/src/constants/styles/fonts'

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '55px 25px 15px',
    overflow: 'hidden',
  },
  modalHeader: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '130%',
    color: MIDNIGHT_EXPRESS,
    textAlign: 'center',
    paddingTop: 10,
  },
  modalSubHeader: {
    marginBottom: 10,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '160%',
    color: RAVEN,
    marginTop: '10px',
    padding: '0 20px',
    textAlign: 'center',
  },
  buttonsGroup: {
    marginTop: '30px',
    display: 'flex',
  },
  button: {
    textTransform: 'capitalize',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '193px',
  },
  cancelButton: {
    marginRight: '10px',
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  saveButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
}))

export default useStyles
