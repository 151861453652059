import Drawer from '@mui/material/Drawer'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import {
  Carrier,
  Consolidation,
  ConsolidationUpdateInput,
} from '@returnmates/client-core/src/graphql/generated/api'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import { BackdropProps } from '@returnmates/ui-core/src/constants/drawer'
import { FORM_ERROR } from 'final-form'
import arrayMutators from 'final-form-arrays'
import moment from 'moment'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import PickupForm from '../../FormSidebar'
import useStyles from '../styles'
import EditConsolidationFormInner from './EditConsolidationFormInner'

interface Props {
  isOpen: boolean
  currentData: Consolidation | null
  onClose: () => void
  setIsOpen: (val: boolean) => void
  fetchConsolidationPackages?: () => void
}

function EditDetailsForm({
  isOpen,
  currentData,
  onClose,
  setIsOpen,
  fetchConsolidationPackages,
}: Props) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const overlayRef = useRef<HTMLDivElement>(null)

  const updateConsolidation = useCallback(
    async ({
      id,
      status,
      trackingId,
      carrier,
      expectedDeliveryDate,
      weight,
    }: ConsolidationUpdateInput & { id: string; carrier?: Carrier }) => {
      setIsLoading(true)
      try {
        await createAsyncAction(
          dispatch,
          actions.updateConsolidation.request({
            id,
            input: {
              status,
              trackingId,
              carrierId: carrier?.id,
              expectedDeliveryDate: moment(expectedDeliveryDate).isValid()
                ? moment(expectedDeliveryDate).format('YYYY-MM-DD')
                : null,
              weight,
            },
          }),
        )

        await fetchConsolidationPackages?.()

        onClose()
      } catch (err) {
        const { message } = err as Error

        return { [FORM_ERROR]: errorMapper(message || (err as string)) }
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch, onClose, fetchConsolidationPackages],
  )

  const handleClick = useCallback(
    e => {
      if (overlayRef.current && overlayRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    },
    [setIsOpen],
  )

  const headerFields = useMemo(() => {
    return [
      {
        label: 'Consolidation ID',
        value: currentData?.id || '',
      },
    ]
  }, [currentData])

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true })

    return () => {
      document.removeEventListener('click', handleClick, { capture: true })
    }
  })

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'unset'
    }
  }, [isOpen])

  return (
    <Drawer
      classes={{ paperAnchorRight: classes.drawer }}
      open={isOpen}
      onClose={onClose}
      anchor="right"
      BackdropProps={BackdropProps}
    >
      <Form
        initialValues={currentData || {}}
        onSubmit={updateConsolidation}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitError }) => (
          <>
            <div className={classes.pickupFormWrapper}>
              <PickupForm
                onClose={onClose}
                tripSection="Consolidation"
                headerFields={headerFields}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                error={submitError}
                width={660}
              >
                <EditConsolidationFormInner consolidationId={currentData?.id} />
              </PickupForm>
            </div>
          </>
        )}
      />
    </Drawer>
  )
}

export default memo(EditDetailsForm)
