import Button from '@returnmates/ui-core/src/components/Button'
import Modal from '@returnmates/ui-core/src/components/Modal'
import clsx from 'clsx'
import { memo } from 'react'

import useStyles from './styles'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  isLoading?: boolean
}

function RemovePackageModal({ isOpen, onClose, isLoading, onConfirm }: Props) {
  const classes = useStyles()
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={448}>
      <div className={classes.modalWrapper}>
        <p className={classes.modalHeader}>Remove Package</p>
        <p className={classes.modalSubHeader}>
          This package will be removed from its Consolidation
        </p>
        <div className={classes.buttonsGroup}>
          <Button
            className={clsx(classes.button, classes.cancelButton)}
            onClick={onClose}
            label="Cancel"
            cancelButton
          />
          <Button
            className={clsx(classes.button, classes.saveButton)}
            onClick={onConfirm}
            label="Confirm"
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}

export default memo(RemovePackageModal)
