import { memo } from 'react'

import useStyles from '../styles'

function HeaderLabel() {
  const classes = useStyles({})

  return <span className={classes.dateRangeTitle}>Date range</span>
}

export default memo(HeaderLabel)
