import clsx from 'clsx'
import { useCallback, useMemo } from 'react'

import Select from '../Select'
import useStyles from './styles'

type CustomObject<T extends string, D> = D & {
  name?: string
} & {
    [dataName in T]?: string | null
  }

export interface Props<T extends string, D> {
  values: Array<CustomObject<T, D>>
  value?: CustomObject<T, D>
  onChange: (val: CustomObject<T, D>) => void
  placeholder?: string
  disabled?: boolean
  objectKey: T
  fullWidth?: boolean
  className?: string
  popupIconStyles?: string
}

function WrappedSelect<T extends string, D>({
  value: valueProps,
  onChange: onChangeProps,
  values,
  placeholder,
  disabled,
  objectKey,
  fullWidth,
  className,
  popupIconStyles,
}: Props<T, D>): JSX.Element {
  const classes = useStyles({ fullWidth })
  const onChange = useCallback(
    e => {
      const currentValue = values.find(v => v[objectKey] === e.target.value)

      if (currentValue) {
        onChangeProps(currentValue)
      }
    },
    [objectKey, onChangeProps, values],
  )

  const mappedValues = useMemo(() => {
    if (!values.length) {
      return {}
    }

    return values.reduce((acc, val) => {
      if (val[objectKey]) {
        return { ...acc, [val[objectKey] as string]: val.name }
      }

      return acc
    }, {})
  }, [objectKey, values])

  return (
    <Select
      value={valueProps?.[objectKey] ? (valueProps[objectKey] as string) : ''}
      onChange={onChange}
      values={mappedValues}
      className={clsx(classes.select, className || '')}
      placeholder={placeholder}
      disabled={disabled}
      fullWidth={fullWidth}
      popupIconStyles={popupIconStyles}
    />
  )
}

export default WrappedSelect
