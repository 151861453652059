import { Table } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'
import { memo } from 'react'

import useStyles from '../styles'

interface Props extends Table.DataCellProps {
  className?: string
}

const CellComponent = ({ className = '', column, ...props }: Props) => {
  const classes = useStyles()

  return (
    <Table.Cell
      className={clsx(classes.tableCell, className, {
        // @ts-ignore
        [classes.iconColumn]: column.isOnlyIconCell,
        // @ts-ignore
        [classes.centerAlign]: column.align === 'center',
      })}
      column={column}
      {...props}
    />
  )
}

export default memo(CellComponent)
