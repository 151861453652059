import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'

import SortedAscendingIcon from '../../images/icons/sortedAscendingIcon'
import SortedDescendingIcon from '../../images/icons/sortedDescendingIcon'
import SortedIcon from '../../images/icons/sortedIcon'
import { TableColumn } from '../types'

interface Props extends TableHeaderRow.SortLabelProps {
  column: TableColumn
}

const SortLabelComponent = ({ children, direction, column, ...restProps }: Props) => {
  return (
    <TableHeaderRow.SortLabel direction={direction} column={column} {...restProps}>
      {children}
      {column.sortingEnabled ? (
        direction ? (
          direction === 'asc' ? (
            <SortedAscendingIcon />
          ) : (
            <SortedDescendingIcon />
          )
        ) : (
          <SortedIcon />
        )
      ) : null}
    </TableHeaderRow.SortLabel>
  )
}

export default memo(SortLabelComponent)
