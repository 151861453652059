import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Button as MuiButton,
  ButtonGroup,
  Radio,
  TextField as MuiInput,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import Button from '@returnmates/ui-core/src/components/Button'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import CloseIcon from '../../images/icons/close'
import { MAX_POLYBAG_COUNT, MIN_POLYBAG_COUNT } from '../constants'
import useStyles from '../styles'

interface Props {
  onPackageIDLabelsClick: () => void
  handleTrackingIdPrint: () => void
  handlePolybagIDsPrint: (count: number) => void
  showOptions: boolean
  showPolybagOption: boolean
}

function PrintLabels({
  onPackageIDLabelsClick,
  handleTrackingIdPrint,
  handlePolybagIDsPrint,
  showOptions,
  showPolybagOption,
}: Props) {
  const classes = useStyles()
  const [printLabelsInput, setPrintLabelsInput] = useState<null | string>(null)
  const [isOpenPrintLabelsPopup, setIsOpenPrintLabelsPopup] = useState(false)
  const [polybagCount, setPolybagCount] = useState<number | string>(1)

  const handleClick = useCallback(() => {
    setPrintLabelsInput(null)
    setIsOpenPrintLabelsPopup(prevIsOpenPrintLabels => !prevIsOpenPrintLabels)
  }, [])

  const onClosePrintLabelsPopup = useCallback(() => {
    setPrintLabelsInput(null)
    setIsOpenPrintLabelsPopup(false)
  }, [])

  const handleChange = useCallback(e => {
    const value = e.target.value

    setPrintLabelsInput(value)
  }, [])

  const printLabelsMap = useMemo(() => {
    return [
      { key: 'Package ID Labels', label: 'Package ID Labels', disabled: !showOptions, show: true },
      {
        key: 'Package Tracking ID',
        label: 'Package Tracking ID',
        disabled: !showOptions,
        show: true,
      },
      {
        key: 'Polybag IDs',
        label: 'Polybag IDs',
        disabled: !showPolybagOption,
        show: showPolybagOption,
      },
      { key: 'Carrier Labels', label: 'Carrier Labels', disabled: true, show: true },
    ]
  }, [showOptions, showPolybagOption])

  const handlePrint = useCallback(() => {
    switch (printLabelsInput) {
      case printLabelsMap[0].key:
        onPackageIDLabelsClick()
        onClosePrintLabelsPopup()
        break
      case printLabelsMap[1].key:
        handleTrackingIdPrint()
        onClosePrintLabelsPopup()
        break
      case printLabelsMap[2].key:
        handlePolybagIDsPrint(polybagCount as number)
        onClosePrintLabelsPopup()
        setPolybagCount(1)
        break
      case printLabelsMap[3].key:
        break
    }
  }, [
    printLabelsInput,
    printLabelsMap,
    onPackageIDLabelsClick,
    onClosePrintLabelsPopup,
    handleTrackingIdPrint,
    handlePolybagIDsPrint,
    polybagCount,
  ])

  const increasePolybagCount = useCallback(() => {
    setPolybagCount((prev: number) => (prev < MAX_POLYBAG_COUNT ? prev + 1 : prev))
  }, [])

  const decreasePolybagCount = useCallback(() => {
    setPolybagCount((prev: number) => (prev > MIN_POLYBAG_COUNT ? prev - 1 : prev))
  }, [])

  const handleChangePolybagCount = useCallback(event => {
    const newValue = event.target.value
    if (newValue === '') {
      setPolybagCount(newValue)
    } else {
      const numberValue = Number(newValue)
      if (!isNaN(numberValue)) {
        setPolybagCount(numberValue)
      }
    }
  }, [])

  const handleOnBlur = useCallback(() => {
    if (!polybagCount) {
      setPolybagCount(1)
    }
  }, [polybagCount])

  useEffect(() => {
    if (
      polybagCount !== '' &&
      (Number(polybagCount) < MIN_POLYBAG_COUNT || Number(polybagCount) > MAX_POLYBAG_COUNT)
    ) {
      setPolybagCount(
        Number(polybagCount) < MIN_POLYBAG_COUNT ? MIN_POLYBAG_COUNT : MAX_POLYBAG_COUNT,
      )
    }
  }, [polybagCount])

  useEffect(() => {
    if (!printLabelsInput || !showOptions) {
      setPrintLabelsInput(printLabelsMap.find(label => !label.disabled)?.key || null)
    }
  }, [printLabelsInput, printLabelsMap, showOptions])

  return (
    <div className={classes.printLabelsContainer}>
      <Button className={classes.printLabelsButton} label={'Print Labels'} onClick={handleClick} />
      {isOpenPrintLabelsPopup && (
        <Paper className={classes.popupContainer}>
          <div onClick={onClosePrintLabelsPopup} className={classes.closeIconContainer}>
            <CloseIcon strokeColor="#E00B0B" />
          </div>
          <div className={classes.radioGroupContainer}>
            {printLabelsMap.map(val => (
              <>
                {val.show ? (
                  <div className={classes.radioItemContainer} key={val.key}>
                    <Radio
                      checked={printLabelsInput === val.label}
                      onChange={handleChange}
                      name={val.label}
                      value={val.label}
                      color="primary"
                      disabled={val.disabled}
                      size="small"
                    />
                    <Typography
                      className={clsx(classes.printOption, {
                        [classes.printOptionDisabled]: val.disabled,
                      })}
                      variant="body1"
                    >
                      {val.label}
                    </Typography>
                  </div>
                ) : null}

                {printLabelsInput === 'Polybag IDs' && val.key === 'Polybag IDs' ? (
                  <ButtonGroup className={classes.counterWrap}>
                    <MuiButton
                      onClick={decreasePolybagCount}
                      disabled={polybagCount === MIN_POLYBAG_COUNT}
                      className={classes.counterButton}
                    >
                      <RemoveIcon fontSize="small" />
                    </MuiButton>
                    <MuiInput
                      type="number"
                      size="small"
                      className={classes.counterInput}
                      onChange={handleChangePolybagCount}
                      value={polybagCount}
                      onBlur={handleOnBlur}
                    />
                    <MuiButton
                      onClick={increasePolybagCount}
                      disabled={Number(polybagCount) >= MAX_POLYBAG_COUNT}
                      className={classes.counterButton}
                    >
                      <AddIcon fontSize="small" />
                    </MuiButton>
                  </ButtonGroup>
                ) : null}
              </>
            ))}
          </div>
          <div className={classes.closeIconContainer}>
            <Button
              disabled={!printLabelsInput}
              className={classes.printButton}
              label={'Print'}
              onClick={handlePrint}
            />
          </div>
        </Paper>
      )}
    </div>
  )
}

export default memo(PrintLabels)
