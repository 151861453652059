import { makeStyles } from '@mui/styles'
import {
  ECHO_BLUE,
  HAWKES_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

interface Props {
  isOpen?: boolean
  isOperationPage?: boolean
}

const useStyles = makeStyles(() => ({
  rangeDatepicker: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 30,
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: 650,
    top: 70,
    right: ({ isOperationPage }: Props) => (isOperationPage ? 80 : 300),
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    border: ({ isOpen }: Props) => (isOpen ? `1px solid ${SOLITUDE}` : 'none'),
    borderRadius: '8px',
    //hide unnecessary elements
    '& .rdrMonthAndYearPickers': {
      display: 'none',
    },

    //inputs
    '& .rdrDateDisplayWrapper': {
      position: 'absolute',
      top: ({ isOpen }: Props) => (isOpen ? -50 : -49),
      right: ({ isOpen }: Props) => (isOpen ? 0 : 1),
      width: '278px',
      backgroundColor: '#FFFFFF',
    },
    '& .rdrDateDisplay': {
      margin: 0,
    },
    '& .rdrDateInput': {
      border: `1px solid ${SOLITUDE}`,
      boxShadow: 'none',
      '& > input': {
        fontFamily: POPPINS,
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '20px',
        color: MIDNIGHT_EXPRESS,
        height: 36,
        paddingLeft: 13,
        textAlign: 'start',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: 10,
        width: 16,
        height: 16,
        backgroundImage: `url(/assets/images/icons/calendar.svg)`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'none',
      },
    },
    '& .rdrDateDisplayItem + .rdrDateDisplayItem': {
      marginLeft: '6px',
    },
    '& .rdrDateDisplayItemActive': {
      border: `1px solid ${NEON_BLUE_LIGHT}`,
    },
    '& .rdrWarning': {
      right: '30px',
      top: '50%',
      transform: 'translateY(-50%)',
    },

    //Static Ranges
    '& .rdrDefinedRangesWrapper': {
      display: ({ isOpen }: Props) => (isOpen ? 'flex' : 'none'),
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 649,
      borderRadius: '8px 8px 0 0',
      borderBottom: `1px solid ${SOLITUDE}`,
      overflow: 'hidden',
      height: 60,
    },
    '& .rdrStaticRanges': {
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
      order: 2,
    },
    '& .rdrInputRanges': {
      order: 1,
      display: 'none',
    },
    '& .rdrStaticRange': {
      border: `1px solid ${HAWKES_BLUE}`,
      borderRadius: '16px',
      height: 30,
      fontFamily: POPPINS,
      fontWeight: 500,
      fontSize: 12,
      lineHeigth: '160%',
      color: RAVEN,
      marginRight: 10,
      '&:first-child': {
        marginLeft: 'auto',
      },
      '&:hover': {
        borderRadius: '16px',
        background: '#FFFFFF',
        border: `1px solid ${NEON_BLUE_LIGHT}`,
        '& .rdrStaticRangeLabel': {
          background: 'transparent',
          color: NEON_BLUE_LIGHT,
        },
      },
    },
    '& .rdrStaticRangeLabel': {
      padding: '5px 25px',
    },
    '& .rdrStaticRangeSelected': {
      backgroundColor: NEON_BLUE_LIGHT,
      '& > span': {
        color: '#FFFFFF',
      },
    },

    //Calendar
    '& .rdrMonths': {
      display: ({ isOpen }: Props) => (isOpen ? 'flex' : 'none'),
    },
    '& .rdrMonth': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .rdrMonthName': {
      order: 2,
      textAlign: 'center',
      fontFamily: POPPINS,
      fontWeight: 500,
      fontSize: 14,
      lineHeigth: '19px',
      color: MIDNIGHT_EXPRESS,
      padding: 0,
      marginTop: 15,
    },
    '& .rdrWeekDays': {
      order: 1,
      fontFamily: POPPINS,
      fontWeight: 500,
      fontSize: 13,
      lineHeigth: '19px',
      color: RAVEN,
      marginTop: 15,
    },
    '& .rdrDays': {
      order: 3,
      marginTop: 20,
    },

    //Day
    '& .rdrDayNumber': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 30,
      height: 30,
    },
    '& .rdrDay': {
      fontFamily: POPPINS,
      fontWeight: 500,
      fontSize: 13,
      lineHeigth: '19px',
      height: '30px',
      marginTop: '3px',
      overflow: 'hidden',
      '&:hover': {
        '& .rdrDayNumber': {
          borderRadius: '50%',
          border: `1px solid ${NEON_BLUE_LIGHT}`,
          backgroundColor: '#FFFFFF',
          zIndex: 1,
          '& > span': {
            color: `${NEON_BLUE_LIGHT} !important`,
          },
        },
      },
    },
    '& .rdrDayToday .rdrDayNumber span::after': {
      display: 'none',
    },
    '& .rdrDayNumber span': {
      color: MIDNIGHT_EXPRESS,
    },
    '& .rdrDayPassive': {
      '& .rdrDayNumber span': {
        color: ECHO_BLUE,
      },
    },
    '& .rdrStartEdge.rdrEndEdge': {
      borderRadius: '50%',
      width: 30,
      height: 30,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '& .rdrStartEdge': {
      height: 30,
      width: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 'unset',
      background: 'linear-gradient(90deg, #FFFFFF 50%, rgba(99, 93, 246, 0.1) 50%)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: NEON_BLUE_LIGHT,
      },
    },
    '& .rdrEndEdge': {
      height: 30,
      width: '100%',
      top: '50%',
      right: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 'unset',
      background: 'linear-gradient(90deg, rgba(99, 93, 246, 0.1) 50%, #FFFFFF 50%)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: NEON_BLUE_LIGHT,
      },
    },
    '& .rdrInRange': {
      height: 30,
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'rgba(99, 93, 246, 0.1)',
    },
    '& .rdrDayHovered .rdrStartEdge': {
      '&::after': {
        display: 'none',
      },
    },
    '& .rdrDayHovered .rdrEndEdge': {
      '&::after': {
        display: 'none',
      },
    },
    '& .rdrDayInPreview': {
      height: 30,
      top: '50%',
      transform: 'translateY(-50%)',
      background: 'rgba(99, 93, 246, 0.1)',
      border: 'none',
    },
    '& .rdrDay:not(.rdrDayPassive)': {
      '& .rdrInRange, .rdrSelected': {
        '& ~ .rdrDayNumber > span': {
          color: MIDNIGHT_EXPRESS,
        },
      },
    },
    '& .rdrDayStartPreview.rdrDayEndPreview': {
      display: 'none',
    },
    '& .rdrDayStartPreview': {
      visibility: 'visible',
      border: 'none',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      height: 30,
      width: '100%',
      borderRadius: 0,
      background: 'linear-gradient(90deg, transparent 50%, rgba(99, 93, 246, 0.1) 50%)',
    },
    '& .rdrDayEndPreview': {
      visibility: 'visible',
      border: 'none',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      height: 30,
      width: '100%',
      borderRadius: 0,
      background: 'linear-gradient(90deg, rgba(99, 93, 246, 0.1) 50%, transparent 50%)',
    },

    //Arrows
    '& .rdrMonthAndYearWrapper': {
      display: ({ isOpen }: Props) => (isOpen ? 'flex' : 'none'),
      position: 'absolute',
      width: '100%',
      top: 95,
    },
    '& .rdrNextPrevButton': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
    },
  },
  dateRangeTitle: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    paddingLeft: 20,
  },
  calendarIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 12,
  },
  nextArrow: {
    transform: 'rotate(180deg)',
  },
  inputRanges: {
    display: 'none',
  },

  '@media(max-width: 1024px)': {
    datePicker: {
      right: 30,
      '& .rdrDateDisplayWrapper': {
        width: 240,
      },
    },
  },
}))

export default useStyles
