import { adaptV4Theme, createTheme } from '@mui/material/styles'
import {
  ALICE_BLUE,
  ECHO_BLUE,
  ERROR,
  GHOST_WHITE,
  GRAYISH_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE,
  NEON_BLUE_LIGHT,
  PERIWINKLE,
  RAVEN,
  ROCK_BLUE,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../constants/styles/fonts'

declare module '@mui/material/Button/buttonClasses' {
  interface ButtonClasses {
    cancel: string
  }
}

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: NEON_BLUE_LIGHT,
      },
      secondary: {
        main: NEON_BLUE_LIGHT,
      },
    },
    overrides: {
      MuiInput: {
        underline: {
          '&::before': {
            display: 'none',
          },
          '&::after': {
            display: 'none',
          },
        },
        formControl: {
          'label + &': {
            marginTop: 0,
            minWidth: 250,
          },
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: POPPINS,
          color: RAVEN,
          fontSize: 12,
          height: '100%',
          '&.Mui-disabled': {
            '& > input': {
              color: 'rgba(0, 0, 0, 0.38)',
            },
            '&:hover': {
              background: 'none',
            },
          },
        },
      },
      MuiSelect: {
        // @ts-ignore
        root: {
          display: 'flex',
          alignItems: 'center',
          border: 'rgba(0,0,0,0)',
          paddingLeft: 0,
          '&:hover': {
            border: 'rgba(0,0,0,0)',
          },
          '&.Mui-focused': {
            border: 'rgba(0,0,0,0)',
          },
        },
        // @ts-ignore
        selectMenu: {
          height: '100%',
          minHeight: '100%',
        },
        select: {
          fontFamily: POPPINS,
          fontSize: 12,
          fontWeight: 300,
          padding: 0,
          '&:focus': {
            backgroundColor: 'transparent',
            borderRadius: 35,
          },
        },
        icon: {
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
        },
        iconOpen: {
          top: '50%',
          transform: 'rotate(180deg) translateY(8px)',
        },
      },
      PrivateTabIndicator: {
        colorSecondary: {
          backgroundColor: NEON_BLUE_LIGHT,
        },
      },
      MuiDataGrid: {
        root: {
          '& .MuiDataGrid-columnHeader': {
            fontFamily: POPPINS,
            fontSize: 13,
            lineHeight: '160%',
            padding: '16px 30px',
            color: RAVEN,
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: ALICE_BLUE,
            border: `1px solid ${SOLITUDE}`,
            borderBottom: `1px solid ${SOLITUDE}`,
            borderRadius: '5px',
          },
          '& .MuiDataGrid-columnHeaderWrapper': {
            alignItems: 'stretch',
            minHeight: '54px',
            transform: 'translate3d(-1px, 0px, 0px) !important',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
            padding: '0',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell': {
            paddingLeft: '30px',
          },
          '& .MuiDataGrid-cell': {
            fontFamily: POPPINS,
            fontWeight: 500,
            fontSize: 13,
            lineHeight: '160%',
            color: MIDNIGHT_EXPRESS,
          },
          '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:not(:first-child):not(:last-child), .MuiDataGrid-columnHeader:not(:first-child):not(:last-child)': {
            borderLeft: `1px solid ${SOLITUDE}`,
          },
          '& .Mui-focused > svg > path': {
            stroke: NEON_BLUE_LIGHT,
          },
          '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover .MuiDataGrid-sortIcon': {
            opacity: 1,
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: 12,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiMenuItem: {
        root: {
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '160%',
          color: MIDNIGHT_EXPRESS,
        },
      },

      MuiRadio: {
        root: {
          color: ROCK_BLUE,
          '&$checked': {
            color: NEON_BLUE_LIGHT,
          },
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: GHOST_WHITE,
          fontFamily: POPPINS,
          fontWeight: 600,
          fontSize: 11,
          lineHeight: '160%',
          textTransform: 'uppercase',
          color: ECHO_BLUE,
          height: '54px',
          border: `1px solid ${SOLITUDE}`,
        },
      },
      MuiTableCell: {
        root: {
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '160%',
          color: MIDNIGHT_EXPRESS,
          padding: '10px 25px',
          borderLeft: `1px solid ${SOLITUDE}`,
          '&:last-child': {
            borderRight: `1px solid ${SOLITUDE}`,
          },
        },
        head: {
          color: RAVEN,
          textTransform: 'capitalize',
        },
      },
      MuiTableRow: {
        root: {
          borderBottom: `1px solid ${SOLITUDE}`,
          cursor: 'pointer',
        },
        head: {
          cursor: 'default',
        },
      },
      MuiTable: {
        // @ts-ignore
        root: {
          marginTop: 0,
          marginBottom: 0,
          borderCollapse: 'collapse !important',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          backgroundColor: NEON_BLUE_LIGHT,
          fontWeight: 600,
          fontFamily: POPPINS,
          lineHeight: '130%',
          fontSize: 16,
          color: '#FFFFFF',
          borderRadius: 5,
          '&:hover': {
            border: `0px solid ${NEON_BLUE}`,
            backgroundColor: NEON_BLUE,
            '@media (hover: none)': {
              backgroundColor: NEON_BLUE,
            },
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
        },
        cancel: {
          marginRight: '10px',
          marginLeft: 'auto',
          backgroundColor: ALICE_BLUE,
          border: `1px solid ${SOLITUDE}`,
          color: MIDNIGHT_EXPRESS,
          '&:hover': {
            backgroundColor: ALICE_BLUE,
            border: `1px solid ${NEON_BLUE}`,
          },
        },
        outlined: {
          border: 'none',
          padding: '16px 24px',
          '&.Mui-disabled': {
            color: '#FFFFFF',
            border: 'none',
            background: 'rgba(99, 93, 246, 0.4)',
            '&:hover': {
              background: 'rgba(99, 93, 246, 0.4)',
            },
          },
        },
      },
      MuiTextField: {
        root: {
          borderRadius: 4,
          fontSize: 12,
          padding: 0,
          fontWeight: 600,
          boxSizing: 'border-box',
          backgroundColor: '#FFFFFF',
          '& label': {
            backgroundColor: GHOST_WHITE,
            padding: '0 2px',
          },
          '&.Mui-focused': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          border: `1px solid ${PERIWINKLE}`,
          '&.Mui-error': {
            border: `1px solid ${ERROR} !important`,
          },
          '&.Mui-focused': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
          '&:hover': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
          '&.Mui-disabled .MuiInputAdornment-positionEnd': {
            display: 'none',
          },
          '&.Mui-disabled:hover': {
            borderColor: 'transparent',
          },
        },
        notchedOutline: {
          border: 'none',
        },
        inputMultiline: {
          padding: '14px 20px',
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '130%',
          '& label': {
            color: 'rgba(146, 155, 172, 0.6)',
          },
        },
        input: {
          padding: '14px 20px',
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '130%',
          '& label': {
            color: 'rgba(146, 155, 172, 0.6)',
          },
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: 'none',
        },
        rounded: {
          borderRadius: 16,
        },
      },
      MuiTypography: {
        body1: {
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '19px',
          color: MIDNIGHT_EXPRESS,
        },
        body2: {
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '160%',
          color: MIDNIGHT_EXPRESS,
        },
      },
      MuiPopover: {
        paper: {
          border: `1px solid ${SOLITUDE}`,
          boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
          borderRadius: '8px',
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
          '& > :first-child': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          order: 1,
          marginTop: '10px',
          marginBottom: 0,
        },
        daysHeader: {
          marginTop: '30px',
        },
        dayLabel: {
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '19px',
          color: RAVEN,
        },
      },
      MuiPickersCalendar: {
        transitionContainer: {
          marginTop: 0,
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: NEON_BLUE_LIGHT,
          '&:hover': {
            backgroundColor: NEON_BLUE_LIGHT,
            '& .MuiTypography-body2': {
              color: '#FFFFFF',
            },
          },
        },
        current: {
          color: MIDNIGHT_EXPRESS,
        },
        day: {
          '&:hover': {
            borderRadius: '50%',
            border: `1px solid ${NEON_BLUE_LIGHT}`,
            '& .MuiTypography-body2': {
              color: NEON_BLUE_LIGHT,
            },
          },
        },
      },
      MuiTableSortLabel: {
        icon: {
          display: 'none',
        },
      },
      EditColumn: {
        headingCell: {
          borderBottom: `1px solid ${SOLITUDE}`,
        },
        cell: {
          borderBottom: `1px solid ${SOLITUDE}`,
        },
      },
      TableDetailCell: {
        active: {
          backgroundColor: ALICE_BLUE,
          padding: '17px',
          cursor: 'auto !important',
        },
      },
      MuiToggleButton: {
        root: {
          padding: '7px 10px',
          color: MIDNIGHT_EXPRESS,
          '&.Mui-selected': {
            backgroundColor: NEON_BLUE_LIGHT,
            color: '#FFFFFF !important',
            '&:hover': {
              backgroundColor: 'rgba(99, 93, 246, 0.8) !important',
            },
          },
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '160%',
          textTransform: 'capitalize',
        },
      },
      MuiFormLabel: {
        root: {
          '&.Mui-focused': {
            color: NEON_BLUE_LIGHT,
          },
        },
      },
      MuiTablePagination: {
        select: {
          marginRight: 5,
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 13,
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          height: 48,
        },
        endAdornment: {
          top: 'auto',
        },
      },
      MuiCheckbox: {
        root: {
          color: GRAYISH_BLUE,
          '&&.TableSelectCell-checkbox': {
            padding: 0,
          },
        },
        indeterminate: {
          color: NEON_BLUE_LIGHT,
        },
      },
    },
    error: {
      fontFamily: POPPINS,
      fontSize: 16,
      color: ERROR,
    },
  }),
)

export default theme
