import { useEffect, useState } from 'react'

const useRowPerPage = (): [number, (num: number) => void] => {
  const [rowsPerPage, setRowsPerPage] = useState(+(localStorage.getItem('pageSize') || 0) || 10)

  useEffect(() => {
    localStorage.setItem('pageSize', String(rowsPerPage))
  }, [rowsPerPage])

  return [rowsPerPage, setRowsPerPage]
}

export default useRowPerPage
