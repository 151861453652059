import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import SelectItem from '@mui/material/Select'
import { PackageStatus, TripStatus } from '@returnmates/client-core/src/graphql/generated/api'
import clsx from 'clsx'
import { ElementType, memo } from 'react'

import { getStatusIcons } from '../../pages/Main/constants'
import CellIcon from '../CellIcon'
import ArrowDown from '../images/icons/arrowDown'
import useStyles from './styles'

interface Props {
  values: {
    [key: string | number]: string | number
  }
  colorValues?: {
    [key: string]: string
  }
  value: string | number
  onChange?: (event: Event & { target: { value: string; name: string } }) => void
  showItemIcon?: boolean
  disabled?: boolean
  error?: string
  className?: string
  wrapperClassName?: string
  placeholder?: string
  disabledValues?: Array<string>
  withEmpty?: boolean
  fullWidth?: boolean
  popupIconStyles?: string
  errorClassName?: string
  emptyItemTitle?: string
  emptyItemValue?: string | number
  isAllDisabledValues?: boolean
  classNameItem?: string
  classNameSelectedItem?: string
  selectIcon?: ElementType<any> | undefined
}

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'left' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const,
  },
  getContentAnchorEl: null,
}

function Select({
  values,
  value,
  onChange,
  showItemIcon = false,
  disabled = false,
  className,
  error,
  placeholder = 'Select...',
  disabledValues,
  withEmpty,
  wrapperClassName,
  fullWidth = false,
  popupIconStyles,
  errorClassName,
  emptyItemTitle,
  emptyItemValue,
  selectIcon,
  isAllDisabledValues,
  colorValues,
  classNameItem,
  classNameSelectedItem,
}: Props) {
  const classes = useStyles({ fullWidth })

  return (
    <>
      <FormControl className={clsx(wrapperClassName, classes.selectWrapper)}>
        {!value ? <InputLabel className={classes.label}>{placeholder}</InputLabel> : null}
        <SelectItem
          className={clsx(className, classes.select)}
          value={value}
          onChange={onChange}
          IconComponent={selectIcon ? selectIcon : ArrowDown}
          MenuProps={MenuProps}
          disabled={disabled}
          classes={{ icon: popupIconStyles || '' }}
          style={colorValues?.[value] ? { backgroundColor: colorValues?.[value] } : {}}
        >
          {withEmpty ? (
            <MenuItem className={clsx(classes.selectEmpty, classNameItem)} value={emptyItemValue}>
              {emptyItemTitle}
            </MenuItem>
          ) : null}
          {Object.keys(values).map(value => (
            <MenuItem
              className={clsx(classes.selectItem, classNameItem)}
              key={value}
              value={value}
              disabled={disabledValues?.includes(value) || isAllDisabledValues}
              style={colorValues?.[value] ? { backgroundColor: colorValues?.[value] } : {}}
            >
              {showItemIcon ? (
                <ListItemIcon className={classes.itemIcon}>
                  <CellIcon iconComponent={getStatusIcons(value as TripStatus | PackageStatus)} />
                </ListItemIcon>
              ) : null}
              <span className={clsx(classes.selectValue, classNameSelectedItem)}>
                {values?.[value]}
              </span>
            </MenuItem>
          ))}
        </SelectItem>
      </FormControl>
      {error ? <p className={clsx(classes.error, errorClassName)}>{error}</p> : null}
    </>
  )
}

export default memo(Select)
