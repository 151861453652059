import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {},
  main: {
    padding: '80px 0 0 80px',
    overflowX: 'hidden',
    minHeight: '100vh',
  },
  spinnerHandler: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles
