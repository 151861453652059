interface Props {
  className?: string
}

const CheckedSelectorIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9.5" stroke="#635DF6" />
    <circle cx="10.0004" cy="10.0002" r="6.16667" fill="#635DF6" stroke="#635DF6" />
  </svg>
)

export default CheckedSelectorIcon
