import { makeStyles } from '@mui/styles'
import { MIDNIGHT_EXPRESS, NEON_BLUE_LIGHT } from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },
  readOnlyField: {
    fontFamily: POPPINS,
    color: MIDNIGHT_EXPRESS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    padding: '10px',
  },
}))

export default useStyles
