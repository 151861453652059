interface Props {
  className?: string
}

const Printer = ({ className }: Props): JSX.Element => (
  <svg
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7.41662C1 5.89784 2.23122 4.66663 3.75 4.66663H20.25C21.7688 4.66663 23 5.89784 23 7.41663V14.75C23 16.2687 21.7688 17.5 20.25 17.5H19.3333V13.8333H4.66667V17.5H3.75C2.23122 17.5 1 16.2687 1 14.75V7.41662Z"
      fill="#A7B4C8"
    />
    <path
      d="M5.58325 14.75H18.4166V22.0833C18.4166 22.5896 18.0062 23 17.4999 23H6.49992C5.99366 23 5.58325 22.5896 5.58325 22.0833V14.75Z"
      fill="#E8EAEE"
    />
    <path
      d="M18.4167 3.75L5.58342 3.75L5.58342 1.91667C5.58342 1.41041 5.99382 1 6.50008 1L17.5001 1C18.0063 1 18.4167 1.41041 18.4167 1.91667L18.4167 3.75Z"
      fill="#E8EAEE"
    />
  </svg>
)

export default Printer
