import { getStatusColor, getStatusColorBg } from '@returnmates/client-core/src/constants/trip'
import { PackageStatus, TripStatus } from '@returnmates/client-core/src/graphql/generated/api'
import clsx from 'clsx'
import { memo, useCallback } from 'react'

import Select from '../Select'
import useStyles from './styles'

interface Props<T> {
  onSubmit?: (val: string) => void
  onChangeStatusValue: (val: T) => void
  values: {
    [key: string]: string
  }
  value: string
  className?: string
  showItemIcon?: boolean
  disabledValues?: Array<string>
}

function StatusSelect<T>({
  onSubmit,
  onChangeStatusValue,
  value,
  values,
  className,
  showItemIcon,
  disabledValues,
}: Props<T>) {
  const classes = useStyles({
    statusBgColor: getStatusColorBg(value as TripStatus | PackageStatus),
    statusColor: getStatusColor(value as TripStatus | PackageStatus),
  })

  const onStatusChange = useCallback(
    e => {
      const { value } = e.target

      onChangeStatusValue(value)
      onSubmit?.(value)
    },
    [onChangeStatusValue, onSubmit],
  )

  return (
    <Select
      className={clsx(classes.statusSelect, className)}
      value={value}
      onChange={onStatusChange}
      values={values}
      showItemIcon={showItemIcon}
      disabledValues={disabledValues}
    />
  )
}

export default memo(StatusSelect)
