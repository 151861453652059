import { makeStyles } from '@mui/styles'
import { SOLITUDE } from '@returnmates/client-core/src/constants/colors'

const useStyles = makeStyles(() => ({
  formControl: {
    width: 158,
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '35px',
    height: '36px',

    '&:hover': {
      border: `1px solid ${SOLITUDE}`,
    },
  },
  hubDropdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px',
    borderLeft: `1px solid ${SOLITUDE}`,
    borderRight: `1px solid ${SOLITUDE}`,
    height: '100%',
    marginLeft: 'auto',
  },
  hubDropdown: {
    width: 158,
  },
  form: {},
  control: {
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '35px',
  },
  mapPin: {
    width: 16,
    height: 16,
  },
  startAdornment: {
    margin: '0 9px 0 14px',
  },
  endAdornment: {
    marginRight: 10,
    marginLeft: 0,
  },
  select: {
    '& > svg': {
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
    },
  },

  '@media(max-width: 1024px)': {
    hubDropdownWrapper: {
      marginRight: 250,
      padding: '0 10px',
    },
  },
}))

export default useStyles
