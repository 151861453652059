import { makeStyles } from '@mui/styles'
import {
  COMET,
  ERROR,
  NEON_BLUE_LIGHT,
  PERIWINKLE,
  SOLITUDE,
  VAMPIRE_BLACK,
  WHISPER_LIGHT,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  spinnerWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: WHISPER_LIGHT,
    height: '100vh',
  },
  formWrapper: {
    width: 440,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${SOLITUDE}`,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
    padding: '32px 28px 42px',
    boxSizing: 'border-box',
  },
  logo: {
    width: 200,
    margin: '0 auto',
    display: 'block',
  },
  title: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 32,
    textAlign: 'center',
    color: VAMPIRE_BLACK,
    marginTop: 16,
  },
  subtitle: {
    fontFamily: POPPINS,
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    color: COMET,
    marginTop: 12,
  },
  form: {},
  passwordInputWrapper: {
    marginTop: 10,
    position: 'relative',
  },
  input: {
    width: '100%',
    '& div': {
      fontFamily: POPPINS,
    },
  },
  emailInput: {
    marginTop: 30,
  },
  passwordInput: {},
  eyeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 15,
  },
  button: {
    width: '100%',
    marginTop: 20,
    textTransform: 'uppercase',
  },
  error: {
    marginTop: 6,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    fontWeight: 500,
    lineHeight: '160%',
    zIndex: 1,
  },
  googleButton: {
    width: '100%',
    marginTop: 30,
    '&:hover': {
      border: `1px solid ${NEON_BLUE_LIGHT}`,
    },
  },
  orSeparator: {
    width: '100%',
    height: 1,
    backgroundColor: PERIWINKLE,
    marginTop: 30,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      backgroundColor: '#FFFFFF',
      fontFamily: POPPINS,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '130%',
      color: PERIWINKLE,
      padding: '8px 10px 10px',
    },
  },
  '@media (max-width: 768px)': {
    googleButton: {
      width: 315,
    },
    orSeparator: {
      width: 315,
    },
  },
  '@media (max-width: 430px)': {
    googleButton: {
      width: '84%',
    },
    orSeparator: {
      width: '84%',
    },
  },
}))

export default useStyles
