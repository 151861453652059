import Link from '@mui/material/Link'
import clsx from 'clsx'
import { ElementType, memo, useCallback } from 'react'

import { carrierUrls } from './constants'
import useStyles from './styles'

interface Props {
  value?: string | null
  carrier: string | undefined
  icon?: ElementType
  valueClassName?: string
}

function TrackingIdLink({ value, carrier, icon: Icon, valueClassName }: Props) {
  const classes = useStyles()

  const onTrackIdClick = useCallback(
    e => {
      e.stopPropagation()
      if (carrier) {
        const url = `${
          carrierUrls[carrier as 'UPS' | 'USPS' | 'FedEx' | 'DHL' | 'Returnmates']
        }${value}`
        window.open(url, '_blank')?.focus()
      }
    },
    [carrier, value],
  )

  return (
    <>
      {carrier && value ? (
        <Link onClick={onTrackIdClick} className={classes.link}>
          {Icon ? <Icon /> : value}
        </Link>
      ) : (
        <p className={clsx(classes.readOnlyField, valueClassName)}>{value || '-'}</p>
      )}
    </>
  )
}

export default memo(TrackingIdLink)
