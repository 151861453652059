import {
  ConsolidationOrderFields,
  OrderType,
} from '@returnmates/client-core/src/graphql/generated/api'

export const directionMap = {
  asc: OrderType.ASC,
  desc: OrderType.DESC,
}

export const sortingMap = {
  tracking: ConsolidationOrderFields.TRACKING,
  status: ConsolidationOrderFields.STATUS,
  destination: ConsolidationOrderFields.ADDRESS,
  partnerName: ConsolidationOrderFields.PARTNER,
  date: ConsolidationOrderFields.CREATED_AT,
  expectedDeliveryDate: ConsolidationOrderFields.EXPECTED_DATE,
  id: ConsolidationOrderFields.ID,
  weight: ConsolidationOrderFields.WEIGHT,
  carrierName: ConsolidationOrderFields.CARRIER,
  locationName: ConsolidationOrderFields.LOCATION_NAME,
}

export const defaultSorting = { columnName: 'status', direction: 'asc' as const }
