import { memo, useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { AUDITS, MAIN, PACKAGE_LOG } from '../../constants/paths'
import { cognitoGroups } from '../../constants/userPermissions'

interface Props {
  children: JSX.Element
  userRole: string
  accessRoles: string[]
}

function PrivateRoute({ children, userRole, accessRoles }: Props) {
  const [redirectPath, setRedirectPath] = useState(MAIN)
  const { warehouse, auditor, saver } = cognitoGroups

  const hasAccess = useMemo(() => accessRoles.includes(userRole), [accessRoles, userRole])

  useEffect(() => {
    if (userRole === warehouse) {
      setRedirectPath(PACKAGE_LOG)
    } else if (userRole === auditor || userRole === saver) {
      setRedirectPath(AUDITS)
    } else {
      setRedirectPath(MAIN)
    }
  }, [auditor, saver, userRole, warehouse])

  if (hasAccess) {
    return children
  }

  return <Navigate to={redirectPath} />
}

export default memo(PrivateRoute)
