import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'
import { memo, useCallback } from 'react'

import useStyles from '../styles'

interface HeadCellComponentProps extends TableHeaderRow.CellProps {
  column: {
    handleClick?: () => void
    name: string
  }
}

const HeadCellComponent = (props: HeadCellComponentProps) => {
  // eslint-disable-line
  const classes = useStyles()

  const handleClick = useCallback(() => {
    if (props?.column?.handleClick) {
      props?.column?.handleClick()
    }
  }, [props?.column])

  return (
    <TableHeaderRow.Cell
      onClick={handleClick}
      className={clsx(classes.tableCell, props?.column?.handleClick && classes.clickableColumn)}
      {...props}
    />
  )
}

export default memo(HeadCellComponent)
