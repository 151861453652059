import { makeStyles } from '@mui/styles'
import { ERROR, MIDNIGHT_EXPRESS } from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  input: {
    '& > div > input': {
      fontFamily: POPPINS,
      color: MIDNIGHT_EXPRESS,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '160%',
      padding: '10px',
    },
  },
  dateInput: {
    '& > div > div': {
      display: 'none',
    },
    '& > div:hover > div': {
      display: 'flex',
    },
  },
  errorHandler: {
    position: 'relative',
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
  },
  helperText: {
    position: 'absolute',
    top: '100%',
    left: 0,
  },
}))

export default useStyles
