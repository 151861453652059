import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  ERROR,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SLATE_GREY,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  detailsRow: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginTop: 20,
    },
  },
  detailsBlock: {
    minHeight: 40,
    marginTop: 20,
  },
  detailsGroupWrap: {
    marginTop: 20,
    display: 'flex',
  },
  detailsGroupHeader: {
    marginTop: 15,
    display: 'flex',
  },
  detailsGroup: {},
  detailsGroupTitle: {
    width: 130,
    fontFamily: POPPINS,
    fontSize: 14,
    lineHeight: '150%',
    color: RAVEN,
  },
  detailsGroupRow: {
    display: 'flex',
  },
  detailsGroupField: {
    minWidth: 130,
    marginTop: 10,
  },
  inputHead: {
    minWidth: 130,
    flexShrink: 0,
    fontFamily: POPPINS,
    fontSize: 14,
    lineHeight: '150%',
    color: RAVEN,
    marginBottom: 10,
  },
  input: {
    height: 40,
    '& > div': {
      border: `1px solid ${SOLITUDE}`,
      '& > input': {
        fontFamily: POPPINS,
        color: MIDNIGHT_EXPRESS,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '160%',
        padding: 10,
      },
    },
  },
  descriptionInput: {
    height: 74,
    marginTop: 8,
    '& div': {
      height: '100%',
      '& textarea:first-of-type': {
        height: '100% !important',
        boxSizing: 'border-box',
      },
    },
  },
  packageCount: {
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    padding: 10,
  },
  dateInput: {
    '& > div > div': {
      display: 'none',
    },
    '& > div:hover > div': {
      display: 'flex',
    },
  },
  autocomplete: {
    display: 'inline-block',
    width: '100%',
  },
  readOnlyField: {
    fontFamily: POPPINS,
    color: MIDNIGHT_EXPRESS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    padding: 10,
  },
  pickupFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    zIndex: 15,
    height: '100%',
  },
  drawer: {
    boxShadow: '-12px 4px 36px -11px rgba(0, 0, 0, 0.08)',
    maxHeight: 'calc(100vh - 80px)',
    height: 'calc(100% - 80px)',
    top: 80,
    backgroundColor: '#FFFFFF',
  },
  fileWrapper: {
    paddingTop: 20,
  },
  uploadLabel: {
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    paddingTop: 20,
    cursor: 'pointer',
  },
  uploadInput: {
    display: 'none',
  },
  error: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    textAlign: 'start',
  },
  spinner: {
    marginLeft: 20,
  },
  retailerButton: {
    height: 38,
    marginLeft: 40,
  },
  sidebarWrap: {
    position: 'fixed',
    top: -80,
    left: 0,
    width: '100vw',
    height: 'calc(100vh + 80px)',
    overflow: 'hidden',
    zIndex: 15,
  },
  hidden: {
    display: 'none',
  },
  link: {
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },
  table: {
    marginTop: 10,
    borderCollapse: 'collapse',
  },
  tableHead: {
    height: 'auto',
    backgroundColor: '#FFFFFF',
    border: 'none',
  },
  headerRow: {
    backgroundColor: '#FFFFFF',
    border: 'none',
  },
  tableRow: {
    border: 'none',
    '&:nth-child(odd)': {
      backgroundColor: ALICE_BLUE,
    },
  },
  headerCell: {
    cursor: 'auto !important',
    border: 'none',
    borderBottom: `2px solid ${SOLITUDE}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  headerCellSource: {
    maxWidth: '400px',
  },
  bodyCell: {
    border: 'none',
    borderBottom: `1px solid ${SOLITUDE}`,
    cursor: 'auto !important',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  formControlLabelRoot: {
    marginRight: 35,
  },
  formControlLabel: {
    fontFamily: POPPINS,
    color: MIDNIGHT_EXPRESS,
    fontSize: 14,
    lineHeight: '130%',
    marginLeft: 5,
  },
  selectWrapper: {
    width: '100%',
  },
  selectField: {
    border: `1px solid ${SOLITUDE}`,
    borderRadius: 5,
    width: '100%',
    height: 40,
    '& > div': {
      padding: '8px 14px',
    },
    '&:hover': {
      border: `1px solid ${NEON_BLUE_LIGHT}`,
    },
    '&.Mui-focused': {
      border: `1px solid ${NEON_BLUE_LIGHT}`,
    },
  },
  rowGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectWrap: {
    width: 'calc(50% - 10px)',
    marginTop: 20,
  },
  packageDetailsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  boldTitle: {
    fontWeight: 600,
    color: MIDNIGHT_EXPRESS,
  },
  tableWrap: {
    paddingTop: 25,
    marginTop: 25,
    borderTop: `1px solid ${SOLITUDE}`,
  },
  root: {
    height: '100%',
    width: 750,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '0 0 auto',
    backgroundColor: ALICE_BLUE,
    padding: '13px 15px',
    borderBottom: `1px solid ${SOLITUDE}`,
    zIndex: 15,
    position: 'sticky',
    width: 'inherit',
  },
  headerInfo: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: POPPINS,
    lineHeight: '160%',
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    color: MIDNIGHT_EXPRESS,
  },
  id: {
    fontWeight: 500,
    fontSize: 14,
    color: RAVEN,
  },
  closeButton: {
    cursor: 'pointer',
    alignSelf: 'flex-start',
  },
  detailsBlockWrap: {
    flex: '1 1 auto',
    padding: '30px 35px 30px 30px',
    paddingBottom: 20,
    overflowX: 'hidden',
    marginBottom: 75,
  },
  actionButtons: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: 'white',
    display: 'flex',
    flex: '0 0 auto',
    borderTop: `1px solid ${SOLITUDE}`,
    width: 'inherit',
    padding: '16px 28px',
    marginTop: 'auto',
  },
  button: {
    textTransform: 'capitalize',
    lineHeight: '130%',
    borderRadius: 4,
    padding: '10px 0',
    width: 130,
  },
  cancelButton: {
    marginRight: 10,
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  saveButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  receiveButton: {
    marginLeft: 10,
    width: 150,
  },
  statusSelect: {
    '& > div': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  inputHeaderWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    fontSize: 14,
    lineHeight: '150%',
    marginBottom: 10,
    cursor: 'pointer',
    '& svg': {
      marginRight: 6,
      marginBottom: 1,
    },
  },
  newRetailerModal: {
    position: 'absolute',
    left: -70,
    top: 30,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${SOLITUDE}`,
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    marginLeft: 10,
    zIndex: 2,
    padding: 20,
    minWidth: 320,
    height: 'fit-content',
  },
  newRetailerModalTitle: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    marginBottom: 15,
  },
  modalButtons: {
    display: 'flex',
    marginTop: 17,
  },
  errorSpace: {
    marginTop: 30,
  },
  labelTypeWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  labelTitle: {
    marginBottom: 0,
  },
  swapCheckbox: {
    display: 'flex',
    alignItems: 'center',
  },
  errorBlock: {
    margin: '0 35px 30px 30px',
  },
  idGroup: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  copyIcon: {
    color: SLATE_GREY,
  },
  iconWrap: {
    padding: 8,
    marginLeft: 'auto',
  },
  noLabelButton: {
    marginBottom: 10,
    fontSize: 12,
    color: NEON_BLUE_LIGHT,
    fontFamily: POPPINS,
    cursor: 'pointer',
  },
  detailsDataRow: {
    '&:not(:first-child)': {
      marginTop: 15,
    },
  },
  detailsType: {
    fontFamily: POPPINS,
    fontSize: 13,
    lineHeight: '150%',
    color: RAVEN,
    width: 100,
    display: 'inline-block',
    verticalAlign: 'top',
    overflowWrap: 'break-word',
  },
  detailsData: {
    display: 'inline-block',
    maxWidth: 'calc(100% - 120px)',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    paddingLeft: 30,
    overflowWrap: 'break-word',
  },
  optionPrimary: {
    fontFamily: POPPINS,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    cursor: 'pointer',
  },
  optionSecondary: {
    fontWeight: 500,
    fontSize: 13,
  },
  inputRoot: {
    border: `1px solid ${SOLITUDE}`,
    height: 40,
    '& > input': {
      fontSize: 14,
    },
  },
  inputRootConsolidationId: {
    '& > input': {
      padding: '14px 25px 14px 14px',
    },
  },
  inputConsolidatedBy: {
    border: 'none',
    height: 40,
    '& > input': {
      fontSize: 14,
      padding: 0,
    },
  },
  hidePopupIcon: {
    display: 'none',
  },
  customCloseWrapper: {
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    right: 5,
    top: 42,
  },
  consolidationIdOptionList: {
    fontSize: 14,
    position: 'absolute',
    right: 0,
    whiteSpace: 'nowrap',
    minWidth: 265,
  },
  consolidationIdNoOptionsList: {
    width: 0,
    height: 0,
  },
  selectedConsolidationId: {
    padding: '0 !important',
    borderWidth: '0 !important',
    '& > input': {
      fontSize: 11.5,
      color: MIDNIGHT_EXPRESS,
      padding: '0 !important',
      borderWidth: '0 !important',
    },
    '& .Mui-disabled': {
      color: MIDNIGHT_EXPRESS,
      textFillColor: MIDNIGHT_EXPRESS,
    },
  },
  serviceTypeInput: {
    padding: '0 !important',
    borderWidth: '0 !important',
    '& > input': {
      fontSize: 11.5,
      color: MIDNIGHT_EXPRESS,
      padding: '0 !important',
      borderWidth: '0 !important',
    },
    '& .Mui-disabled': {
      color: MIDNIGHT_EXPRESS,
      textFillColor: MIDNIGHT_EXPRESS,
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
  },
  inputHeadCheckbox: {
    width: 'fit-content',
    flexShrink: 0,
    fontFamily: POPPINS,
    fontSize: 14,
    color: RAVEN,
  },
  boxRequiredTitle: {
    marginLeft: 15,
  },
  modalWrapper: {
    padding: '55px 25px 15px',
    overflowX: 'hidden',
  },
  missingLabelTitile: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '36px',
    textAlign: 'center',
    color: MIDNIGHT_EXPRESS,
  },
  missingLabelText: {
    fontFamily: POPPINS,
    fontWeight: 275,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: '25px',
    color: RAVEN,
  },
  missingLabelModalButtons: {
    display: 'grid',
    marginTop: '40px',
    gridTemplateColumns: '1fr 1fr',
  },
  multiPackageModalButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: '40px',
  },
  multiPackageModalButton: {
    width: '100%',
    height: '40px',
  },
  missingLabelButton: {
    width: '193px',
    height: '40px',
  },
  returnDestinationInputHead: {
    width: '200px',
  },
  claimReasonInputHead: {
    width: '200px',
  },
  claimReasonWrap: {
    marginBottom: '30px',
  },
  requiresAgeVerificationLabel: {
    marginLeft: 10,
  },
  requiresDeliverySignatureCheckbox: {
    marginLeft: 15,
  },
  verifiedDobLabel: {
    marginLeft: 10,
  },
  verifiedDobContainer: {
    marginLeft: 15,
    display: 'flex',
  },
  datePicker: {
    width: 265,
    '& > div': {
      border: `1px solid ${SOLITUDE}`,
    },
  },
  minimumAge: {
    width: 265,
  },
  disabledDate: {
    width: 265,
    '& > div': {
      border: `1px solid ${SOLITUDE}`,
    },
    '&:hover > div': {
      pointerEvents: 'none',
    },
  },
  dimensionsContainer: {
    display: 'flex',
    gap: 5,
  },
  packageReasonsSelect: {
    maxWidth: 265,
  },
  packageReasonsSelectError: {
    marginTop: 5,
  },
  scanDate: {
    whiteSpace: 'nowrap',
  },
  scansContainer: {
    overflow: 'hidden',

    '& td': {
      padding: 10,
    },
    '& th': {
      padding: 10,
    },
  },
  user: {
    cursor: 'pointer !important',
  },
}))

export default useStyles
