import {
  NORDSTROM_ITEMS_LABEL_PART,
  NORDSTROM_ITEMS_LABEL_PART_WITHOUT_RMA_ID_OR_ORDER_NUMBER,
  NORDSTROM_ORDER_NUMBER_LABEL_PART,
  NORDSTROM_RMA_ID_LABEL_PART,
  NORDSTROM_RMA_ID_LABEL_PART_WITHOUT_ORDER_NUMBER,
} from './constants'

export const hydratePolybagLabels = (polybadIDs: string[]): string[] => {
  return polybadIDs.map(
    id => `^XA^LH10,10^LS0^MTD^PW812^FX Polybag Details^FWN,0^CF0,60^FX
^FO20,40^GB750,4,4^FS
^FO20,60
^BQN,2,10
^FDQA,{"polybagId": "${id}"}^FS
^FO20,650^A0N,30,30^FDPOLYBAG ID: ${id}^FS
^FO20,700^GB750,4,4^FS^XZ`,
  )
}

export const getNordstromLabelTemplate = (
  template: string,
  rmaId?: string,
  orderNumber?: string,
): string => {
  let updatedTemplate = template
  if (!rmaId || !orderNumber) {
    updatedTemplate = template.replace(
      rmaId ? NORDSTROM_ORDER_NUMBER_LABEL_PART : NORDSTROM_RMA_ID_LABEL_PART,
      '',
    )
    updatedTemplate = updatedTemplate.replace(
      NORDSTROM_ITEMS_LABEL_PART,
      NORDSTROM_ITEMS_LABEL_PART_WITHOUT_RMA_ID_OR_ORDER_NUMBER,
    )

    if (!orderNumber) {
      updatedTemplate = updatedTemplate.replace(
        NORDSTROM_RMA_ID_LABEL_PART,
        NORDSTROM_RMA_ID_LABEL_PART_WITHOUT_ORDER_NUMBER,
      )
    }
  }

  return updatedTemplate
}
