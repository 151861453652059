interface Props {
  className?: string
}

const DashboardIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.400024" y="0.399902" width="8" height="8" rx="2.4" fill="#A7B4C8" />
    <rect x="0.400024" y="11.5999" width="8" height="8" rx="2.4" fill="#D0D9E7" />
    <rect x="11.6" y="0.399902" width="8" height="8" rx="2.4" fill="#D0D9E7" />
    <rect x="11.6" y="11.5999" width="8" height="8" rx="2.4" fill="#D0D9E7" />
  </svg>
)

export default DashboardIcon
