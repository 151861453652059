import Drawer from '@mui/material/Drawer'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { Address, TripStatus } from '@returnmates/client-core/src/graphql/generated/api'
import { PickupDetails } from '@returnmates/client-core/src/type/pickups'
import cognitoErrorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import detailsValidate from '@returnmates/client-core/src/utils/validation/pickupHelpers/details'
import { BackdropProps } from '@returnmates/ui-core/src/constants/drawer'
import { FORM_ERROR } from 'final-form'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import FormSidebar from '../FormSidebar'
import EditAddressFormInner from './EditAddressFormInner'
import useStyles from './styles'

interface Props {
  isOpen: boolean
  addressData?: Address | null
  id?: string
  onClose: () => void
  setIsOpen: (val: boolean) => void
  label: string
  updateAddress: (id: string, addressId: string) => Promise<void>
  createAddress: (val: PickupDetails) => Promise<Address>
  addNewAddress?: (address: Address) => void
  addresses: Address[]
  isZipCodeWithoutValidation?: boolean
  tripStatus?: TripStatus
}

function EditAddressForm({
  isOpen,
  id,
  onClose,
  setIsOpen,
  label,
  addressData,
  updateAddress: updateAddressProps,
  createAddress,
  addNewAddress,
  addresses,
  isZipCodeWithoutValidation,
  tripStatus,
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const overlayRef = useRef<HTMLDivElement>(null)

  const updateAddress = useCallback(
    async (address: Address) => {
      if (tripStatus === TripStatus.IN_PROGRESS) {
        onClose()

        return
      }

      try {
        setIsLoading(true)
        if (addressData?.id === address.id) {
          await createAsyncAction(
            dispatch,
            actions.updateAddress.request({
              id: address.id,
              input: {
                name: address.name as string,
                phone: address.phone as string,
                street: address.street as string,
                unit: address.unit,
                city: address.city as string,
                state: (address.state as string) ?? '',
                zipCode: address.zipCode as string,
                instructions: address.instructions,
                nickname: address.nickname,
                isDefault: address.isDefault,
              },
              skipServicedArea: isZipCodeWithoutValidation,
            }),
          )
        } else if (id) {
          await updateAddressProps(id, address.id)
        }

        onClose()
      } catch (err) {
        const { message } = err as Error

        return { [FORM_ERROR]: cognitoErrorMapper(message || (err as string)) }
      } finally {
        setIsLoading(false)
      }
    },
    [
      tripStatus,
      onClose,
      addressData?.id,
      id,
      dispatch,
      isZipCodeWithoutValidation,
      updateAddressProps,
    ],
  )

  const validate = useCallback(
    val => detailsValidate(val, { email: true, zipCode: Boolean(isZipCodeWithoutValidation) }),
    [isZipCodeWithoutValidation],
  )

  const handleClick = useCallback(
    e => {
      if (overlayRef.current && overlayRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    },
    [setIsOpen],
  )

  const headerFields = useMemo(() => {
    return [
      {
        label: `${label} ID`,
        value: id || '',
      },
    ]
  }, [id, label])

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true })

    return () => {
      document.removeEventListener('click', handleClick, { capture: true })
    }
  })

  useEffect(() => {
    return () => {
      document.body.style.overflowY = 'unset'
    }
  }, [isOpen])

  return (
    <Drawer
      classes={{ paperAnchorRight: classes.drawer }}
      open={isOpen}
      onClose={onClose}
      anchor="right"
      BackdropProps={BackdropProps}
    >
      <Form
        initialValues={addressData}
        onSubmit={updateAddress}
        validate={validate}
        render={({ handleSubmit, submitError }) => (
          <div className={classes.pickupFormWrapper}>
            <FormSidebar
              onClose={onClose}
              tripSection="address"
              headerFields={headerFields}
              handleSubmit={handleSubmit}
              error={submitError}
              isLoading={isLoading}
              width={560}
            >
              <EditAddressFormInner
                addNewAddress={addNewAddress}
                createAddress={createAddress}
                addresses={addresses}
                tripStatus={tripStatus}
                isZipCodeWithoutValidation={isZipCodeWithoutValidation}
              />
            </FormSidebar>
          </div>
        )}
      />
    </Drawer>
  )
}

export default memo(EditAddressForm)
