import { Sorting } from '@devexpress/dx-react-grid'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import {
  Consolidation,
  ConsolidationInboundCreateInput,
  ConsolidationOutboundCreateInput,
} from '@returnmates/client-core/src/graphql/generated/api'
import {
  getConsolidations,
  getConsolidationsCount,
} from '@returnmates/client-core/src/selectors/admin'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import {
  AddInboundConsolidationInput,
  AddOutboundConsolidationInput,
} from '@returnmates/client-core/src/type/consolidation'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import Button from '@returnmates/ui-core/src/components/Button'
import clsx from 'clsx'
import { FORM_ERROR } from 'final-form'
import moment from 'moment'
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ConsolidationFilter } from '../../components/ConsolidationsTable/constants'
import PlusIcon from '../../components/images/icons/plus'
import SectionHeader from '../../components/SectionHeader'
import { Context } from '../Main'
import { consolidationTabs } from '../Main/constants'
import { ConsolidationType } from '../Main/types'
import InboundConsolidationSection from './components/InboundConsolidationSection'
import OutboundConsolidationSection from './components/OutboundConsolidationSection'
import { defaultSorting, directionMap, sortingMap } from './constants'
import useStyles from './styles'
import { getFormattedData } from './utils'

function Consolidations() {
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState(0)
  const consolidations = useSelector(getConsolidations)
  const consolidationsCount = useSelector(getConsolidationsCount)
  const [data, setData] = useState<
    Array<{
      [key: string]: string
    }>
  >([])
  const [isAddConsolidationModalOpen, setIsAddConsolidationModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [
    newInboundConsolidationState,
    setNewInboundConsolidationState,
  ] = useState<AddInboundConsolidationInput | null>(null)
  const [
    newOutboundConsolidationState,
    setNewOutboundConsolidationState,
  ] = useState<AddOutboundConsolidationInput | null>(null)
  const [sorting, setSorting] = useState<Sorting>(defaultSorting)
  const [pageSize, setPageSize] = useState<number>(0)
  const [filter, setFilter] = useState<ConsolidationFilter>({
    status: null,
    partnerCode: null,
    carrierId: null,
    consolidationId: null,
    locationId: null,
    addressId: null,
  })
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const openConsolidationDataRef = useRef<Consolidation | null>(null)
  const [selectedTrips, setSelectedTrips] = useState<Array<string>>([])

  const dispatch = useDispatch()
  const { currentDateRange, currentHubId } = useContext(Context)

  const handleTabChange = useCallback((_, newValue) => {
    setTabIndex(newValue)
  }, [])

  const fetchPartners = useCallback(async () => {
    try {
      await createAsyncAction(dispatch, actions.getPartners.request({}))
    } catch (err) {
      const { message } = err as Error
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    }
  }, [dispatch])

  const requestConsolidations = useCallback(async () => {
    setIsLoading(true)
    try {
      const searchFilter = Object.entries(filter).find(
        item =>
          (item[0] === 'destination' ||
            item[0] === 'packageId' ||
            item[0] === 'trackingId' ||
            item[0] === 'consolidationId') &&
          item[1],
      )
      const searchFilterWhere = searchFilter ? { [searchFilter[0]]: searchFilter[1] } : null

      await createAsyncAction(
        dispatch,
        actions.getConsolidations.request({
          where: searchFilterWhere || {
            fromDate: moment(currentDateRange.startDate).format('YYYY-MM-DD'),
            toDate: moment(currentDateRange.endDate).format('YYYY-MM-DD'),
            hubId: currentHubId,
            type: tabIndex === ConsolidationType.Inbound ? 'INBOUND' : 'OUTBOUND',
            ...filter,
          },
          pagination: {
            size: pageSize,
            page: currentPage,
          },
          order: {
            direction: directionMap[sorting.direction],
            field: sortingMap[sorting.columnName as keyof typeof sortingMap],
          },
        }),
      )

      if (openConsolidationDataRef.current?.id) {
        await createAsyncAction(
          dispatch,
          actions.getConsolidationPackages.request({
            where: {
              consolidationId: openConsolidationDataRef.current?.id,
            },
            pagination: {
              size: 100,
            },
          }),
        )
      }
    } catch (err) {
      const { message } = err as Error

      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentDateRange.endDate,
    currentDateRange.startDate,
    currentHubId,
    currentPage,
    dispatch,
    filter,
    pageSize,
    sorting.columnName,
    sorting.direction,
  ])

  const getLocations = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        actions.getLocations.request({ isFullList: true, where: { hubId: currentHubId } }),
      )
    } catch (err) {
      const { message } = err as Error
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    }
  }, [currentHubId, dispatch])

  const getTableRows = useCallback(() => {
    // @ts-ignore
    setData(getFormattedData(consolidations || []))
  }, [consolidations])

  const handleAddInboudConsolidationModalOpen = useCallback(() => {
    setNewInboundConsolidationState({
      hubId: '',
      partnerDestinationId: '',
    })
    setIsAddConsolidationModalOpen(true)
  }, [])

  const handleAddOutboundConsolidationModalOpen = useCallback(() => {
    setNewOutboundConsolidationState({
      hubIdOrigin: '',
      hubIdDestination: '',
    })
    setIsAddConsolidationModalOpen(true)
  }, [])

  const handleAddConsolidationModalClose = useCallback(
    () => setIsAddConsolidationModalOpen(false),
    [],
  )

  const handleInboundConsolidationCreate = useCallback(
    async ({
      hubId,
      partnerDestinationId,
      carrierId,
      expectedDeliveryDate,
    }: ConsolidationInboundCreateInput) => {
      setIsLoading(true)
      try {
        await createAsyncAction(
          dispatch,
          actions.createConsolidation.request({
            input: {
              hubId,
              partnerDestinationId,
              carrierId,
              expectedDeliveryDate: moment(expectedDeliveryDate).format('YYYY-MM-DD'),
            },
          }),
        )
        setIsAddConsolidationModalOpen(false)
      } catch (err) {
        const { message } = err as Error

        return {
          [FORM_ERROR]: errorMapper(message || (err as string)),
        }
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch],
  )

  const handleOutboundConsolidationCreate = useCallback(
    async ({
      hubIdOrigin,
      hubIdDestination,
      expectedDeliveryDate,
    }: ConsolidationOutboundCreateInput) => {
      setIsLoading(true)
      try {
        await createAsyncAction(
          dispatch,
          actions.createOutboundConsolidation.request({
            input: {
              hubIdOrigin,
              hubIdDestination,
              expectedDeliveryDate: moment(expectedDeliveryDate).format('YYYY-MM-DD'),
            },
          }),
        )
        setIsAddConsolidationModalOpen(false)
      } catch (err) {
        const { message } = err as Error

        return {
          [FORM_ERROR]: errorMapper(message || (err as string)),
        }
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch],
  )

  const onSortingChange = useCallback(newSorting => {
    setSorting(newSorting || defaultSorting)
  }, [])

  const updateOpenConsolidationDataRef = useCallback(
    (val: Consolidation) => (openConsolidationDataRef.current = val),
    [],
  )

  const getPackageServiceTypes = useCallback(async () => {
    try {
      await createAsyncAction(dispatch, actions.getPackageServiceTypes.request())
    } catch (err) {
      const { message } = err as Error
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    }
  }, [dispatch])

  useEffect(() => {
    getPackageServiceTypes()
  }, [getPackageServiceTypes])

  const onFilterUpdate = useCallback(newFilter => {
    setCurrentPage(0)
    setFilter(newFilter)
  }, [])

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      const locationId = searchParams.get('locationId')

      if (locationId) {
        onFilterUpdate({
          //@ts-ignore
          locationId,
          fromDate: undefined,
          toDate: undefined,
          hubId: undefined,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, onFilterUpdate])

  useEffect(() => {
    getTableRows()
  }, [getTableRows])

  useEffect(() => {
    dispatch(actions.getCarriers.request())
  }, [dispatch])

  useEffect(() => {
    fetchPartners()
  }, [fetchPartners])

  useEffect(() => {
    if (pageSize) {
      requestConsolidations()
    }
  }, [pageSize, requestConsolidations])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  return (
    <div className={classes.pickups}>
      <div className={classes.header}>
        <SectionHeader
          title={
            tabIndex === ConsolidationType.Inbound
              ? 'Inbound Consolidations'
              : 'Outbound Consolidations'
          }
          count={consolidationsCount || 0}
          onRefresh={requestConsolidations}
        />

        <div className={classes.headerButtons}>
          <Button
            label={
              tabIndex === ConsolidationType.Inbound
                ? 'Add Inbound Consolidation'
                : 'Add Outbound Consolidation'
            }
            onClick={
              tabIndex === ConsolidationType.Inbound
                ? handleAddInboudConsolidationModalOpen
                : handleAddOutboundConsolidationModalOpen
            }
            startIcon={<PlusIcon className={classes.plusIcon} />}
            className={classes.addTripButton}
          />
        </div>
      </div>

      <div className={clsx(classes.pickupsGroup, { [classes.showFilterPanel]: isFilterActive })}>
        <AppBar position="static" className={classes.appBar}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            {consolidationTabs.map(tab => (
              <Tab key={tab} className={classes.tab} label={tab} />
            ))}
          </Tabs>
        </AppBar>

        {tabIndex === ConsolidationType.Inbound ? (
          <InboundConsolidationSection
            data={data}
            consolidationsCount={consolidationsCount}
            onFilterUpdate={onFilterUpdate}
            onSortingChange={onSortingChange}
            setPageSize={setPageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isLoading={isLoading}
            setSelectedTrips={setSelectedTrips}
            selectedTrips={selectedTrips}
            isFilterActive={isFilterActive}
            setIsFilterActive={setIsFilterActive}
            updateOpenConsolidationDataRef={updateOpenConsolidationDataRef}
            isAddConsolidationModalOpen={isAddConsolidationModalOpen}
            handleAddConsolidationModalClose={handleAddConsolidationModalClose}
            handleConsolidationCreate={handleInboundConsolidationCreate}
            newConsolidationState={newInboundConsolidationState}
          />
        ) : (
          <OutboundConsolidationSection
            data={data}
            consolidationsCount={consolidationsCount}
            onFilterUpdate={onFilterUpdate}
            onSortingChange={onSortingChange}
            setPageSize={setPageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isLoading={isLoading}
            setSelectedTrips={setSelectedTrips}
            selectedTrips={selectedTrips}
            isFilterActive={isFilterActive}
            setIsFilterActive={setIsFilterActive}
            updateOpenConsolidationDataRef={updateOpenConsolidationDataRef}
            isAddConsolidationModalOpen={isAddConsolidationModalOpen}
            handleAddConsolidationModalClose={handleAddConsolidationModalClose}
            handleConsolidationCreate={handleOutboundConsolidationCreate}
            newConsolidationState={newOutboundConsolidationState}
          />
        )}
      </div>
    </div>
  )
}

export default memo(Consolidations)
