import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import client from '@returnmates/client-core/src/graphql/callApi'

import Root from './pages/Root'
import theme from './theme'

const App = (): JSX.Element => {
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client.clientPublic}>
            <Root />
          </ApolloProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  )
}

export default App
