interface Props {
  className?: string
}

const ActivePackageIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="0.399902" width="16" height="19.2" rx="2.4" fill="#635DF6" />
    <rect
      x="0.899902"
      y="0.899902"
      width="18.2"
      height="3.8"
      rx="1.9"
      fill="white"
      stroke="#635DF6"
    />
    <rect x="6" y="9.19995" width="8" height="1.6" rx="0.8" fill="white" />
  </svg>
)

export default ActivePackageIcon
