import { TableRowDetail } from '@devexpress/dx-react-grid-material-ui'
import clsx from 'clsx'
import { memo, useCallback } from 'react'

import useStyles from '../styles'

interface Props extends TableRowDetail.RowProps {
  onRowClick: (val: string) => void
  selectedRow: Array<string>
}

const RowComponent = ({ onRowClick, selectedRow, ...props }: Props) => {
  const { tableRow } = props
  const classes = useStyles()

  const onClick = useCallback(() => {
    onRowClick(tableRow.rowId as string)
  }, [onRowClick, tableRow.rowId])

  return (
    <TableRowDetail.Row
      {...props}
      onClick={onClick}
      className={clsx({ [classes.selectedRow]: selectedRow[0] === tableRow.rowId })}
    />
  )
}

export default memo(RowComponent)
