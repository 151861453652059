import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  ERROR,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  drawer: {
    boxShadow: '-12px 4px 36px -11px rgba(0, 0, 0, 0.08)',
    maxHeight: 'calc(100vh - 80px)',
    height: 'calc(100% - 80px)',
    top: 80,
    backgroundColor: '#FFFFFF',
  },
  pickupFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    visibility: 'visible',
    backgroundColor: '#FFFFFF',
    zIndex: 15,
  },
  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: '20px',
    },
  },
  inputHead: {
    width: 180,
    flexShrink: 0,
    fontFamily: POPPINS,
    fontSize: 14,
    lineHeight: '150%',
    color: RAVEN,
    marginRight: '10px',
  },
  input: {
    '& > div': {
      borderColor: 'transparent',
      color: MIDNIGHT_EXPRESS,
      '& > input': {
        ['-webkit-text-fill-color']: MIDNIGHT_EXPRESS,
        fontFamily: POPPINS,
        color: MIDNIGHT_EXPRESS,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '160%',
        padding: '10px',
      },
    },
  },
  autocomplete: {
    display: 'inline-block',
    width: '100%',
  },
  addressesDropdown: {
    width: '100%',
  },
  newAddressDropdown: {
    zIndex: 3,
    backgroundColor: '#FFFFFF',
    padding: '10px 30px',
    '& > div': {
      maxWidth: '100%',
    },
    '& input': {
      padding: '7px 20px',
    },
  },
  submitError: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
  },
  modalButtons: {
    display: 'flex',
    marginTop: '30px',
  },
  cancelButton: {
    marginRight: '10px',
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  button: {
    textTransform: 'capitalize',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '130px',
  },
  modalCancelButton: {
    minWidth: '150px',
  },
  saveButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  modalSaveButton: {
    width: '100%',
  },
  paperList: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 305,
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    fontSize: 14,
    lineHeight: '150%',
    marginBottom: 10,
    cursor: 'pointer',
    alignSelf: 'flex-end',
    marginRight: 10,
    '& svg': {
      marginRight: 6,
      marginBottom: 1,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  disableAddressDropdown: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
    '&>div': {
      background: ALICE_BLUE,
    },
  },
}))

export default useStyles
