import Paper from '@mui/material/Paper'
import clsx from 'clsx'
import { memo, ReactNode, useCallback } from 'react'

import PlusIcon from '../../components/images/icons/plus'
import useStyles from './styles'

interface Props {
  className?: string
  children: ReactNode
  onButtonClick?: () => void
}

const AddressesDropdown = ({ className, onButtonClick, children }: Props) => {
  const classes = useStyles()

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      onButtonClick?.()
    },
    [onButtonClick],
  )

  return (
    <Paper className={clsx(className, classes.paperList)}>
      {children}
      <div className={classes.addButton} onMouseDown={handleClick}>
        <PlusIcon /> Add a new address
      </div>
    </Paper>
  )
}

export default memo(AddressesDropdown)
