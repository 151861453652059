import { pdf } from '@react-pdf/renderer'
import {
  Consolidation,
  PackingSlip as PackingSlipType,
} from '@returnmates/client-core/src/graphql/generated/api'
import FileIcon from '@returnmates/ui-core/src/components/images/file'
import clsx from 'clsx'
import printJS from 'print-js'
import { memo, useCallback } from 'react'

import useStyles from '../../styles'
import PackingSlip from './PackingSlip'

export interface PackingDetails extends Consolidation {
  currentPackingSlip: PackingSlipType[] | null
}

interface Props {
  details: PackingDetails
}

function DownloadPDFLink({ details }: Props) {
  const classes = useStyles()

  const printPDF = useCallback(async () => {
    const blobPdf = await pdf(<PackingSlip details={details} />).toBlob()

    const blobURL = URL.createObjectURL(blobPdf)
    printJS(blobURL)
    URL.revokeObjectURL(blobURL)
  }, [details])

  const downloadPDF = useCallback(async () => {
    const blobPdf = await pdf(<PackingSlip details={details} />).toBlob()

    const blobURL = URL.createObjectURL(blobPdf)
    const link = document.createElement('a')
    link.href = blobURL
    link.download = `PS_${details.id}.pdf`
    link.click()
    URL.revokeObjectURL(blobURL)
  }, [details])

  const openPdf = useCallback(async () => {
    const blobPdf = await pdf(<PackingSlip details={details} />).toBlob()

    const blobURL = URL.createObjectURL(blobPdf)
    window.open(blobURL, '_blank')

    URL.revokeObjectURL(blobURL)
  }, [details])

  return (
    <div className={classes.packingSlipGroup}>
      <p onClick={openPdf}>
        <FileIcon />
      </p>
      <div className={classes.PDFLinkRow}>
        <p className={classes.PDFLink} onClick={downloadPDF}>
          Download
        </p>
        <p className={clsx(classes.detailsLabel, classes.delim)}>or</p>
        <p className={classes.PDFLink} onClick={printPDF}>
          Print
        </p>
      </div>
    </div>
  )
}

export default memo(DownloadPDFLink)
