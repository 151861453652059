import { DataTypeProvider } from '@devexpress/dx-react-grid'
import { CONSOLIDATION_STATUS_MAP } from '@returnmates/client-core/src/constants/trip'
import { ConsolidationStatus } from '@returnmates/client-core/src/graphql/generated/api'
import { memo } from 'react'

import { getStatusIcons } from '../../../pages/Main/constants'
import { StatusIconProps } from '../../../pages/Main/types'
import CellIcon from '../../CellIcon'
import useStyles from '../styles'

function PickupCellFormatter({ value, column }: DataTypeProvider.ValueFormatterProps) {
  const classes = useStyles()

  return (
    <>
      {column.name === 'status' ? (
        <div className={classes.iconCell}>
          <CellIcon iconComponent={getStatusIcons(value as StatusIconProps)} />
          {CONSOLIDATION_STATUS_MAP[value as ConsolidationStatus]}
        </div>
      ) : null}
      {column.name === 'expectedDeliveryDate' ? <div>{value ? value : '-'}</div> : null}
    </>
  )
}

export default memo(PickupCellFormatter)
