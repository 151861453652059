import { Consolidation } from '@returnmates/client-core/src/graphql/generated/api'
import { AddInboundConsolidationInput } from '@returnmates/client-core/src/type/consolidation'
import { memo } from 'react'

import ConsolidationsTable from '../../../components/ConsolidationsTable'
import AddConsolidationModal from './../components/AddConsolidationModal'

interface Props {
  data: {
    [key: string]: string
  }[]
  consolidationsCount: number | null
  onFilterUpdate: (val: any) => void
  onSortingChange: (val: any) => void
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
  isLoading: boolean
  setSelectedTrips: React.Dispatch<React.SetStateAction<string[]>>
  selectedTrips: string[]
  isFilterActive: boolean
  setIsFilterActive: React.Dispatch<React.SetStateAction<boolean>>
  updateOpenConsolidationDataRef: (val: Consolidation) => Consolidation
  isAddConsolidationModalOpen: boolean
  handleAddConsolidationModalClose: () => void
  handleConsolidationCreate: any
  newConsolidationState: AddInboundConsolidationInput | null
}

function InboundConsolidationSection({
  data,
  consolidationsCount,
  onFilterUpdate,
  onSortingChange,
  setPageSize,
  setCurrentPage,
  currentPage,
  isLoading,
  setSelectedTrips,
  selectedTrips,
  isFilterActive,
  setIsFilterActive,
  updateOpenConsolidationDataRef,
  isAddConsolidationModalOpen,
  handleAddConsolidationModalClose,
  handleConsolidationCreate,
  newConsolidationState,
}: Props) {
  return (
    <div>
      <ConsolidationsTable
        data={data}
        searchPlaceholder="Search consolidations"
        totalCount={consolidationsCount || 0}
        onFilterUpdate={onFilterUpdate}
        onSortingChange={onSortingChange}
        onPageSizeChange={setPageSize}
        onCurrentPageChange={setCurrentPage}
        currentPageNumber={currentPage}
        isLoading={isLoading}
        setSelectedRows={setSelectedTrips}
        selectedRows={selectedTrips}
        isFilterActive={isFilterActive}
        setIsFilterActive={setIsFilterActive}
        updateOpenConsolidationDataRef={updateOpenConsolidationDataRef}
      />

      <AddConsolidationModal
        isOpen={isAddConsolidationModalOpen}
        onClose={handleAddConsolidationModalClose}
        onCreate={handleConsolidationCreate}
        initialValues={newConsolidationState}
        loading={isLoading}
        isInboundModal={true}
      />
    </div>
  )
}

export default memo(InboundConsolidationSection)
