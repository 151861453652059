interface Props {
  className?: string
}

const CompleteIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.25" stroke="#4BD1A0" strokeWidth="1.5" />
    <path
      d="M16.8 8.40001L10.2 15L7.19995 12"
      stroke="#4BD1A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CompleteIcon
