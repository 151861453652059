import { useJsApiLoader } from '@react-google-maps/api'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { getUserData } from '@returnmates/client-core/src/selectors/user'
import refreshTimeout from '@returnmates/client-core/src/utils/refreshTimeout'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import AWS from 'aws-sdk'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import config from '../../config'
import { MAIN } from '../../constants/paths'
import { OAUTH } from '../../constants/paths'
import LoginPage from '../Login'
import MainPage from '../Main'
import OauthLoginPage from '../OauthLogin'
import useStyles from './styles'

AWS.config.update({
  region: config.aws.region,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.aws.identityPoolId,
  }),
})

function Root() {
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()
  const userData = useSelector(getUserData)
  const dispatch = useDispatch()
  const { isLoaded: isLoadedGoogle } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.google.maps,
    libraries: ['places'],
  })

  const checkIsTokenExist = useCallback(async () => {
    try {
      await refreshTimeout(dispatch, true)
    } finally {
      setIsLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    checkIsTokenExist()
  }, [checkIsTokenExist])

  useEffect(() => {
    dispatch(
      actions.setIsAdmin.request({
        isAdmin: true,
      }),
    )
  }, [dispatch])

  if (isLoading || !isLoadedGoogle) {
    return (
      <BrowserRouter>
        <div className={classes.spinnerHandler}>
          <Spinner />
        </div>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      {userData ? (
        <Routes>
          <Route path={MAIN} element={<MainPage />} />
          <Route path="*" element={<MainPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={OAUTH} element={<OauthLoginPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      )}
    </BrowserRouter>
  )
}

export default memo(Root)
