import { IconButton } from '@mui/material'
import FileIcon from '@returnmates/ui-core/src/components/images/file'
import { memo, useCallback } from 'react'

import Delete from '../../images/icons/delete'
import useStyles from '../styles'

interface Props {
  isImage: boolean
  signedUrl: string
  onRemove: (url: string) => void
  originUrl: string
}

function File({ isImage, signedUrl, onRemove: onRemoveProps, originUrl }: Props) {
  const classes = useStyles()

  const openFile = useCallback(() => {
    window.open(signedUrl, '_blank')?.focus()
  }, [signedUrl])

  const onRemove = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      onRemoveProps(originUrl)
    },
    [originUrl, onRemoveProps],
  )

  return (
    <div className={classes.fileWithIcon}>
      <IconButton onClick={onRemove} className={classes.trashIcon}>
        <Delete />
      </IconButton>
      <span onClick={openFile} className={classes.fileWrapper}>
        {isImage ? (
          <img src={signedUrl} className={classes.fileImage} />
        ) : (
          <FileIcon className={classes.fileIcon} />
        )}
      </span>
    </div>
  )
}

export default memo(File)
