import { makeStyles } from '@mui/styles'
import {
  ERROR,
  MIDNIGHT_EXPRESS,
  SHAMROCK,
  SLATE_GREY,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'

import { POPPINS } from '../../constants/styles/fonts'

interface Props {
  type?: SnackBarStatuses
  isOperationPage?: boolean
  isAuditPage?: boolean
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '80px',
    backgroundColor: '#FFFFFF',
    borderBottom: `1px solid ${SOLITUDE}`,
    zIndex: 103,
  },
  main: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    paddingLeft: 79,
    paddingRight: 15,
  },
  leftPart: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '50%',
    borderLeft: `1px solid ${SOLITUDE}`,
    paddingLeft: '40px',
  },
  rightPart: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '50%',
  },
  title: {
    marginLeft: 27,
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
  },
  logoWrapper: {
    position: 'absolute',
    left: 10,
    top: 10,
  },
  notificationBlock: {
    width: 45,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderLeft: ({ isOperationPage, isAuditPage }: Props) =>
      isOperationPage || isAuditPage ? `1px solid ${SOLITUDE}` : 'none',
  },
  notificationIcon: {
    color: SLATE_GREY,
    cursor: 'pointer',
  },
  popperBlock: {
    position: 'absolute',
    width: 500,
    height: 800,
  },
  poppover: {
    zIndex: 105,
    width: 600,
    backgroundColor: '#FFFFFF',
  },
  listWrap: {
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '5px',
  },
  notificationList: {
    maxHeight: 800,
    overflow: 'auto',
  },
  listItem: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${SOLITUDE}`,
    },
  },
  listItemText: {
    '& > span': {
      fontFamily: POPPINS,
      fontSize: 16,
      color: ({ type }: Props) => (type === SnackBarStatuses.SUCCESS ? SHAMROCK : ERROR),
    },
  },
  emptyState: {
    fontFamily: POPPINS,
    fontSize: 16,
    color: SLATE_GREY,
    padding: '30px 20px',
  },

  '@media(max-width: 1024px)': {
    title: {
      marginLeft: 10,
      fontSize: 16,
    },
    leftPart: {
      paddingLeft: 20,
    },
  },
}))

export default useStyles
