interface Props {
  className?: string
}

const sortedDescendingIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="27"
    viewBox="0 0 20 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.83337 15.3334L10 19.5L14.1667 15.3334H5.83337Z" fill="#666D78" />
    <path d="M5.83337 11.6667L10 7.5L14.1667 11.6667H5.83337Z" fill="#A7B4C8" />
  </svg>
)

export default sortedDescendingIcon
