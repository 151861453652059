import { Getter } from '@devexpress/dx-react-core'
import {
  CustomPaging,
  DataTypeProvider,
  EditingState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  PagingPanel,
  PagingState,
  RowDetailState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableColumnResizing,
  TableEditColumn,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@mui/material/Paper'
import { ColumnWidths } from '@returnmates/client-core/src/type/admin'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import clsx from 'clsx'
import { ElementType, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useRowPerPage from '../../hooks/useRowPerPage'
import CellComponent from './components/CellComponent'
import CommandComponent from './components/CommandComponent'
import FormattedValueFormatter from './components/FormattedValueFormatter'
import HeadCellComponent from './components/HeadCellComponent'
import HeadComponent from './components/HeadComponent'
import ImageFormatter from './components/ImageFormatter'
import NoDataCellComponent from './components/NoDataCellComponent'
import Pager from './components/Pager'
import RowComponent from './components/RowComponent'
import SortLabelComponent from './components/SortLabelComponent'
import TableComponent from './components/TableComponent'
import useStyles from './styles'
import { OptionType, TableColumn } from './types'

interface Props {
  data: {
    [key: string]: string
  }[]
  columns: TableColumn[]
  formattedColumnNames?: string[]
  isLoading?: boolean
  setIdForRemove?: (val: string) => void
  tableColumnExtensions?: {
    columnName: string
    width?: number
  }[]
  showDetails?: boolean
  canDeleteRow?: boolean
  canEditRow?: boolean
  canSelectRow?: boolean
  detailsProps?: object
  detailsComponent?: ElementType
  formatterComponent?: ElementType
  setEditableRow?: (row: object) => void
  imageColumn?: Array<string>
  showPaging?: boolean
  showSorting?: boolean
  editOnRowClick?: boolean
  formattedColumns?: Array<string>
  onSortingChange?: (sort: Sorting) => void
  onCurrentPageChange?: (page: number) => void
  currentPageNumber?: number
  onPageSizeChange?: (size: number) => void
  totalCount?: number
  isNetworkFiltering?: boolean
  setSelectedRows?: (values: string[]) => void
  selectedRows?: string[]
  selectOnRowClick?: boolean
  cellClassName?: string
  expandedRowIds?: string[] | null
  setExpandedRowIds?: (val: string[]) => void
  defaultColumnWidths?: ColumnWidths
  onColumnWidthsChange?: (val: ColumnWidths) => void
  sortDescByName?: string[]
  optionType?: OptionType
}

const rowsPerPageOption = [10, 25, 50, 100]

function GridTable({
  data,
  columns,
  formattedColumnNames = [],
  isLoading,
  setIdForRemove,
  tableColumnExtensions,
  showDetails = false,
  canDeleteRow,
  canEditRow,
  canSelectRow = false,
  detailsComponent: RowDetail,
  formatterComponent: ProviderFormatter,
  detailsProps,
  setEditableRow,
  imageColumn = [],
  formattedColumns = [],
  showPaging = false,
  showSorting = false,
  editOnRowClick = false,
  selectOnRowClick = true,
  onSortingChange,
  onPageSizeChange,
  onCurrentPageChange,
  currentPageNumber,
  totalCount,
  isNetworkFiltering,
  setSelectedRows,
  selectedRows,
  cellClassName,
  setExpandedRowIds,
  expandedRowIds,
  defaultColumnWidths,
  onColumnWidthsChange,
  sortDescByName,
  optionType,
}: Props) {
  const styles = useStyles()
  const [rows, setRows] = useState<
    Array<{
      [key: string]: string
    }>
  >([])
  const [rowsPerPage, setRowsPerPage] = useRowPerPage()
  const [currentPage, setCurrentPage] = useState(0)
  const [expandedRows, setExpandedRows] = useState<string[]>(expandedRowIds || [])
  const [notUniqueIds, setNotUniqueIds] = useState<{ [key: string]: string }>({})
  const { pathname } = useLocation()

  const handleChangeExpandedRows = useCallback(
    (rows: string[]) => {
      const mainPath = pathname.split('/')[1]
      const searchParams = new URLSearchParams()

      setExpandedRows(rows)

      if (!optionType) {
        return
      }

      const option = optionType === OptionType.PACKAGE ? 'packageId' : 'tripId'

      if (rows.length) {
        searchParams.set(option, rows[0])
      }

      window.history.replaceState(
        null,
        '',
        `${mainPath}${rows.length ? '?' + searchParams.toString() : ''}`,
      )
    },
    [pathname, optionType],
  )

  const sortingStateColumnExtensions = useMemo(
    () =>
      columns.map(col => ({
        columnName: col.name,
        sortingEnabled: col.sortingEnabled || false,
      })),
    [columns],
  )

  useEffect(() => {
    onPageSizeChange?.(rowsPerPage)
  }, [rowsPerPage, onPageSizeChange])

  useEffect(() => {
    onCurrentPageChange?.(currentPage)
  }, [currentPage, onCurrentPageChange])

  useEffect(() => {
    const idsCounter = data.reduce((acc, item) => {
      if (!acc[item.id]) {
        acc[item.id] = 1
      } else {
        acc[item.id] = acc[item.id] + 1
      }

      return acc
    }, {} as { [key: string]: number })

    const notUniqueIdsArray = Object.keys(idsCounter).filter(key => idsCounter[key] >= 2)

    if (notUniqueIdsArray.length) {
      setRows(
        data.map((item, length) => {
          if (notUniqueIdsArray.includes(item.id)) {
            setNotUniqueIds(prevUniqueIds => {
              return { ...prevUniqueIds, [`${item.id}--${length}`]: item.id }
            })

            return { ...item, uniqueId: `${item.id}--${length}` }
          } else {
            return item
          }
        }),
      )
    } else {
      setRows(data)
      setNotUniqueIds({})
    }
  }, [data])

  const commitChanges = useCallback(
    ({ deleted }) => {
      const rowToRemove = rows.find(row => row.id === deleted[0])
      if (rowToRemove?.id && setIdForRemove) {
        setIdForRemove(rowToRemove.id)
      }
    },
    [rows, setIdForRemove],
  )

  const onEditingRowIdsChange = useCallback(
    (id: Array<string>) => {
      const editableRow = rows.find(row => notUniqueIds?.[row.id] === id[0] || row.id === id[0])
      setEditableRow?.(editableRow || {})
    },
    [notUniqueIds, rows, setEditableRow],
  )

  const onRowClick = useCallback(
    rowId => {
      const id = notUniqueIds?.[rowId] || rowId
      if (selectOnRowClick) {
        const index = expandedRows?.indexOf(id)

        if ((index as number) > -1) {
          setExpandedRowIds?.([]) || handleChangeExpandedRows([])
        } else {
          setExpandedRowIds?.([id]) || handleChangeExpandedRows([id])
        }
      }

      if (editOnRowClick) {
        onEditingRowIdsChange([id])
      }
    },
    [
      editOnRowClick,
      expandedRows,
      handleChangeExpandedRows,
      notUniqueIds,
      onEditingRowIdsChange,
      selectOnRowClick,
      setExpandedRowIds,
    ],
  )

  const moveEditingColumn = useCallback(
    ({ tableColumns }) => {
      const filteredColumns = tableColumns.filter(
        // @ts-ignore
        col => col.type !== TableEditColumn.COLUMN_TYPE && col.type !== TableRowDetail.COLUMN_TYPE,
      )
      if (canDeleteRow || canEditRow) {
        const width = canDeleteRow && canEditRow ? 150 : 80

        return [
          ...filteredColumns,
          {
            key: 'editCommand',
            type: TableEditColumn.COLUMN_TYPE,
            width,
          },
        ]
      } else {
        return filteredColumns
      }
    },
    [canDeleteRow, canEditRow],
  )

  const getRowId = useCallback(row => row?.uniqueId || row.id, [])

  const onRowsPerPageChange = useCallback(
    pageSize => {
      setCurrentPage(0)
      setRowsPerPage(pageSize)
    },
    [setRowsPerPage],
  )

  const onTableSortingChange = useCallback(
    sorting => {
      setCurrentPage(0)
      onSortingChange?.({
        ...sorting[0],
        direction: sortDescByName?.includes(sorting[0].columnName)
          ? sorting[0].direction === 'desc'
            ? 'asc'
            : 'desc'
          : sorting[0].direction,
      })
    },
    [sortDescByName, onSortingChange],
  )

  useEffect(() => {
    setCurrentPage(currentPageNumber || 0)
  }, [currentPageNumber])

  useEffect(() => {
    if (expandedRowIds) {
      handleChangeExpandedRows(expandedRowIds)
    }
  }, [expandedRowIds, handleChangeExpandedRows])

  return (
    <Paper className={clsx(styles.tableWrapper, { [styles.spinnerWrapper]: isLoading })}>
      {isLoading ? (
        <Spinner classes={{ spinnerWrapper: styles.spinner }} />
      ) : (
        <Grid getRowId={getRowId} rows={rows} columns={columns}>
          <DataTypeProvider
            for={formattedColumnNames}
            // @ts-ignore
            formatterComponent={props => <ProviderFormatter {...props} />}
          />
          <SortingState
            onSortingChange={onTableSortingChange}
            columnExtensions={sortingStateColumnExtensions}
          />
          <PagingState
            defaultCurrentPage={0}
            defaultPageSize={rowsPerPage}
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            onPageSizeChange={onRowsPerPageChange}
          />
          <CustomPaging totalCount={totalCount || rows.length} />

          <EditingState
            onCommitChanges={commitChanges}
            onEditingRowIdsChange={onEditingRowIdsChange}
          />
          <SelectionState selection={selectedRows} onSelectionChange={setSelectedRows} />
          <RowDetailState expandedRowIds={expandedRows} />

          <IntegratedFiltering />
          {!isNetworkFiltering ? <IntegratedPaging /> : null}
          <IntegratedSelection />
          <DataTypeProvider for={formattedColumns} formatterComponent={FormattedValueFormatter} />
          <DataTypeProvider for={imageColumn} formatterComponent={ImageFormatter} />
          <Table
            tableComponent={TableComponent}
            headComponent={HeadComponent}
            cellComponent={props => <CellComponent className={cellClassName} {...props} />}
            noDataCellComponent={NoDataCellComponent}
            columnExtensions={tableColumnExtensions}
            rowComponent={props => (
              <RowComponent onRowClick={onRowClick} selectedRow={expandedRows || []} {...props} />
            )}
          />
          {defaultColumnWidths ? (
            <TableColumnResizing
              defaultColumnWidths={defaultColumnWidths}
              onColumnWidthsChange={onColumnWidthsChange}
              resizingMode="nextColumn"
            />
          ) : null}
          <TableHeaderRow
            showSortingControls={showSorting}
            sortLabelComponent={SortLabelComponent}
            cellComponent={HeadCellComponent}
          />
          <TableEditColumn
            showDeleteCommand={canDeleteRow}
            showEditCommand={canEditRow}
            // @ts-ignore
            commandComponent={CommandComponent}
          />
          {showDetails && RowDetail ? (
            <>
              <TableRowDetail
                // @ts-ignore
                contentComponent={props => <RowDetail {...detailsProps} {...props} />}
              />
            </>
          ) : null}
          {canSelectRow ? <TableSelection showSelectionColumn showSelectAll /> : null}

          {showPaging ? (
            <PagingPanel containerComponent={Pager} pageSizes={rowsPerPageOption} />
          ) : null}

          <Getter name="tableColumns" computed={moveEditingColumn} />
        </Grid>
      )}
    </Paper>
  )
}

export default memo(GridTable)
