import Button from '@returnmates/ui-core/src/components/Button'
import Modal from '@returnmates/ui-core/src/components/Modal'
import { memo, useCallback } from 'react'

import useStyles from '../styles'

interface Props {
  isOpen: boolean
  onClose: () => void
  updateTrip: (addressId: string) => void
  createNewTrip: (addressId: string) => void
  onSubmit: () => void
  addressId: string
}

const MultiPackageModal = ({
  isOpen,
  onClose,
  onSubmit,
  updateTrip,
  createNewTrip,
  addressId,
}: Props) => {
  const classes = useStyles({})

  const onUpdateTrip = useCallback(() => {
    updateTrip(addressId)
    onSubmit()
  }, [onSubmit, updateTrip, addressId])

  const onCreateNewTrip = useCallback(() => {
    createNewTrip(addressId)
    onSubmit()
  }, [onSubmit, createNewTrip, addressId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={448}>
      <div className={classes.modalWrapper}>
        <p className={classes.missingLabelTitile}>Warning</p>
        <p className={classes.missingLabelText}>
          This package belongs to a multi-package trip. Do you want to update the address for all
          packages?
        </p>
        <div className={classes.multiPackageModalButtons}>
          <Button
            className={classes.multiPackageModalButton}
            onClick={onUpdateTrip}
            label="Update trip"
          />
          <Button
            className={classes.multiPackageModalButton}
            onClick={onCreateNewTrip}
            label="Create New Trip"
          />
          <Button
            className={classes.multiPackageModalButton}
            onClick={onClose}
            label="Cancel"
            cancelButton
          />
        </div>
      </div>
    </Modal>
  )
}

export default memo(MultiPackageModal)
