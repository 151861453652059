interface Props {
  className?: string
  onClick?: () => void
}

const Camera = ({ className, onClick }: Props): JSX.Element => (
  <svg
    width="40"
    height="40"
    className={className}
    onClick={onClick}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="4" fill="#635DF6" />
    <path
      d="M29.1666 25.8314C29.1666 26.2734 28.991 26.6973 28.6784 27.0099C28.3659 27.3225 27.9419 27.498 27.4999 27.498H12.4999C12.0579 27.498 11.634 27.3225 11.3214 27.0099C11.0088 26.6973 10.8333 26.2734 10.8333 25.8314V16.6647C10.8333 16.2227 11.0088 15.7988 11.3214 15.4862C11.634 15.1736 12.0579 14.998 12.4999 14.998H15.8333L17.4999 12.498H22.4999L24.1666 14.998H27.4999C27.9419 14.998 28.3659 15.1736 28.6784 15.4862C28.991 15.7988 29.1666 16.2227 29.1666 16.6647V25.8314Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.0001 24.1647C21.841 24.1647 23.3334 22.6723 23.3334 20.8314C23.3334 18.9904 21.841 17.498 20.0001 17.498C18.1591 17.498 16.6667 18.9904 16.6667 20.8314C16.6667 22.6723 18.1591 24.1647 20.0001 24.1647Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Camera
