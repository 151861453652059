import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { getHubs } from '@returnmates/client-core/src/selectors/hubs'
import StandingStore from '@returnmates/client-core/src/standingStore'
import MapPin from '@returnmates/ui-core/src/components/images/icons/mapPin'
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'

import ArrowDown from '../images/icons/arrowDown'
import useStyles from './styles'

interface Props {
  currentHubId: string | null
  setCurrentHubId: (val: string | null) => void
}

const allHubsValue = 'AllHubs'

function HubDropdown({ currentHubId, setCurrentHubId }: Props) {
  const classes = useStyles()
  const hubs = useSelector(getHubs)

  const handleChange = useCallback(
    (event: Event & { target: { value: string; name: string } }) => {
      const { value } = event.target

      if (value === allHubsValue) {
        StandingStore.removeItem('hubID')
        setCurrentHubId(null)
      } else {
        StandingStore.setItem('hubID', value)
        setCurrentHubId(value)
      }
    },
    [setCurrentHubId],
  )

  return (
    <div className={classes.hubDropdownWrapper}>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.select}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="end" className={classes.startAdornment}>
              <MapPin className={classes.mapPin} />
            </InputAdornment>
          }
          IconComponent={ArrowDown}
          defaultValue={currentHubId}
          value={currentHubId || allHubsValue}
        >
          {Number(hubs?.length) > 1 ? (
            <MenuItem value={allHubsValue}>All Hubs</MenuItem>
          ) : (
            <MenuItem value={allHubsValue}>No Hubs</MenuItem>
          )}
          {hubs?.length &&
            hubs.map(hub => (
              <MenuItem key={hub.id} value={hub.id}>
                {hub.name?.split(',')[0]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default memo(HubDropdown)
