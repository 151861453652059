import { memo, useCallback } from 'react'

import PlusIcon from '../images/icons/plus'
import useStyles from './styles'

interface Props {
  filteredItem: string
  filteredKey: string
  onFilterItemRemove: (val: string) => void
  filterContainerClasses?: string
}

function FilteredItem({
  filteredItem,
  filteredKey,
  onFilterItemRemove,
  filterContainerClasses,
}: Props) {
  const classes = useStyles({ labelType: filteredKey })

  const removeFilter = useCallback(() => {
    onFilterItemRemove(filteredKey)
  }, [onFilterItemRemove, filteredKey])

  return (
    <div className={filterContainerClasses ? filterContainerClasses : classes.filteredItem}>
      <span className={classes.filteredItemTitle}>{filteredItem}</span>
      <div className={classes.removeFilter} onClick={removeFilter}>
        <PlusIcon
          className={filterContainerClasses ? classes.removeLabelIcon : classes.removeIcon}
        />
      </div>
    </div>
  )
}

export default memo(FilteredItem)
