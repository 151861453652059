import { IconButton, TextField } from '@mui/material'
import Paper from '@mui/material/Paper'
import Button from '@returnmates/ui-core/src/components/Button'
import SearchIcon from '@returnmates/ui-core/src/components/images/icons/search'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ArrowDown from '../images/icons/arrowDown'
import CloseIcon from '../images/icons/close'
import FilterCheckbox from './FilterCheckbox'
import useStyles from './styles'

interface Props {
  isAllAvailable: boolean
  values?: Array<{ key: string; label: string }>
  selectedValues: { [key: string]: boolean }
  onChange: (val: { [key: string]: boolean }) => void
  isLoading?: boolean
  label: string
  onlyOneSelect?: boolean
  disabled?: boolean
  allAvailableLabel?: string
  withSearch?: boolean
  searchPlaceholder?: string
}

function CheckboxFilter({
  values,
  selectedValues,
  onChange,
  isAllAvailable,
  onlyOneSelect,
  isLoading,
  label,
  disabled,
  allAvailableLabel,
  withSearch,
  searchPlaceholder,
}: Props): JSX.Element {
  const styles = useStyles({})
  const ref = useRef<HTMLDivElement>(null)
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true)
  const [filterValues, setFilterValues] = useState(values)
  const [searchValue, setSearchValue] = useState('')

  const allSelectedVal = useMemo(
    () => ({
      key: '',
      label: allAvailableLabel ? allAvailableLabel : 'Select All',
    }),
    [allAvailableLabel],
  )

  const clearStyles = useMemo<{ visibility: 'visible' | 'hidden' }>(
    () => ({ visibility: searchValue.length ? 'visible' : 'hidden' }),
    [searchValue],
  )

  const clearSearch = useCallback(() => {
    setSearchValue('')
    setFilterValues(values)
  }, [values])

  const handleClick = useCallback(() => {
    setIsFilterActive(val => !val)

    clearSearch()
  }, [clearSearch])

  const selectAll = useCallback(() => {
    const allSelected = values?.reduce((acc, val) => ({ ...acc, [val.key]: false }), {})

    clearSearch()

    onChange(allSelected || {})
  }, [values, clearSearch, onChange])

  const select = useCallback(
    val => {
      if (onlyOneSelect) {
        const newSelection = values?.reduce((acc, item) => {
          if (item.key === val) {
            return {
              ...acc,
              [item.key]: true,
            }
          } else {
            return {
              ...acc,
              [item.key]: false,
            }
          }
        }, {})

        onChange(newSelection || {})
      } else {
        const newSelection = values?.reduce((acc, item) => {
          if (item.key === val) {
            return {
              ...acc,
              [item.key]: !selectedValues[val],
            }
          }
          return acc
        }, {})
        onChange(newSelection || {})
      }
    },
    [onChange, onlyOneSelect, selectedValues, values],
  )

  const handleSearchChange = useCallback(
    e => {
      const { value } = e.target

      const filteredValues = values?.filter(val =>
        val.label.toLowerCase().includes(value.toLowerCase()),
      )

      setSearchValue(value)
      setFilterValues(filteredValues)
    },
    [values],
  )

  useEffect(() => {
    if (values?.length) {
      const allChecked = values.every(val => selectedValues?.[val.key])
      const allSelected = values.every(val => !selectedValues?.[val.key]) || allChecked

      setIsAllSelected(Boolean(allSelected))

      if (allChecked && values.length > 1) {
        selectAll()
      }
    }
  }, [selectAll, selectedValues, values])

  useEffect(() => {
    if (isFilterActive) {
      const callback = (e: Event) => {
        if (!ref.current?.contains(e.target as Node)) {
          setIsFilterActive(false)
          clearSearch()
        }
      }

      document.body.addEventListener('click', callback)

      return () => {
        document.body.removeEventListener('click', callback)
      }
    }
  }, [isFilterActive, clearSearch])

  return (
    <div className={styles.checkboxFilterWrapper} ref={ref}>
      <Button
        className={clsx(styles.checkBoxFilterButton, {
          [styles.filterActiveButton]: isFilterActive,
        })}
        disabledStyles={styles.disabledButton}
        label={label}
        onClick={handleClick}
        endIcon={<ArrowDown className={isFilterActive ? styles.filterIconActive : ''} />}
        disabled={isLoading || disabled || !values?.length}
      />
      {isFilterActive ? (
        <Paper className={styles.checkboxFilterPaper}>
          {isAllAvailable ? (
            <FilterCheckbox
              isSelected={isAllSelected}
              val={allSelectedVal}
              select={selectAll}
              onlyOneSelect={onlyOneSelect}
              className={styles.isAllSelected}
            />
          ) : null}
          {withSearch ? (
            <TextField
              variant="standard"
              value={searchValue}
              onChange={handleSearchChange}
              placeholder={searchPlaceholder}
              className={styles.searchInput}
              InputProps={{
                startAdornment: <SearchIcon className={styles.searchIcon} />,
                endAdornment: (
                  <>
                    <IconButton
                      title="Clear"
                      aria-label="Clear"
                      size="small"
                      style={clearStyles}
                      onClick={clearSearch}
                    >
                      <CloseIcon className={styles.closeIcon} />
                    </IconButton>
                  </>
                ),
              }}
            />
          ) : null}
          {filterValues?.map(val => (
            <FilterCheckbox
              isSelected={Boolean(selectedValues[val.key])}
              onlyOneSelect={onlyOneSelect}
              key={val.key}
              val={val}
              select={select}
            />
          ))}
        </Paper>
      ) : null}
    </div>
  )
}

export default memo(CheckboxFilter)
