import { makeStyles } from '@mui/styles'
import {
  ERROR,
  GRAYISH_BLUE,
  MIDNIGHT_EXPRESS,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

interface Props {
  fullWidth?: boolean
}

const useStyles = makeStyles(() => ({
  selectWrapper: {
    width: ({ fullWidth }: Props) => (fullWidth ? '100%' : 'auto'),
  },
  select: {
    position: 'relative',
    fontFamily: POPPINS,
    color: MIDNIGHT_EXPRESS,
    fontSize: 14,
    lineHeight: '160%',
    padding: '0 10px',
    fontWeight: 400,
    '& .MuiSelect-select:focus': {
      borderRadius: 0,
    },
  },
  endAdornment: {},
  selectIcon: {
    display: 'block',
    pointerEvents: 'stroke',
  },
  selectItem: {
    fontFamily: POPPINS,
    color: MIDNIGHT_EXPRESS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
  },
  selectValue: {
    fontSize: 14,
  },
  icon: {
    height: '100%',
  },
  itemIcon: {
    minWidth: 'auto',
    '& > svg': {
      marginRight: 10,
    },
  },
  error: {
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    textAlign: 'start',
  },
  label: {
    position: 'absolute',
    top: '50%',
    left: '14px',
    transform: 'translateY(-50%)',
    fontFamily: POPPINS,
    lineHeight: '20px',
    fontSize: 14,
    color: GRAYISH_BLUE,
  },
  selectEmpty: {
    height: 30,
  },
}))

export default useStyles
