import { getCurrentPackage } from '@returnmates/client-core/src/selectors/packages'
import { memo, useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { useSelector } from 'react-redux'

import Select from '../../../components/Select'
import useStyles from '../styles'
import { getDisabledPackageStatuses, getFilteredStatuses } from '../utils'

function PackageStatusSelect() {
  const classes = useStyles()

  const { input: statusInput } = useField('status')
  const { input: carrier } = useField('carrier')
  const { input: trackingId } = useField('trackingId')

  const currentPackageData = useSelector(getCurrentPackage)

  const filteredStatuses = useMemo(() => {
    if (!currentPackageData) {
      return {}
    }

    const { type } = currentPackageData

    return getFilteredStatuses(type)
  }, [currentPackageData])

  const disabledPackageStatuses = useMemo(() => {
    if (!currentPackageData) {
      return []
    }

    const { tripStatus, type: tripType } = currentPackageData

    return getDisabledPackageStatuses(
      tripStatus,
      statusInput.value,
      tripType,
      carrier.value,
      trackingId.value,
    )
  }, [currentPackageData, statusInput.value, carrier.value, trackingId.value])

  return (
    <Field name="status">
      {({ input }) => (
        <Select
          values={filteredStatuses}
          className={classes.statusSelect}
          value={input.value}
          onChange={input.onChange}
          showItemIcon
          disabledValues={disabledPackageStatuses}
        />
      )}
    </Field>
  )
}

export default memo(PackageStatusSelect)
