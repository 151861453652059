import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import clsx from 'clsx'
import { ElementType, memo } from 'react'

import useStyles from './styles'

interface Props {
  iconComponent: ElementType
  disabled?: boolean
  className?: string
  disabledIconStyles?: string
  tooltipTitle?: string
}

const CellIcon = ({
  iconComponent: Icon,
  disabled,
  className,
  disabledIconStyles,
  tooltipTitle = '',
}: Props) => {
  const classes = useStyles()

  if (!Icon) {
    return null
  }

  return (
    <Tooltip title={!disabled ? tooltipTitle : ''}>
      <IconButton
        className={clsx(classes.cellIconButton, className, {
          [disabledIconStyles || '']: disabled,
        })}
        disabled={disabled}
      >
        <Icon
          className={clsx({
            [disabledIconStyles || '']: disabled,
          })}
        />
      </IconButton>
    </Tooltip>
  )
}

export default memo(CellIcon)
