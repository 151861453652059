import { Font, StyleSheet } from '@react-pdf/renderer'

Font.register({
  family: 'Inter',
  fonts: [
    { src: '/assets/fonts/Inter-Regular.ttf', fontWeight: 400 },
    { src: '/assets/fonts/Inter-Medium.ttf', fontWeight: 500 },
    { src: '/assets/fonts/Inter-SemiBold.ttf', fontWeight: 600 },
  ],
  format: 'truetype',
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    padding: 15,
  },
  frame: {
    position: 'absolute',
    top: 15,
    left: 15,
    height: '100%',
    width: '100%',
    border: `1pt solid #000000`,
  },
  section: {
    margin: '38pt 30pt 30pt',
    flexGrow: 1,
  },
  mainTitle: {
    fontFamily: 'Inter',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 28,
    color: '#000000',
  },
  consolidationSection: {
    marginTop: 70,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 1,
  },
  destinationInfo: {
    flexDirection: 'column',
    maxWidth: '40%',
  },
  destinationText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 14,
    color: '#000000',
  },
  consolidationTable: {
    width: '100%',
    maxWidth: '300pt',
    border: `1pt solid #000000`,
    borderRadius: '5pt',
    marginLeft: 10,
    alignSelf: 'flex-start',
  },
  packageTable: {
    width: '100%',
    border: `1pt solid #000000`,
    borderRadius: '5pt',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '100%',
    borderBottom: `1pt solid #000000`,
    padding: 9,
  },
  tableColBottom: {
    width: 'calc(100% / 3)',
    padding: 9,
  },
  tableCell: {
    fontSize: 8,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#000000',
  },
  packagesSection: {
    marginTop: 60,
  },
  tableCellHeader: {
    fontSize: 10,
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#000000',
    textAlign: 'center',
  },
  tableColHeader1: {
    width: '40%',
    borderBottom: `0.5pt solid #000000`,
    borderRight: `1pt solid #000000`,
    padding: '28pt 9pt 15pt',
  },
  tableCol1: {
    width: '40%',
    borderTop: `0.5pt solid #000000`,
    borderBottom: `0.5pt solid #000000`,
    borderRight: `1pt solid #000000`,
    padding: 9,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableCell1: {
    fontSize: 8,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#000000',
  },
  tableColHeader2: {
    width: '30%',
    borderBottom: `0.5pt solid #000000`,
    borderRight: `1pt solid #000000`,
    padding: '28pt 9pt 15pt',
  },
  tableCol2: {
    width: '30%',
    borderTop: `0.5pt solid #000000`,
    borderBottom: `0.5pt solid #000000`,
    borderRight: `1pt solid #000000`,
    padding: 9,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableCell2: {
    fontSize: 8,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#000000',
  },
  tableColHeader3: {
    width: '30%',
    borderBottom: `0.5pt solid #000000`,
    padding: '28pt 9pt 15pt',
  },
  tableCol3: {
    width: '30%',
    borderTop: `0.5pt solid #000000`,
    borderBottom: `0.5pt solid #000000`,
    padding: 9,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 12,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#000000',
  },
  topMargin: {
    marginTop: 30,
  },
  bottomMargin: {
    marginTop: 60,
  },
})

export default styles
