import { makeStyles } from '@mui/styles'
import {
  ECHO_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '48px',
    color: MIDNIGHT_EXPRESS,
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      fontSize: 16,
      fontWeight: 500,
      color: ECHO_BLUE,
      marginLeft: 20,
      backgroundColor: SOLITUDE,
      height: 30,
      padding: '0 5px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
    },
  },
  refreshButton: {
    color: NEON_BLUE_LIGHT,
  },
}))

export default useStyles
