import { IconButton } from '@mui/material'
import clsx from 'clsx'
import { ElementType, memo, ReactNode } from 'react'

import useStyles from './styles'

interface Props {
  sectionTitle: string
  onIconClick?: () => void
  icon?: ElementType
  firstDetailsGroup?: {
    type: string
    data: string | ReactNode
  }[]
  secondDetailsGroup?: {
    type: string
    data: string | ElementType
  }[]
  className?: string
  children?: ReactNode
}

function DetailsSection({
  sectionTitle,
  onIconClick,
  icon: HeaderIcon,
  className,
  children,
}: Props) {
  const classes = useStyles()
  return (
    <div className={clsx(className, classes.detailsSection)}>
      <div className={classes.detailsHeaderWrap}>
        <div className={classes.detailsHeader}>{sectionTitle}</div>
        {HeaderIcon && (
          <IconButton className={classes.iconButton} onClick={onIconClick}>
            <HeaderIcon />
          </IconButton>
        )}
      </div>
      {children}
    </div>
  )
}

export default memo(DetailsSection)
