import { Partner } from '@returnmates/client-core/src/graphql/generated/api'
import { getPartners } from '@returnmates/client-core/src/selectors/admin'
import { useSelector } from 'react-redux'

const useEnabledPartners: () => Partner[] = () => {
  const partners: Partner[] = useSelector(getPartners)

  return partners.filter((partner: Partner) => partner.isActive)
}

export default useEnabledPartners
