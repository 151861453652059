import { Autocomplete } from '@react-google-maps/api'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { Address, TripStatus } from '@returnmates/client-core/src/graphql/generated/api'
import { getServicedZipCodes as getServicedZipCodesSelector } from '@returnmates/client-core/src/selectors/zipCode'
import { PickupDetails } from '@returnmates/client-core/src/type/pickups'
import formatPhone from '@returnmates/client-core/src/utils/formatPhone'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import AutocompleteInput from '@returnmates/ui-core/src/components/AutocompleteInput'
import Input from '@returnmates/ui-core/src/components/Input'
import clsx from 'clsx'
import { HTMLAttributes, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import AddNewAddress from './AddNewAddress'
import AddressesDropdown from './AddressesDropdown'
import useStyles from './styles'

interface Props {
  addNewAddress?: (address: Address) => void
  createAddress: (val: PickupDetails) => Promise<Address>
  addresses: Address[]
  tripStatus?: TripStatus
  isZipCodeWithoutValidation?: boolean
  addressIdName?: string
}

function EditAddressFormInner({
  addNewAddress,
  createAddress,
  addresses,
  tripStatus,
  isZipCodeWithoutValidation,
  addressIdName = 'id',
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [autocomplete, setAutocomplete] = useState<{
    getPlace: () => {
      formatted_address: string
      name: string
      address_components: Array<{ long_name: string; short_name: string; types: Array<string> }>
    }
  }>()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [isDestinationOpen, setIsDestinationOpen] = useState(false)
  const { input: streetInput } = useField('street')
  const { input: stateInput } = useField('state')
  const { input: zipCodeInput } = useField('zipCode')
  const { input: instructionsInput } = useField('instructions')
  const { input: nameInput } = useField('name')
  const { input: phoneInput } = useField('phone')
  const { input: unitInput } = useField('unit')
  const { input: cityInput } = useField('city')
  const { input: addressIdInput } = useField(addressIdName)

  const servicedZipCodes = useSelector(getServicedZipCodesSelector)

  const onPlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace()
      if (place?.address_components) {
        const street = place.address_components.find(ad => ad.types.includes('route'))
        if (street) {
          const streetNumber = place.address_components.find(ad =>
            ad.types.includes('street_number'),
          )
          if (streetNumber) {
            streetInput.onChange(`${streetNumber.long_name} ${street.long_name}`)
          } else {
            streetInput.onChange(street.long_name)
          }
        } else {
          streetInput.onChange('-')
        }
      }
    }
  }, [autocomplete, streetInput])

  const onLoad = useCallback(a => {
    setAutocomplete(a)
  }, [])

  const onReset = useCallback(
    (value: Address) => {
      streetInput.onChange(value?.street || null)
      stateInput.onChange(value?.state || null)
      addressIdInput.onChange(value?.id || null)
      instructionsInput.onChange(value?.instructions || null)
      nameInput.onChange(value?.name || null)
      unitInput.onChange(value?.unit || null)
      zipCodeInput.onChange(value?.zipCode || null)
      cityInput.onChange(value?.city || null)
      phoneInput.onChange(value?.phone || null)
    },
    [
      streetInput,
      stateInput,
      addressIdInput,
      instructionsInput,
      nameInput,
      unitInput,
      phoneInput,
      cityInput,
      zipCodeInput,
    ],
  )

  const onChangeUserAddress = useCallback(
    (_, value) => {
      onReset(value)
    },
    [onReset],
  )

  const handleOpenAddressModal = useCallback(() => {
    setIsAddressModalOpen(true)
    setIsDestinationOpen(true)
  }, [])

  const handlePartnerDestinationOpen = useCallback(() => {
    setIsDestinationOpen(true)
  }, [])

  const handlePartnerDestinationClose = useCallback(() => {
    if (!isAddressModalOpen) {
      setIsDestinationOpen(false)
      setIsAddressModalOpen(false)
    }
  }, [isAddressModalOpen])

  const handleAddressDropdownClose = useCallback(() => {
    setIsDestinationOpen(false)
    setIsAddressModalOpen(false)
  }, [])

  const getOptionalLabel = useCallback(
    (option: string) => {
      const currentAddress = addresses?.reduce((acc, item: Address) => {
        if (item.id === option) {
          acc = `${item.street}, ${item.city}, ${item.state}, ${item.zipCode}`
        }

        return acc
      }, '')

      return currentAddress || ''
    },
    [addresses],
  )

  const isReadOnly = useMemo(
    () => tripStatus === TripStatus.IN_PROGRESS || tripStatus === TripStatus.PENDING_API,
    [tripStatus],
  )

  const getServicedZipCodes = useCallback(async () => {
    await createAsyncAction(dispatch, actions.getServicedZipCodes.request({ where: {} }))
  }, [dispatch])

  useEffect(() => {
    stateInput.onChange(servicedZipCodes[zipCodeInput.value]?.state)
  }, [zipCodeInput.value, servicedZipCodes]) // eslint-disable-line

  useEffect(() => {
    if (!Object.keys(servicedZipCodes).length) {
      getServicedZipCodes()
    }
  }, [servicedZipCodes, getServicedZipCodes])

  return (
    <>
      <div className={classes.detailsRow}>
        <Field name={addressIdName}>
          {({ input, meta }) => {
            return (
              <AutocompleteInput
                className={clsx(
                  classes.addressesDropdown,
                  isReadOnly && classes.disableAddressDropdown,
                )}
                options={addresses || []}
                value={input.value || null}
                onChange={onChangeUserAddress}
                readOnly={isReadOnly}
                onOpen={handlePartnerDestinationOpen}
                onClose={handlePartnerDestinationClose}
                isOpen={isDestinationOpen}
                isOptionEqualToValue={(option: Address, value: string) => option.id === value}
                getOptionLabel={getOptionalLabel}
                renderOption={(events: HTMLAttributes<HTMLElement>, option: Address) => (
                  <p {...events} key={option.id}>
                    {`${option.street}, ${option.city}, ${option.state}, ${option.zipCode}`}
                  </p>
                )}
                placeholder="Selected Address"
                meta={meta}
                PaperComponent={options =>
                  isAddressModalOpen ? (
                    <AddNewAddress
                      className={options.className}
                      onCloseDropdown={handleAddressDropdownClose}
                      createAddress={createAddress}
                      reset={onReset}
                      addNewAddress={addNewAddress}
                      isZipCodeWithoutValidation={isZipCodeWithoutValidation}
                    />
                  ) : (
                    <AddressesDropdown
                      className={options.className}
                      children={options.children}
                      onButtonClick={handleOpenAddressModal}
                    />
                  )
                }
              />
            )
          }}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Name</p>
        <Field name="name">
          {({ input, meta }) => (
            <Input
              placeholder="No assignee"
              classes={{
                textField: classes.input,
                input: classes.disabled,
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Mobile</p>
        <Field name="phone">
          {({ input, meta }) => (
            <Input
              placeholder="Add number"
              classes={{
                textField: classes.input,
                input: classes.disabled,
              }}
              onChange={input.onChange}
              value={formatPhone(input.value)}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
              type="string"
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Unit</p>
        <Field name="unit">
          {({ input, meta }) => (
            <Input
              placeholder="Add unit"
              classes={{
                textField: classes.input,
                input: classes.disabled,
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Street</p>
        <Field name="street">
          {({ input, meta }) => (
            <Autocomplete
              className={classes.disabled}
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <Input
                placeholder="Add street"
                onChange={input.onChange}
                value={input.value}
                fullWidth
                error={meta.touched && meta.error}
                helperText={meta.error}
                classes={{ textField: classes.input }}
              />
            </Autocomplete>
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>City</p>
        <Field name="city">
          {({ input, meta }) => (
            <Input
              placeholder="Add city"
              classes={{
                textField: classes.input,
                input: classes.disabled,
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>State</p>
        <Field name="state">
          {({ input, meta }) => (
            <Input
              placeholder="Add state"
              classes={{
                textField: classes.input,
                input: classes.disabled,
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Zip code</p>
        <Field name="zipCode">
          {({ input, meta }) => (
            <Input
              placeholder="Add zip code"
              classes={{
                textField: classes.input,
                input: classes.disabled,
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
              type="number"
              max={99999}
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Instructions</p>
        <Field name="instructions">
          {({ input, meta }) => (
            <Input
              placeholder="Address instructions, i.e gate code"
              classes={{
                textField: classes.input,
                input: isReadOnly ? classes.disabled : '',
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
            />
          )}
        </Field>
      </div>
    </>
  )
}

export default memo(EditAddressFormInner)
