import Link from '@mui/material/Link'
import clsx from 'clsx'
import { memo, useCallback } from 'react'
import { useField } from 'react-final-form'

import useStyles from '../styles'

interface Props {
  input: {
    value: string
    onChange: (newValue: string) => void
  }
  field: string
}

function TrackIdField({ input, field }: Props) {
  const classes = useStyles()
  const { input: barcodeScansData } = useField(field)

  const onTrackIdClick = useCallback(() => {
    if (typeof window !== 'undefined') {
      const googleMapsUrl = `https://www.google.com/maps?q=loc:${barcodeScansData.value.latitude},${barcodeScansData.value.longitude}`
      window.open(googleMapsUrl, '_blank')?.focus()
    }
  }, [barcodeScansData.value.latitude, barcodeScansData.value.longitude])

  return (
    <Link onClick={onTrackIdClick} className={clsx(classes.detailsGroupField, classes.link)}>
      {input.value}
    </Link>
  )
}

export default memo(TrackIdField)
