interface Props {
  className?: string
}

const smallLogo = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="57" height="57" rx="13.0286" fill="#4BD1A0" />
    <path
      d="M25.1429 19.7639C29.4286 19.0478 38 20.0504 38 29.7904C38 39.5305 28.7143 40.5331 24.0714 39.8169M25.1429 19.7639V16L18 19.7639L25.1429 23.878V19.7639Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)

export default smallLogo
