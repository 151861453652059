interface Props {
  className?: string
}

const PackageIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="2" y="0.399902" width="16" height="19.2" rx="2.4" fill="#D0D9E7" />
    <rect x="0.400024" y="0.399902" width="19.2" height="4.8" rx="2.4" fill="#A7B4C8" />
    <rect x="6" y="9.19995" width="8" height="1.6" rx="0.8" fill="#A7B4C8" />
  </svg>
)

export default PackageIcon
