import {
  ConsolidationStatus,
  PackageStatus,
  TripStatus,
} from '@returnmates/client-core/src/graphql/generated/api'

export enum EditType {
  Pickup,
  Delivery,
}

export enum AuditEditType {
  Audit,
  Saver,
}

export enum ConsolidationType {
  Inbound,
  Outbound,
}

export enum RoutePricingDriverStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export type StatusIconProps = PackageStatus | TripStatus | ConsolidationStatus

export type IconComponent = ({ className }: { className: string }) => JSX.Element

export interface RoutePricingDriverInfo {
  workerId: string
  status: RoutePricingDriverStatus
  errorMessage: string | null
}
