import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  AMBER,
  ERROR,
  GAINSBORO_LIGHT,
  GRAYISH_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SHAMROCK,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  iconCell: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  rowDetailsWrapper: {},
  mainDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsSection: {
    maxWidth: '400px',
  },
  select: {
    '& > div': {
      alignItems: 'center',
      display: 'flex',
    },
  },
  detailsBody: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 25px 25px',
    height: '100%',
  },
  detailsGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    '&:not(:first-child)': {
      marginLeft: '20px',
    },
  },
  detailsDataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&:not(:first-child)': {
      marginTop: '15px',
    },
  },
  detailsLabel: {
    fontFamily: POPPINS,
    fontSize: 13,
    lineHeight: '150%',
    color: RAVEN,
  },
  detailsData: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    paddingLeft: '30px',
    textAlign: 'right',
  },
  link: {
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 13,
    cursor: 'pointer',
  },
  mediaSection: {
    maxWidth: '240px',
  },
  mediaInner: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  addressDetails: {
    flexWrap: 'wrap',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  fullWidthRow: {
    marginLeft: 0,
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  packagesInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '20px',
  },
  packagesTable: {
    border: 'none',
  },
  newPackageModal: {
    padding: '55px 25px 15px',
    overflow: 'hidden',
  },
  modalHeader: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '130%',
    color: MIDNIGHT_EXPRESS,
    textAlign: 'center',
    paddingTop: 10,
  },
  modalSubHeader: {
    marginBottom: 10,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '160%',
    color: RAVEN,
    marginTop: '10px',
    padding: '0 20px',
    textAlign: 'center',
  },
  buttonsGroup: {
    marginTop: '90px',
    display: 'flex',
  },
  button: {
    textTransform: 'capitalize',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '130px',
  },
  cancelButton: {
    marginRight: '10px',
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  modalCancelButton: {
    minWidth: '150px',
  },
  saveButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  modalSaveButton: {
    width: '100%',
  },
  trackingInput: {
    position: 'relative',
    width: '100%',
  },
  noLabelButton: {
    position: 'absolute',
    bottom: -30,
    right: 0,
    marginBottom: 10,
    fontSize: 12,
    color: NEON_BLUE_LIGHT,
    fontFamily: POPPINS,
    cursor: 'pointer',
  },
  rowGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalDetailsRow: {
    marginTop: '30px',
    textAlign: 'left',
    '&:last-child': {
      marginBottom: '30px',
    },
  },
  modalDetailsTitle: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
  },
  instructionInputs: {
    height: '100px',
    marginTop: '8px',
    '& div': {
      height: '100%',
      '& textarea:first-of-type': {
        height: '100% !important',
        boxSizing: 'border-box',
      },
    },
  },
  selectOption: {
    marginTop: '8px',
  },
  optionPrimary: {
    fontFamily: POPPINS,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    cursor: 'pointer',
  },
  optionSecondary: {
    fontWeight: 500,
    fontSize: 13,
  },
  buttonGroup: {
    marginTop: '15px',
    width: '100%',
  },
  statusButtonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  toggleButton: {
    width: '100%',
  },
  toggleStatusButton: {
    width: '25%',
    '&:not(:first-child)': {
      borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important',
      marginLeft: '0px !important',
    },
    '& > span': {
      fontSize: 12,
    },
  },
  error: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    textAlign: 'start',
  },
  fileWrapper: {
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flex: '50%',
    justifyContent: 'center',
  },
  img: {
    maxWidth: 48,
    maxHeight: 48,
    cursor: 'pointer',
  },
  fileIcon: {},
  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: '20px',
    },
  },
  inputHead: {
    width: 180,
    flexShrink: 0,
    fontFamily: POPPINS,
    fontSize: 14,
    lineHeight: '150%',
    color: RAVEN,
    marginRight: '10px',
  },
  input: {
    '& > div': {
      borderColor: 'transparent',
      '& > input': {
        fontFamily: POPPINS,
        color: MIDNIGHT_EXPRESS,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '160%',
        padding: '10px',
      },
    },
  },
  autocomplete: {
    display: 'inline-block',
    width: '100%',
  },
  sidebarWrap: {
    position: 'fixed',
    top: '-80px',
    left: 0,
    width: '100vw',
    height: 'calc(100vh + 80px)',
    overflow: 'hidden',
    zIndex: 15,
  },
  pickupFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    visibility: 'visible',
    backgroundColor: '#FFFFFF',
    zIndex: 15,
  },
  drawer: {
    boxShadow: '-12px 4px 36px -11px rgba(0, 0, 0, 0.08)',
    maxHeight: 'calc(100vh - 80px)',
    height: 'calc(100% - 80px)',
    top: 80,
    backgroundColor: '#FFFFFF',
  },
  hidden: {
    display: 'none',
  },
  open: {
    right: 0,
    visibility: 'visible',
  },
  datePicker: {
    '& > div': {
      borderColor: 'transparent',
    },
  },
  refreshIcon: {
    position: 'absolute',
    right: -24,
    color: '#A7B4C8',
  },
  modal: {},
  date: {
    width: 600,
    padding: 20,
    overflow: 'scroll',
    '& .slick-slide': {
      display: 'inline-block',
    },
  },
  buttons: {
    textAlign: 'right',
    padding: 10,
    '& > button': {
      height: 36,
    },
  },
  onFleetButton: {
    color: NEON_BLUE_LIGHT,
    textAlign: 'right',
    paddingTop: 8,
    cursor: 'pointer',
  },
  onFleetButtonLoading: {
    color: '#A7B4C8',
  },

  toolbar: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderTop: `1px solid ${SOLITUDE}`,
    padding: '15px 0',
    '& > *': {
      marginTop: 5,
    },
  },
  undersCheckbox: {
    marginLeft: '15px',
  },
  calendarWrapper: {
    position: 'relative',
  },
  tooltip: {
    fontFamily: POPPINS,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '160%',
    color: '#FFFFFF',
    backgroundColor: 'rgba(102, 109, 120, 0.94)',
    marginTop: 15,
  },
  iconButton: {
    padding: 0,
    marginLeft: '11px',
  },
  calendarIcon: {
    width: 18,
    height: 18,
  },
  modalButtons: {
    display: 'flex',
    margin: '0 25px 20px',
  },
  applyButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  toolbarDatePicker: {
    position: 'absolute',
    left: '-320px',
    top: 0,
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  cancelUndersButton: {
    padding: 10,
    marginRight: 20,
  },
  underListText: {
    flex: '100%',
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 14,
    color: NEON_BLUE_LIGHT,
    paddingLeft: 16,
    cursor: 'pointer',
  },
  copyIcon: {
    color: GRAYISH_BLUE,
  },
  filterPanel: {
    position: 'absolute',
    top: -50,
    right: 0,
    width: 'calc(100% - 300px)',
  },
  currentUserLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    borderRadius: '28px',
    padding: '8px 13px',
    fontFamily: POPPINS,
    fontWeight: 600,
    lineHeight: '160%',
    fontSize: '12px',
    color: NEON_BLUE_LIGHT,
    marginLeft: 10,
  },
  userName: {
    paddingRight: '15px',
  },
  removeFilter: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  trackingField: {
    '& > div': {
      border: `1px solid ${SOLITUDE}`,
    },
  },
  removeIcon: {
    transform: 'rotate(45deg)',
    '& > path': {
      stroke: NEON_BLUE_LIGHT,
    },
  },
  mediaBlock: {
    margin: '20px 15px 0',
    width: '100%',
    minHeight: 86,
  },
  dropZoneLabel: {
    fontFamily: POPPINS,
    fontSize: 11,
    color: '#000000',
    '& > span': {
      color: NEON_BLUE_LIGHT,
    },
  },
  dndStyles: {
    padding: 9,
    background: GAINSBORO_LIGHT,
    border: `1px dashed rgba(0, 0, 0, 0.3)`,
  },
  packingSlipGroup: {
    marginTop: 10,
  },
  PDFLink: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '15px',
    color: NEON_BLUE_LIGHT,
    marginTop: 6,
  },
  PDFLinkDisabled: {
    color: RAVEN,
    pointerEvents: 'none',
    marginTop: 6,
  },
  PDFLinkRow: {
    display: 'flex',
    alignItems: 'center',
  },
  delim: {
    margin: '6px 5px 0',
  },
  disabledFileIcon: {
    '& > path': {
      fill: RAVEN,
    },
  },
  disableIcon: {
    display: 'none',
  },
  bodyCell: {
    border: 'none',
    borderBottom: `1px solid ${SOLITUDE}`,
    cursor: 'auto !important',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  tableRow: {
    border: 'none',
    '&:nth-child(odd)': {
      backgroundColor: ALICE_BLUE,
    },
  },
  detailsBlock: {
    minHeight: 40,
    marginTop: 20,
  },
  tableWrap: {
    paddingTop: 25,
  },
  table: {
    marginTop: 10,
    borderCollapse: 'collapse',
  },
  tableHead: {
    height: 'auto',
    backgroundColor: '#FFFFFF',
    border: 'none',
  },
  headerRow: {
    backgroundColor: '#FFFFFF',
    border: 'none',
  },
  headerCell: {
    cursor: 'auto !important',
    border: 'none',
    borderBottom: `2px solid ${SOLITUDE}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  scannedListBlock: {
    marginTop: 13,
  },
  scannedListTitle: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '15px',
    color: MIDNIGHT_EXPRESS,
  },
  scannedIdsBlock: {
    marginTop: 10,
  },
  scannedId: {
    fontFamily: POPPINS,
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '15px',
    color: RAVEN,
    display: 'flex',
    alignItems: 'center',
    minHeight: 18,
  },
  scannedIdRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  redCross: {
    height: '100%',
    width: '18px',
    marginLeft: 5,
    '& > path': {
      stroke: ERROR,
    },
  },
  greenCheck: {
    height: '100%',
    width: '18px',
    marginLeft: 5,
    '& path': {
      stroke: SHAMROCK,
    },
  },
  circularProgress: {
    marginLeft: 5,
    color: SHAMROCK,
  },
  iconButtonSecond: {
    padding: 0,
  },
  simulateScanBtn: {
    marginTop: 5,
    width: '100%',
  },
  simulateScanBlock: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${AMBER}`,
    padding: '10px',
  },
}))

export default useStyles
