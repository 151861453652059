import { makeStyles } from '@mui/styles'
import { NEON_BLUE_LIGHT, SOLITUDE } from '@returnmates/client-core/src/constants/colors'

interface Props {
  fullWidth?: boolean
}

const useStyles = makeStyles(() => ({
  select: {
    border: `1px solid ${SOLITUDE}`,
    borderRadius: 5,
    width: ({ fullWidth }: Props) => (fullWidth ? '100%' : 256),
    height: 40,
    '& > div': {
      padding: '8px 14px',
    },
    '&:hover': {
      border: `1px solid ${NEON_BLUE_LIGHT}`,
    },
    '&.Mui-focused': {
      border: `1px solid ${NEON_BLUE_LIGHT}`,
    },
  },
}))

export default useStyles
