import FileIcon from '@returnmates/ui-core/src/components/images/file'
import { memo, useCallback } from 'react'

import useStyles from '../styles'

const ImageFormatter = ({ value }: { value?: { url: string; isImage: boolean } }) => {
  const classes = useStyles()

  const openFile = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      if (value?.url) {
        window.open(value.url, '_blank')?.focus()
      }
    },
    [value],
  )

  return value ? (
    <div className={classes.imageFormatterWrapper} onClick={openFile}>
      <div className={classes.imageFormatterImageHandler}>
        {value.isImage ? (
          <img src={value.url} className={classes.imageFormatterImage} />
        ) : (
          <FileIcon className={classes.imageFormatterImage} />
        )}
      </div>
    </div>
  ) : null
}

export default memo(ImageFormatter)
