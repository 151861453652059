interface Props {
  className?: string
}

const CalendarIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3333 2.00008H11.6667V0.666748H10.3333V2.00008H3.66666V0.666748H2.33333V2.00008H1.66666C0.933328 2.00008 0.333328 2.60008 0.333328 3.33341V14.0001C0.333328 14.7334 0.933328 15.3334 1.66666 15.3334H12.3333C13.0667 15.3334 13.6667 14.7334 13.6667 14.0001V3.33341C13.6667 2.60008 13.0667 2.00008 12.3333 2.00008ZM12.3333 14.0001H1.66666V5.33341H12.3333V14.0001Z"
      fill="#A7B4C8"
    />
  </svg>
)

export default CalendarIcon
