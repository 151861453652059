import { Package } from '@returnmates/client-core/src/graphql/generated/api'

export interface EditPackageFormAddress {
  instructions?: string | null
  name: string | null
  phone: string | null
  street: string | null
  unit: string | null
  state: string | null
  zipCode: string | null
  city: string | null
  addressId: string | null
}

export interface PackageInConsolidation extends Package, EditPackageFormAddress {
  consolidationId: string | null
  instructions?: string | null
}

export enum Modals {
  LABEL = 'LABEL',
  MULTI_PACKAGE = 'MULTI_PACKAGE',
}
