import { PACKAGE_STATUS_MAP } from '@returnmates/client-core/src/constants/trip'
import {
  Address,
  PackageStatus,
  PackageType,
  TripStatus,
} from '@returnmates/client-core/src/graphql/generated/api'

import { statusesToChangeToDelivered } from './constants'
import { EditPackageFormAddress } from './types'

export const getFilteredStatuses = (type: PackageType): Record<string, string> => {
  return Object.keys(PACKAGE_STATUS_MAP).reduce((acc, status: PackageStatus) => {
    if (type === PackageType.PICKUP) {
      return {
        ...acc,
        [status]: PACKAGE_STATUS_MAP[status],
      }
    }

    if (type === PackageType.DROPOFF) {
      if (status !== PackageStatus.PICKED_UP) {
        return {
          ...acc,
          [status]: PACKAGE_STATUS_MAP[status],
        }
      } else {
        return {
          ...acc,
        }
      }
    }

    return acc
  }, {})
}

export const getDisabledPackageStatuses = (
  tripStatus: TripStatus | null | undefined,
  statusInputValue: any,
  type: PackageType,
  carrierValue: any,
  trackingIdValue: any,
): Array<PackageStatus> => {
  let disabled: Array<PackageStatus> = []

  const pickupTripTypeCondition =
    type === PackageType.PICKUP &&
    statusInputValue === PackageStatus.DEPARTED &&
    carrierValue &&
    (trackingIdValue || carrierValue?.name?.toUpperCase() === 'AMAZON')

  const deliveryTripTypeCondition =
    type === PackageType.DROPOFF && statusesToChangeToDelivered.includes(statusInputValue)

  const statusesToDisable = {
    [PackageStatus.PENDING]: false,
    [PackageStatus.RECEIVED]:
      tripStatus !== TripStatus.COMPLETE &&
      (statusInputValue === PackageStatus.PICKED_UP || statusInputValue === PackageStatus.RECEIVED),
    [PackageStatus.DELIVERED]: !(pickupTripTypeCondition || deliveryTripTypeCondition),
    [PackageStatus.PICKED_UP]:
      tripStatus !== TripStatus.COMPLETE &&
      (statusInputValue === PackageStatus.PICKED_UP || statusInputValue === PackageStatus.RECEIVED),
    [PackageStatus.CANCELED]: false,
    [PackageStatus.FAILED]: false,
    [PackageStatus.DEPARTED]: statusInputValue !== PackageStatus.RECEIVED,
    [PackageStatus.CONSOLIDATED]: false,
    [PackageStatus.RTS]: type === PackageType.PICKUP,
  }

  Object.entries(statusesToDisable).forEach((val: [PackageStatus, boolean]) => {
    if (val[1]) {
      disabled = [...disabled, val[0]]
    }
  })

  return disabled
}

export const getFilteredAddress = (address: Address | null): EditPackageFormAddress => {
  return {
    instructions: address?.instructions || null,
    name: address?.name || null,
    phone: address?.phone || null,
    street: address?.street || null,
    unit: address?.unit || null,
    state: address?.state || null,
    city: address?.city || null,
    zipCode: address?.zipCode || null,
    addressId: address?.id || null,
  }
}
