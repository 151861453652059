interface Props {
  className?: string
}

const LogoutIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.10352e-05 4.55508C6.10352e-05 2.04451 2.07964 0 4.63456 0H9.71624C12.2659 0 14.3403 2.03837 14.3403 4.54484V15.931C14.3403 18.4426 12.2607 20.4861 9.70478 20.4861H4.62518C2.07443 20.4861 6.10352e-05 18.4477 6.10352e-05 15.9412V14.9784V4.55508Z"
      fill="#E8EAEE"
    />
    <path
      d="M20.2597 9.68458L17.3447 6.70488C17.0434 6.39759 16.5586 6.39759 16.2583 6.70693C15.9591 7.01627 15.9601 7.51511 16.2603 7.8224L17.8575 9.45412H16.3262H7.73195C7.30797 9.45412 6.96381 9.8075 6.96381 10.2428C6.96381 10.6792 7.30797 11.0315 7.73195 11.0315H17.8575L16.2603 12.6633C15.9601 12.9706 15.9591 13.4694 16.2583 13.7787C16.409 13.9334 16.6055 14.0112 16.803 14.0112C16.9986 14.0112 17.1951 13.9334 17.3447 13.7808L20.2597 10.8021C20.4043 10.6536 20.4861 10.4528 20.4861 10.2428C20.4861 10.0339 20.4043 9.83311 20.2597 9.68458Z"
      fill="#A7B4C8"
    />
  </svg>
)

export default LogoutIcon
