import { IconButton } from '@mui/material'
import { memo, useCallback } from 'react'

import Trash from '../../images/icons/trash'
import UnderscorePencil from '../../images/icons/underscorePencil'
import useStyles from '../styles'

interface Props {
  id: 'edit' | 'delete'
  onExecute: () => void
}

const commandComponents = {
  edit: UnderscorePencil,
  delete: Trash,
}

const CommandComponent = ({ id, onExecute }: Props) => {
  const classes = useStyles()
  const CommandButton = commandComponents[id]

  const onClick = useCallback(
    e => {
      e.stopPropagation()
      onExecute()
    },
    [onExecute],
  )

  return (
    <IconButton onClick={onClick} className={classes.commandButton}>
      <CommandButton className={classes.icon} />
    </IconButton>
  )
}

export default memo(CommandComponent)
