interface Props {
  className?: string
}

const Forum = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9125 8.05469C6.57019 8.05469 3.00004 11.1652 3.00004 14.9887C3.00004 16.517 3.56915 18.0703 4.61284 19.2742L3.13902 21.0286C2.994 21.2014 2.95883 21.4468 3.05059 21.6563C3.14179 21.8657 3.34175 22.0002 3.56255 22.0002H11.475C14.6812 22.0002 18.825 19.1243 18.825 14.9887C18.825 11.1652 15.2548 8.05469 10.9125 8.05469ZM7.50002 16.0627C6.87983 16.0627 6.37503 15.5368 6.37503 14.8908C6.37503 14.2448 6.87987 13.7189 7.50002 13.7189C8.12017 13.7189 8.62501 14.2448 8.62501 14.8908C8.62504 15.5368 8.12021 16.0627 7.50002 16.0627ZM10.9125 16.0627C10.2923 16.0627 9.78753 15.5368 9.78753 14.8908C9.78753 14.2448 10.2924 13.7189 10.9125 13.7189C11.5327 13.7189 12.0375 14.2448 12.0375 14.8908C12.0375 15.5368 11.5327 16.0627 10.9125 16.0627ZM14.325 16.0627C13.7048 16.0627 13.2 15.5368 13.2 14.8908C13.2 14.2448 13.7049 13.7189 14.325 13.7189C14.9452 13.7189 15.45 14.2448 15.45 14.8908C15.45 15.5368 14.9452 16.0627 14.325 16.0627Z"
      fill="#A7B4C8"
    />
    <path
      d="M22.0643 13.923L20.7399 12.3134C21.6853 11.2022 22.2 9.84716 22.2 8.44529C22.2 4.89135 18.9195 2 14.8875 2C11.2415 2 8.1439 4.36681 7.59695 7.44738C8.61427 7.08729 9.75516 6.8826 10.9125 6.8826C15.8384 6.8826 19.8854 10.4665 19.9445 14.8906H21.6375C22.118 14.8906 22.376 14.3012 22.0643 13.923Z"
      fill="#E8EAEE"
    />
  </svg>
)

export default Forum
