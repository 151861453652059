interface Props {
  className?: string
}

const ActiveOperation = ({ className }: Props): JSX.Element => (
  <svg
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2401_27)">
      <rect width="24" height="24" transform="matrix(1 0 0 -1 0 24)" fill="white" />
      <path
        d="M21.9205 9.30241L18.4167 4.24184C18.2561 4.00984 17.9047 3.93115 17.6298 4.06568L15.53 5.09328L13.6386 4.50358C13.569 4.48188 13.4985 4.46367 13.4274 4.44836C12.8949 4.33362 12.3304 4.39801 11.8487 4.63373L8.72217 6.1638C8.03158 6.50174 7.79498 7.25024 8.19368 7.8356C8.59237 8.42095 9.47544 8.6215 10.166 8.28356L13.5969 6.60454C13.9543 6.42965 14.3946 6.42965 14.752 6.60454C14.752 6.60454 21.6643 9.98838 21.6865 10L21.7895 9.92814C22.0087 9.77521 22.0642 9.50994 21.9205 9.30241Z"
        fill="#635DF6"
      />
      <path
        d="M21.3301 10.8251L14.2521 7.05598L10.7161 8.93892C10.3034 9.15871 9.84534 9.27096 9.38121 9.27096C9.14852 9.27096 8.91435 9.24274 8.6836 9.18576C7.99249 9.01494 7.41484 8.60615 7.05714 8.03464C6.69941 7.46319 6.60441 6.79739 6.7896 6.15996C6.94246 5.63372 7.27158 5.17918 7.73027 4.85084L6.27084 4.07366C5.97985 3.91869 5.60751 4.01649 5.44866 4.2896L2.07078 10.0982C1.91884 10.3595 2.01928 10.6849 2.29773 10.8331L2.60713 10.9978L3.15018 10.1303C3.6007 9.41052 4.44065 8.96341 5.34223 8.96341C5.78461 8.96341 6.22159 9.07156 6.60587 9.27623C6.82694 9.39394 7.02413 9.53902 7.19345 9.7064C7.46714 9.61424 7.75948 9.56472 8.05876 9.56472C8.50114 9.56472 8.93808 9.67287 9.32241 9.87754C9.90756 10.1891 10.3262 10.6923 10.501 11.2943C10.5203 11.3605 10.5357 11.4271 10.5486 11.4937C10.792 11.5456 11.0271 11.6306 11.2452 11.7468C11.8304 12.0583 12.2491 12.5615 12.4239 13.1635C12.4432 13.2297 12.4586 13.2963 12.4715 13.363C12.7149 13.4148 12.95 13.4998 13.1681 13.6159C14.3761 14.2593 14.7915 15.6891 14.0941 16.8033L13.3526 17.9879L14.9421 18.8343C15.5826 19.1754 16.4017 18.973 16.7715 18.3822C17.1414 17.7913 16.9219 17.0359 16.2813 16.6947L17.3123 17.2437C17.9529 17.5849 18.772 17.3824 19.1418 16.7916C19.5116 16.2008 19.2921 15.4453 18.6516 15.1042L19.3389 15.4702C19.9795 15.8113 20.7986 15.6089 21.1684 15.018C21.5382 14.4272 21.3188 13.6717 20.6782 13.3306L19.9909 12.9646C20.6314 13.3058 21.4505 13.1033 21.8203 12.5125C22.1902 11.9217 21.9707 11.1662 21.3301 10.8251Z"
        fill="#635DF6"
      />
      <path
        d="M13.31 15.3164C12.6503 14.9139 11.8066 15.1528 11.4257 15.8499L11.8344 15.1019C12.2153 14.4047 11.9893 13.5133 11.3295 13.1108C10.6698 12.7083 9.82611 12.9471 9.44518 13.6443L9.8539 12.8962C10.2348 12.1991 10.0088 11.3076 9.34901 10.9051C8.68925 10.5026 7.8456 10.7415 7.46467 11.4386L7.05594 12.1866C7.43683 11.4895 7.21081 10.598 6.55101 10.1955C5.89125 9.79302 5.0476 10.0319 4.66667 10.729L3.18504 13.4407C2.80411 14.1378 3.03018 15.0293 3.68994 15.4318C4.3497 15.8343 5.19339 15.5954 5.57428 14.8982C5.19335 15.5954 5.41941 16.4869 6.07918 16.8894C6.73894 17.2919 7.58263 17.053 7.96352 16.3558C7.58259 17.053 7.80865 17.9444 8.46845 18.3469C9.12822 18.7494 9.97186 18.5106 10.3528 17.8134C9.97186 18.5105 10.1979 19.402 10.8577 19.8045C11.5175 20.207 12.3611 19.9681 12.742 19.2709L13.8149 17.3073C14.1959 16.6104 13.9698 15.7189 13.31 15.3164Z"
        fill="#635DF6"
      />
      <circle cx="19" cy="15" r="6" fill="white" />
      <path
        d="M19.302 10.5C19.6204 10.5 19.9087 10.6785 20.0679 10.942C20.1454 11.0695 20.197 11.2267 20.1841 11.3925C20.1755 11.52 20.2142 11.6475 20.2831 11.7665C20.5025 12.1278 20.9888 12.2638 21.3674 12.0598C21.7934 11.8133 22.3313 11.962 22.5766 12.3828L22.8649 12.8843C23.1144 13.305 22.9767 13.8447 22.5464 14.087C22.1807 14.3038 22.0516 14.784 22.271 15.1495C22.3399 15.2642 22.4173 15.362 22.5378 15.4215C22.6884 15.5023 22.8046 15.6298 22.8864 15.7573C23.0456 16.0208 23.0327 16.3438 22.8778 16.6285L22.5766 17.1385C22.4173 17.4105 22.1204 17.5805 21.8149 17.5805C21.6643 17.5805 21.4965 17.538 21.3588 17.453C21.2469 17.3808 21.1178 17.3552 20.9802 17.3552C20.5542 17.3552 20.197 17.708 20.1841 18.1288C20.1841 18.6175 19.7882 19 19.2934 19H18.7082C18.209 19 17.8132 18.6175 17.8132 18.1288C17.8046 17.708 17.4474 17.3552 17.0214 17.3552C16.8794 17.3552 16.7503 17.3808 16.6427 17.453C16.5051 17.538 16.3329 17.5805 16.1866 17.5805C15.8768 17.5805 15.5799 17.4105 15.4207 17.1385L15.1238 16.6285C14.9646 16.3523 14.956 16.0208 15.1152 15.7573C15.184 15.6298 15.3131 15.5023 15.4594 15.4215C15.5799 15.362 15.6574 15.2642 15.7305 15.1495C15.9457 14.784 15.8166 14.3038 15.4508 14.087C15.0248 13.8447 14.8871 13.305 15.1324 12.8843L15.4207 12.3828C15.6703 11.962 16.2038 11.8133 16.6341 12.0598C17.0085 12.2638 17.4947 12.1278 17.7142 11.7665C17.783 11.6475 17.8218 11.52 17.8132 11.3925C17.8046 11.2267 17.8519 11.0695 17.9336 10.942C18.0929 10.6785 18.3812 10.5085 18.6953 10.5H19.302ZM19.0051 13.5515C18.3295 13.5515 17.783 14.087 17.783 14.7543C17.783 15.4215 18.3295 15.9528 19.0051 15.9528C19.6807 15.9528 20.2142 15.4215 20.2142 14.7543C20.2142 14.087 19.6807 13.5515 19.0051 13.5515Z"
        fill="#635DF6"
      />
    </g>
    <defs>
      <clipPath id="clip0_2401_27">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
    </defs>
  </svg>
)

export default ActiveOperation
