import { makeStyles } from '@mui/styles'
import {
  BACKGROUND_WHITE,
  ECHO_BLUE,
  ERROR,
  LINK_WATER,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  PERIWINKLE,
  SHAMROCK,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  image: {
    marginTop: 10,
    marginBottom: 10,
    width: 387,
    height: 218,
    display: 'block',
  },
  notVisible: {
    display: 'none',
  },
  error: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    textAlign: 'start',
  },
  mediaButton: {
    width: 130,
    padding: '10px 0',
    lineHeight: '130%',
    borderRadius: 4,
    marginRight: 10,
    fontWeight: 500,
  },
  mediaButtonCancel: {
    backgroundColor: BACKGROUND_WHITE,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: LINK_WATER,
    },
  },
  trashIcon: {
    position: 'absolute',
    top: -5,
    right: 2,
    zIndex: 2,
    padding: 0,
    '& circle': {
      transition: 'all ease-in-out .2s',
    },
    '&:hover circle': {
      fill: SHAMROCK,
    },
  },
  fileWrapper: {
    position: 'relative',
    display: 'inline-block',
    width: 40,
    height: 40,
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: 10,
  },
  fileWithIcon: {
    position: 'relative',
    display: 'inline-block',
  },
  fileIcon: {},
  fileImage: {
    position: 'relative',
    cursor: 'pointer',
    alignItems: 'center',
    maxWidth: 40,
    maxHeight: 40,
  },
  uploadInput: {
    display: 'none',
  },
  cameraIcon: {
    cursor: 'pointer',
  },
  cameraWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  dnd: {
    marginTop: 13,
    padding: 13,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    color: ECHO_BLUE,
    '& > span': {
      color: NEON_BLUE_LIGHT,
      paddingLeft: 4,
    },
    background: BACKGROUND_WHITE,
    border: `1px dashed ${PERIWINKLE}`,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  videoHandler: {
    padding: '11px 11px 20px',
    border: `1px solid ${PERIWINKLE}`,
    marginTop: 14,
  },
  mediaButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useStyles
