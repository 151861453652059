import Button from '@returnmates/ui-core/src/components/Button'
import Modal from '@returnmates/ui-core/src/components/Modal'
import { memo } from 'react'

import useStyles from '../styles'

interface Props {
  onClose: () => void
  handleSubmit: () => void
  isOpen: boolean
}

const MissingLabelModal = ({ isOpen, onClose, handleSubmit }: Props) => {
  const classes = useStyles()

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={448}>
      <div className={classes.modalWrapper}>
        <p className={classes.missingLabelTitile}>Missing Label</p>
        <p className={classes.missingLabelText}>
          You are attempting to save a package with a digital/amazon label but have not uploaded a
          file. The package will not be able to be delivered to the carrier without this label.
          Proceed anyway?
        </p>
        <div className={classes.missingLabelModalButtons}>
          <Button
            className={classes.missingLabelButton}
            onClick={onClose}
            label="Cancel"
            cancelButton
          />
          <Button className={classes.missingLabelButton} onClick={handleSubmit} label="Confirm" />
        </div>
      </div>
    </Modal>
  )
}

export default memo(MissingLabelModal)
