import FileCopyIcon from '@mui/icons-material/FileCopy'
import IconButton from '@mui/material/IconButton'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import {
  AdminPackage,
  Package,
  PackageCondition,
  PackageStatus,
  PackageType,
  Scan,
  ScanType,
  TripStatus,
} from '@returnmates/client-core/src/graphql/generated/api'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import Button from '@returnmates/ui-core/src/components/Button'
import clsx from 'clsx'
import { memo, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useField, useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'

import { TRIPS_LOG } from '../../../constants/paths'
import CloseIcon from '../../images/icons/close'
import useStyles from '../styles'

interface Props {
  onClose: () => void
  tripSection: string
  children: ReactNode
  handleSubmit: () => void
  handleReceiveClick: (val: Package) => void
  idLabel?: string
  error?: string
  isLoading?: boolean
  currentPackage: AdminPackage | Package | null
}

function PackageForm({
  onClose,
  children,
  handleSubmit,
  isLoading,
  handleReceiveClick,
  currentPackage,
}: Props) {
  const { input: statusInput } = useField('status')
  const { input: trackingId } = useField('trackingId')
  const { input: carrier } = useField('carrier')
  const { input: condition } = useField('condition')
  const { input: scans } = useField('scans')
  const { input: partner } = useField('partner')
  const form = useForm()
  const dispatch = useDispatch()

  const {
    id,
    type: tripType,
    tripStatus,
    tripId,
    locationId,
    polybagId,
  } = currentPackage as AdminPackage

  const classes = useStyles()

  const canSaveAndReceive = useMemo(
    () =>
      Boolean(
        statusInput.value === PackageStatus.PICKED_UP &&
          partner.value &&
          (!partner.value.requiresReceiptTracking || (trackingId.value && carrier.value)) &&
          condition.value !== PackageCondition.PENDING &&
          !scans.value.some((scan: Scan) => scan.type === ScanType.INGEST) &&
          tripType === PackageType.PICKUP &&
          tripStatus === TripStatus.COMPLETE,
      ),
    [
      partner.value,
      statusInput,
      scans,
      condition.value,
      tripType,
      carrier.value,
      trackingId.value,
      tripStatus,
    ],
  )

  const onReceiveClick = useCallback(async () => {
    const { values: currentFormState } = form.getState()
    handleReceiveClick(currentFormState as Package)
  }, [form, handleReceiveClick])

  const copyToClipboard = useCallback(
    type => {
      let typeText = ''
      switch (type) {
        case 'Package':
          typeText = id
          break
        case 'Trip':
          typeText = tripId || ''
          break
        case 'Location':
          typeText = locationId || ''
          break
        case 'Polybag':
          typeText = polybagId || ''
          break
      }

      navigator.clipboard.writeText(typeText)

      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.SUCCESS,
          message: `${type} Id was successfully copied to the clipboard`,
        }),
      )
    },
    [dispatch, id, locationId, polybagId, tripId],
  )

  const goToTrip = useCallback(() => {
    if (tripId && tripType) {
      const searchParams = new URLSearchParams({
        tripId,
        tripType,
      })
      const url = `${TRIPS_LOG}?${searchParams.toString()}`
      window.open(`${window.location.origin}${url}`, '_blank')
    }
  }, [tripId, tripType])

  useEffect(() => {
    carrier.onChange(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          <div>
            <div className={classes.idGroup} onClick={() => copyToClipboard('Package')}>
              <div className={classes.id}>
                <span className={classes.boldTitle}>Package Id:</span> {id}
              </div>
              <IconButton className={classes.iconWrap}>
                <FileCopyIcon className={classes.copyIcon} />
              </IconButton>
            </div>
            {tripId ? (
              <div className={classes.idGroup}>
                <div className={classes.id} onClick={goToTrip}>
                  <span className={classes.boldTitle}>Trip Id:</span> {tripId}
                </div>
                <IconButton className={classes.iconWrap} onClick={() => copyToClipboard('Trip')}>
                  <FileCopyIcon className={classes.copyIcon} />
                </IconButton>
              </div>
            ) : null}
            {locationId ? (
              <div className={classes.idGroup} onClick={() => copyToClipboard('Location')}>
                <div className={classes.id}>
                  <span className={classes.boldTitle}>Location Id:</span> {locationId}
                </div>
                <IconButton className={classes.iconWrap}>
                  <FileCopyIcon className={classes.copyIcon} />
                </IconButton>
              </div>
            ) : null}
            {polybagId ? (
              <div className={classes.idGroup} onClick={() => copyToClipboard('Polybag')}>
                <div className={classes.id}>
                  <span className={classes.boldTitle}>Polybag Id:</span> {polybagId}
                </div>
                <IconButton className={classes.iconWrap}>
                  <FileCopyIcon className={classes.copyIcon} />
                </IconButton>
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className={classes.detailsBlockWrap}>{children}</div>
      <div className={classes.actionButtons}>
        <Button
          className={clsx(classes.button, classes.cancelButton)}
          onClick={onClose}
          label="Cancel"
          cancelButton
        />
        <Button
          className={clsx(classes.button, classes.saveButton)}
          onClick={handleSubmit}
          label="Save"
          isLoading={isLoading}
        />
        {canSaveAndReceive ? (
          <Button
            className={clsx(classes.button, classes.saveButton, classes.receiveButton)}
            onClick={onReceiveClick}
            label="Save &amp; Receive"
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  )
}

export default memo(PackageForm)
