import { makeStyles } from '@mui/styles'
import {
  BACKGROUND_WHITE,
  ECHO_BLUE,
  ERROR,
  NEON_BLUE_LIGHT,
  PERIWINKLE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  fileWrapper: {
    paddingTop: 20,
  },
  uploadLabel: {
    fontFamily: POPPINS,
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    paddingTop: 20,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  uploadInput: {
    display: 'none',
  },
  spinner: {
    marginLeft: 20,
  },
  dnd: {
    cursor: 'pointer',
    marginTop: 13,
    padding: 13,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    color: ECHO_BLUE,
    '& > span': {
      color: NEON_BLUE_LIGHT,
      paddingLeft: 4,
    },
    background: BACKGROUND_WHITE,
    border: `1px dashed ${PERIWINKLE}`,
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  error: {
    paddingTop: 10,
    fontFamily: POPPINS,
    fontSize: 14,
    color: ERROR,
    textAlign: 'start',
  },
}))

export default useStyles
