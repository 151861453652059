import { makeStyles } from '@mui/styles'
import { MIDNIGHT_EXPRESS } from '@returnmates/client-core/src/constants/colors'

interface Props {
  statusBgColor?: string
  statusColor?: string
}

const useStyles = makeStyles(() => ({
  statusSelect: {
    '& > div > span': {
      fontSize: 14,
    },
    backgroundColor: ({ statusBgColor }: Props) => (statusBgColor ? statusBgColor : '#FFFFFF'),
    color: ({ statusColor }: Props) => (statusColor ? statusColor : MIDNIGHT_EXPRESS),
    borderRadius: '19px',
    padding: '5px 12px',
    '& > svg > path': {
      stroke: ({ statusColor }: Props) => (statusColor ? statusColor : MIDNIGHT_EXPRESS),
    },
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
}))

export default useStyles
