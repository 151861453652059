import { Auth, CognitoHostedUIIdentityProvider } from '@returnmates/client-core/src/amplify'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import cognitoErrorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import validate from '@returnmates/client-core/src/utils/validation/login'
import Button from '@returnmates/ui-core/src/components/Button'
import GoogleButton from '@returnmates/ui-core/src/components/GoogleButton'
import Eye from '@returnmates/ui-core/src/components/images/icons/eye'
import EyeClose from '@returnmates/ui-core/src/components/images/icons/eyeClose'
import Logo from '@returnmates/ui-core/src/components/images/logo'
import Input from '@returnmates/ui-core/src/components/Input'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import clsx from 'clsx'
import { FORM_ERROR } from 'final-form'
import { memo, useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'

import useStyles from './styles'

function Login() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const onSubmit = useCallback(
    async ({ password, email }: { password: string; email: string }) => {
      try {
        setIsLoading(true)
        await createAsyncAction(
          dispatch,
          actions.login.request({ username: email, password, metadata: { isAdmin: 'true' } }),
        )
        setIsLoading(false)
      } catch (err) {
        const { message } = err as Error

        setIsLoading(false)
        return { [FORM_ERROR]: cognitoErrorMapper(message || (err as string)) }
      }
    },
    [dispatch],
  )

  const onGoogleButtonClick = useCallback(() => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
  }, [])

  const switchPasswordVisibility = useCallback(() => {
    setIsPasswordVisible(val => !val)
  }, [])

  return (
    <>
      {isLoading ? <Spinner /> : null}
      <div className={classes.main}>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, submitError }) => (
            <>
              <div className={classes.formWrapper}>
                <Logo className={classes.logo} />
                <p className={classes.title}>Sign in</p>
                <p className={classes.subtitle}>Continue to Returnmates Wilson.</p>
                <GoogleButton
                  label="Sign in with Google"
                  onClick={onGoogleButtonClick}
                  className={classes.googleButton}
                />
                <div className={classes.orSeparator}>
                  <span>or</span>
                </div>
                <Field name="email">
                  {({ input, meta }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <Input
                        onChange={input.onChange}
                        value={input.value}
                        error={meta.touched && meta.error}
                        helperText={meta.error}
                        placeholder="Email"
                        className={clsx(classes.emailInput, classes.input)}
                        autoFocus
                      />
                    </form>
                  )}
                </Field>
                <div className={classes.passwordInputWrapper}>
                  <Field name="password">
                    {({ input, meta }) => (
                      <form onSubmit={handleSubmit}>
                        <Input
                          onChange={input.onChange}
                          value={input.value}
                          error={meta.touched && meta.error}
                          helperText={meta.error}
                          placeholder="Password"
                          className={clsx(classes.passwordInput, classes.input)}
                          type={isPasswordVisible ? 'input' : 'password'}
                        />
                      </form>
                    )}
                  </Field>
                  {isPasswordVisible ? (
                    <Eye className={classes.eyeIcon} onClick={switchPasswordVisibility} />
                  ) : (
                    <EyeClose className={classes.eyeIcon} onClick={switchPasswordVisibility} />
                  )}
                </div>
                <Button
                  fullWidth
                  onClick={handleSubmit}
                  label="Sign in"
                  className={classes.button}
                  isLoading={isLoading}
                />
                {submitError ? <p className={classes.error}>{submitError}</p> : null}
              </div>
            </>
          )}
        />
      </div>
    </>
  )
}

export default memo(Login)
