import { Sorting } from '@devexpress/dx-react-grid'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import {
  consolidationPackageError,
  packageIsTiedToConsolidation,
  returnDestinationMismatchError,
} from '@returnmates/client-core/src/constants/errors'
import {
  Address,
  Consolidation,
  Package,
  Partner,
  PartnerDestination,
} from '@returnmates/client-core/src/graphql/generated/api'
import {
  getConsolidationTableColumnWidth,
  getPartners,
} from '@returnmates/client-core/src/selectors/admin'
import { getCurrentPackage } from '@returnmates/client-core/src/selectors/packages'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import { PickupDetails as PickupDetailsType } from '@returnmates/client-core/src/type/pickups'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import EditPackageReturnDestinationModal from '@returnmates/ui-core/src/components/CancellationModal/index'
import { FORM_ERROR, setIn } from 'final-form'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import EditAddressForm from '../EditAddressForm'
import EditPackageForm from '../EditPackageForm'
import GridTable from '../GridTable'
import { TableColumn } from '../GridTable/types'
import RemovePackageModal from '../RemovePackageModal'
import AddNewPackageModal from './components/AddNewPackageModal'
import EditDetailsForm from './components/EditDetailsForm'
import PickupCellFormatter from './components/PickupCellFormatter'
import PickupDetails from './components/PickupDetails'
import Toolbar from './components/Toolbar'
import {
  columnsGrid,
  ConsolidationFilter,
  ConsolidationPackageInput,
  defaultColumnWidths,
  formattedColumnNames,
  formattedColumns,
} from './constants'

interface Props {
  data: {
    [key: string]: string
  }[]
  columns?: TableColumn[]
  searchPlaceholder?: string
  showDetails?: boolean
  totalCount?: number
  onSearchChange?: (search: string) => void
  onSortingChange?: (sort: Sorting) => void
  onCurrentPageChange?: (page: number) => void
  currentPageNumber?: number
  onPageSizeChange?: (size: number) => void
  onFilterUpdate?: (val: ConsolidationFilter) => void
  isLoading: boolean
  setSelectedRows: (values: string[]) => void
  selectedRows: string[]
  isFilterActive?: boolean
  setIsFilterActive?: (val: boolean) => void
  updateOpenConsolidationDataRef?: (val: Consolidation) => void
}

function ConsolidationsTable({
  data,
  searchPlaceholder,
  showDetails = true,
  columns = columnsGrid,
  totalCount,
  onSortingChange,
  onPageSizeChange,
  onCurrentPageChange,
  currentPageNumber,
  isLoading,
  // setSelectedRows,
  // selectedRows,
  onFilterUpdate,
  isFilterActive,
  setIsFilterActive,
  updateOpenConsolidationDataRef,
}: Props) {
  const partners = useSelector(getPartners)
  const [isInnerLoading, setIsInnerLoading] = useState(false)
  const [isOuterLoading, setIsOuterLoading] = useState(false)
  const [isReturnDestinationUpdatingLoading, setIsReturnDestinationUpdatingLoading] = useState(
    false,
  )
  const [isEditPickup, setIsEditPickup] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [isEditPackage, setIsEditPackage] = useState(false)
  const [currentRowData, setCurrentRowData] = useState<Consolidation | null>(null)
  const [packageConsolidationId, setPackageConsolidationId] = useState<string | null>(null)

  const currentPackageData = useSelector(getCurrentPackage)
  const tableColumnWidth = useSelector(getConsolidationTableColumnWidth)
  const [isRemovePackageModalOpen, setIsRemovePackageModalOpen] = useState(false)
  const [packageToRemove, setPackageToRemove] = useState<{
    consolidationId: string
    consolidationPackageId: string
  } | null>(null)

  const [packageSubmitError, setPackageSubmitError] = useState<{ [key: string]: string } | null>(
    null,
  )
  const [isNewPackageModalOpen, setIsNewPackageModalOpen] = useState(false)
  const [isReturnDestinationUpdateModalOpen, setIsReturnDestinationUpdateModalOpen] = useState(
    false,
  )
  const [packageIdToUpdateReturnDestination, setPackageIdToUpdateReturnDestination] = useState<
    string | null
  >(null)
  const [consolidationDestinations, setConsolidationDestinations] = useState<Address[]>([])
  const [partner, setPartner] = useState<Partner | null>(null)
  const [failedPackageIds, setFailedPackageIds] = useState<
    | {
        [key: string]: string
      }
    | {}
  >({})
  const [succedPackageIds, setSuccedPackageIds] = useState<string[]>([])
  const failedPackageIdsRef = useRef<string[]>([])
  const dispatch = useDispatch()

  const onEditPackageClick = useCallback(() => {
    setIsEditAddress(false)
    setIsEditPickup(false)
    setIsEditPackage(true)
  }, [])

  const onEditDetailsClick = useCallback(() => {
    setIsEditAddress(false)
    setIsEditPackage(false)
    setIsEditPickup(true)
  }, [])

  const resetScannedIdsState = useCallback(() => {
    setFailedPackageIds({})
    setSuccedPackageIds([])
    failedPackageIdsRef.current = []
  }, [])

  const closeNewPackageModal = useCallback(() => {
    setIsNewPackageModalOpen(false)
    setPackageSubmitError?.(null)
    resetScannedIdsState()
  }, [resetScannedIdsState])

  const closeReturnDestinationChangeModal = useCallback(() => {
    setIsReturnDestinationUpdateModalOpen(false)
  }, [])

  const handleCloseRemovePackageModal = useCallback(() => {
    setIsRemovePackageModalOpen(false)
  }, [])

  const fetchConsolidationPackages = useCallback(async () => {
    setIsOuterLoading(true)
    try {
      if (currentRowData?.id) {
        await createAsyncAction(
          dispatch,
          actions.getConsolidationPackages.request({
            where: {
              consolidationId: currentRowData?.id,
            },
            pagination: {
              size: 10000,
            },
          }),
        )
      }
    } catch (err) {
      const { message } = err as Error
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    } finally {
      setIsOuterLoading(false)
    }
  }, [dispatch, currentRowData?.id])

  const getPartner = useCallback(() => {
    if (currentRowData?.partner.code) {
      const partner = partners.find(p => p.code === currentRowData?.partner.code)

      if (partner) {
        setPartner(partner)
      }
    }
  }, [currentRowData?.partner.code, partners])

  const updateConsolidationAddress = useCallback(
    async (_, addressId: string) => {
      const partnderDestination = partner?.destinations?.filter(
        item => item?.address.id === addressId,
      )

      if (partnderDestination?.length) {
        try {
          await createAsyncAction(
            dispatch,
            actions.updateConsolidation.request({
              id: currentRowData?.id,
              input: {
                partnerDestinationId: partnderDestination[0]?.id,
              },
            }),
          )
        } catch (err) {
          const { message } = err as Error
          dispatch(
            actions.addSnackBar.request({
              type: SnackBarStatuses.ERROR,
              message: errorMapper(message || (err as string)),
            }),
          )
        }
      }
    },
    [dispatch, currentRowData?.id, partner?.destinations],
  )

  const createConsolidationAddress = useCallback(
    async (val: PickupDetailsType): Promise<Address> => {
      const res: PartnerDestination = await createAsyncAction(
        dispatch,
        actions.createConsolidationAddress.request({
          ...val,
          partnerCode: partner?.code,
          skipServicedArea: true,
        }),
      )

      return res.address
    },
    [dispatch, partner?.code],
  )

  const addNewDestination = useCallback(
    (address: Address) => {
      setConsolidationDestinations([...(consolidationDestinations || []), address])
    },
    [consolidationDestinations],
  )

  const handlePackageDelete = useCallback(
    async ({ consolidationId, consolidationPackageId }: ConsolidationPackageInput) => {
      setIsInnerLoading(true)
      try {
        return await createAsyncAction(
          dispatch,
          actions.deleteConsolidationPackage.request({
            consolidationId,
            packageId: consolidationPackageId,
          }),
        )
      } catch (err) {
        const { message } = err as Error

        dispatch(
          actions.addSnackBar.request({
            type: SnackBarStatuses.ERROR,
            message: errorMapper(message || (err as string)),
          }),
        )
      } finally {
        closeNewPackageModal()
        handleCloseRemovePackageModal()
        setIsInnerLoading(false)
      }
    },
    [closeNewPackageModal, dispatch, handleCloseRemovePackageModal],
  )

  const handleRemovePackageConfirm = useCallback(async () => {
    if (packageToRemove) {
      await handlePackageDelete({
        consolidationId: packageToRemove?.consolidationId,
        consolidationPackageId: packageToRemove.consolidationPackageId,
      })

      await fetchConsolidationPackages()
    }
  }, [fetchConsolidationPackages, handlePackageDelete, packageToRemove])

  const updatePackageReturnDestination = useCallback(
    async (id?: string) => {
      const packageId = id || packageIdToUpdateReturnDestination

      const pack: Package = await createAsyncAction(
        dispatch,
        actions.getPackage.request({ id: packageId }),
      )
      if (packageId) {
        await createAsyncAction(dispatch, actions.packageTrackingList.request({ packageId }))
        await createAsyncAction(
          dispatch,
          actions.updatePackage.request({
            id: packageId,
            input: {
              tripId: pack?.tripId,
              returnDestinationId: currentRowData?.address.id,
            },
          }),
        )
      }
    },
    [currentRowData?.address.id, dispatch, packageIdToUpdateReturnDestination],
  )

  const onPackageCreate = useCallback(
    async ({
      consolidationId,
      consolidationPackageId,
      isMultiPackageLabel,
    }: ConsolidationPackageInput) => {
      try {
        const res = await createAsyncAction(
          dispatch,
          actions.createConsolidationPackage.request({
            consolidationId,
            packageId: consolidationPackageId,
          }),
        )

        if (res) {
          setSuccedPackageIds(prev => [...prev, consolidationPackageId as string])
          fetchConsolidationPackages()

          if (!isMultiPackageLabel) {
            closeNewPackageModal()
          }
        }
      } catch (err) {
        const { message } = err as Error

        setFailedPackageIds(prev => ({
          ...prev,
          [consolidationPackageId as string]: err || message,
        }))

        failedPackageIdsRef.current = [...failedPackageIdsRef.current, (err || message) as string]

        let errors = {}
        const setError = (key: string, value: string) => {
          errors = setIn(errors, key, value)
        }

        if (
          (message === packageIsTiedToConsolidation || err === packageIsTiedToConsolidation) &&
          !isMultiPackageLabel
        ) {
          const submitError = { [FORM_ERROR]: consolidationPackageError }

          if (submitError[FORM_ERROR] === consolidationPackageError) {
            setError('consolidationPackageId', consolidationPackageError)
          }

          setPackageSubmitError(errors)
        } else if (
          message?.includes(returnDestinationMismatchError(consolidationPackageId as string)) ||
          (typeof err === 'string' &&
            err?.includes(returnDestinationMismatchError(consolidationPackageId as string)))
        ) {
          setPackageIdToUpdateReturnDestination(consolidationPackageId as string)

          setIsReturnDestinationUpdateModalOpen(true)
        } else {
          dispatch(
            actions.addSnackBar.request({
              type: SnackBarStatuses.ERROR,
              message: errorMapper(message || (err as string)),
            }),
          )
        }
      }
    },
    [closeNewPackageModal, dispatch, fetchConsolidationPackages],
  )

  const editPackageReturnDestination = useCallback(async () => {
    try {
      setIsReturnDestinationUpdatingLoading(true)

      await updatePackageReturnDestination()

      closeReturnDestinationChangeModal()

      await onPackageCreate({
        consolidationId: currentRowData?.id as string,
        consolidationPackageId: packageIdToUpdateReturnDestination as string,
      })
    } catch (err) {
      const { message } = err as Error
      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
    } finally {
      setIsReturnDestinationUpdatingLoading(false)
    }
  }, [
    closeReturnDestinationChangeModal,
    currentRowData?.id,
    dispatch,
    onPackageCreate,
    packageIdToUpdateReturnDestination,
    updatePackageReturnDestination,
  ])

  const handleAddPackage = useCallback(
    async (values: ConsolidationPackageInput, withReplacing: boolean) => {
      if (withReplacing) {
        setIsOuterLoading(true)
        const packageConsolidationForDelete: { consolidationId: string } = await createAsyncAction(
          dispatch,
          actions.getPackageConsolidation.request({
            packageId: values.consolidationPackageId,
          }),
        )

        if (packageConsolidationForDelete) {
          const deleteRes = await handlePackageDelete({
            consolidationId: packageConsolidationForDelete.consolidationId,
            consolidationPackageId: values.consolidationPackageId,
          })

          if (deleteRes && values.consolidationPackageId) {
            await onPackageCreate(values)
            setIsOuterLoading(false)
          }
        }
      } else {
        if (Array.isArray(values.consolidationPackageId)) {
          values.consolidationPackageId.forEach(async (packageId, index, arr) => {
            await onPackageCreate({
              ...values,
              consolidationPackageId: packageId,
              isMultiPackageLabel: true,
            })

            if (!failedPackageIdsRef.current.length && index === arr.length - 1) {
              setTimeout(() => closeNewPackageModal(), 500)
            }
          })
        } else {
          onPackageCreate(values)
        }
      }
    },
    [closeNewPackageModal, dispatch, handlePackageDelete, onPackageCreate],
  )

  const onEditAddressClick = useCallback(() => {
    setIsEditPackage(false)
    setIsEditPickup(false)
    setIsEditAddress(true)
  }, [])

  const onCloseSidebar = useCallback(() => {
    setIsEditAddress(false)
    setIsEditPickup(false)
    setIsEditPackage(false)
  }, [])

  const onColumnWidthsChange = useCallback(
    newTableSize => dispatch(actions.setConsolidationTableColumnWidth.request(newTableSize)),
    [dispatch],
  )

  const columnSize = useMemo(() => (tableColumnWidth ? tableColumnWidth : defaultColumnWidths), [
    tableColumnWidth,
  ])

  const setRowData = useCallback(
    row => {
      setCurrentRowData(row)
      updateOpenConsolidationDataRef?.(row)
    },
    [updateOpenConsolidationDataRef],
  )

  useEffect(() => {
    fetchConsolidationPackages()
  }, [fetchConsolidationPackages])

  useEffect(() => {
    if (partner?.destinations) {
      setConsolidationDestinations(partner.destinations.map(item => item?.address as Address))
    }
  }, [partner?.destinations])

  useEffect(() => {
    if (partners.length) {
      getPartner()
    }
  }, [getPartner, partners.length])

  return (
    <>
      <Toolbar
        searchPlaceholder={searchPlaceholder}
        isLoading={isLoading}
        isFilterActive={isFilterActive}
        setIsFilterActive={setIsFilterActive}
        onFilterUpdate={onFilterUpdate}
      />
      <GridTable
        columns={columns || columnsGrid}
        data={data}
        isLoading={isLoading}
        formattedColumnNames={formattedColumnNames}
        formatterComponent={PickupCellFormatter}
        setEditableRow={onEditPackageClick}
        detailsComponent={PickupDetails}
        detailsProps={{
          onEditDetailsClick,
          onEditAddressClick,
          setPackageToRemove,
          isNewPackageModalOpen,
          setIsNewPackageModalOpen,
          setIsRemovePackageModalOpen,
          currentRowData,
          setRowData,
          onEditPackageClick,
          isOuterLoading,
          setPackageConsolidationId,
          fetchConsolidationPackages,
        }}
        showDetails={showDetails}
        isNetworkFiltering
        showPaging
        showSorting
        formattedColumns={formattedColumns}
        totalCount={totalCount}
        onPageSizeChange={onPageSizeChange}
        onCurrentPageChange={onCurrentPageChange}
        currentPageNumber={currentPageNumber}
        onSortingChange={onSortingChange}
        // canSelectRow
        // setSelectedRows={setSelectedRows}
        // selectedRows={selectedRows}
        defaultColumnWidths={columnSize}
        onColumnWidthsChange={onColumnWidthsChange}
      />
      <EditDetailsForm
        isOpen={isEditPickup}
        currentData={currentRowData}
        onClose={onCloseSidebar}
        setIsOpen={setIsEditPickup}
        fetchConsolidationPackages={fetchConsolidationPackages}
      />
      <EditAddressForm
        isOpen={isEditAddress}
        label="Consolidation"
        id={currentRowData?.id}
        addressData={currentRowData?.address}
        onClose={onCloseSidebar}
        setIsOpen={setIsEditAddress}
        updateAddress={updateConsolidationAddress}
        createAddress={createConsolidationAddress}
        addNewAddress={addNewDestination}
        addresses={(consolidationDestinations as Address[]) || []}
        isZipCodeWithoutValidation
      />
      <AddNewPackageModal
        isOpen={isNewPackageModalOpen}
        onClose={closeNewPackageModal}
        consolidationId={currentRowData?.id}
        onPackageCreate={handleAddPackage}
        validation={packageSubmitError}
        setPackageSubmitError={setPackageSubmitError}
        isLoading={isOuterLoading}
        failedPackageIds={failedPackageIds}
        succedPackageIds={succedPackageIds}
        resetScannedIdsState={resetScannedIdsState}
      />
      <EditPackageReturnDestinationModal
        width={488}
        isOpen={isReturnDestinationUpdateModalOpen}
        onClose={closeReturnDestinationChangeModal}
        onConfirm={editPackageReturnDestination}
        buttonLabelConfirm={'Update Return Destination'}
        buttonLabelCancel={'Cancel'}
        header={returnDestinationMismatchError(packageIdToUpdateReturnDestination)}
        isLoading={isReturnDestinationUpdatingLoading}
      />

      <EditPackageForm
        isOpen={isEditPackage}
        currentPackageData={currentPackageData}
        onClose={onCloseSidebar}
        packageConsolidationId={packageConsolidationId}
        setPackageConsolidationId={setPackageConsolidationId}
      />
      <RemovePackageModal
        isOpen={isRemovePackageModalOpen}
        onClose={handleCloseRemovePackageModal}
        onConfirm={handleRemovePackageConfirm}
        isLoading={isInnerLoading}
      />
    </>
  )
}

export default memo(ConsolidationsTable)
