import { Table, TableProps } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'

import useStyles from '../styles'

const TableComponent = (props: TableProps) => {
  const classes = useStyles()

  return <Table.Table className={classes.tableRoot} {...props} />
}

export default memo(TableComponent)
