import { makeStyles } from '@mui/styles'
import {
  GRAYISH_BLUE,
  NEON_BLUE_LIGHT,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: 80,
    height: '100%',
    borderRight: `1px solid ${SOLITUDE}`,
    zIndex: 11,
  },
  main: {
    height: '100%',
  },
  logoWrapper: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linksList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    paddingTop: '120px',
  },
  link: {
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginBottom: 40,
    },
  },
  active: {
    borderRight: `2px solid ${NEON_BLUE_LIGHT}`,
    '& > svg': {
      marginRight: -2,
    },
  },
  linkIcon: {
    color: GRAYISH_BLUE,
  },
  activeIcon: {
    color: NEON_BLUE_LIGHT,
  },
  logoutLink: {
    marginTop: 'auto',
    marginBottom: 30,
  },
  '@media (max-height: 900px)': {
    link: {
      '&:not(:last-child)': {
        marginBottom: 35,
      },
    },
  },
}))

export default useStyles
