interface Props {
  className?: string
}

const Qr = ({ className }: Props): JSX.Element => (
  <svg
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7625 17.2573C15.3675 17.2573 15.9485 17.0191 16.3715 16.5945C16.7975 16.1659 17.0335 15.5903 17.0325 14.9912V13.7458C17.0275 13.5997 16.9085 13.481 16.7595 13.4771H7.23953C7.09153 13.482 6.97253 13.6007 6.96944 13.7458V14.9912C6.95853 16.2316 7.97453 17.2446 9.23853 17.2573H14.7625ZM17.1936 20.9997C19.3906 20.9997 21.1766 19.2484 21.1766 17.094V15.2377C21.1766 14.8258 20.8366 14.4905 20.4156 14.4905C19.9956 14.4905 19.6546 14.8258 19.6546 15.2377V17.094C19.6546 18.4246 18.5506 19.5072 17.1936 19.5072H15.6006C15.1806 19.5072 14.8396 19.8406 14.8396 20.2525C14.8396 20.6653 15.1806 20.9997 15.6006 20.9997H17.1936ZM8.42773 21C8.84773 21 9.18873 20.6656 9.18873 20.2528C9.18873 19.8409 8.84873 19.5065 8.42773 19.5065L6.79873 19.5056C5.44673 19.5036 4.34673 18.424 4.34673 17.0972V15.238C4.34673 14.8261 4.00473 14.4908 3.58473 14.4908C3.16473 14.4908 2.82373 14.8261 2.82373 15.238V17.0972C2.82373 19.2467 4.60673 20.9971 6.79773 20.999L8.42773 21Z"
      fill="#E8EAEE"
    />
    <path
      d="M22.239 11.9587H1.762C1.342 11.9587 1 11.6244 1 11.2115C1 10.7997 1.342 10.4663 1.762 10.4663H2.823V6.90276C2.823 4.75329 4.607 3.00292 6.798 3.00096L8.427 2.99998C8.848 2.99998 9.188 3.33436 9.189 3.74719C9.189 4.15904 8.848 4.49343 8.428 4.49343L6.8 4.49441C5.447 4.49637 4.346 5.57601 4.346 6.90276V10.4663H6.969V9.47488C6.959 8.23442 7.974 7.22048 9.238 7.20871H14.762C16.027 7.22048 17.042 8.23442 17.032 9.47488V10.4663H19.655V6.90669C19.655 5.57503 18.551 4.49343 17.194 4.49343H15.601C15.18 4.49343 14.839 4.15904 14.839 3.74719C14.839 3.33436 15.18 2.99998 15.601 2.99998H17.194C19.39 2.99998 21.177 4.75133 21.177 6.90669V10.4663H22.239C22.659 10.4663 23 10.7997 23 11.2115C23 11.6244 22.659 11.9587 22.239 11.9587Z"
      fill="#A7B4C8"
    />
  </svg>
)

export default Qr
