import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  ECHO_BLUE,
  GHOST_WHITE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  RAVEN,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  tableWrapper: {
    position: 'relative',
  },
  tableRoot: {
    marginTop: 0,
    marginBottom: 0,
    borderRadius: '5px 5px 0px 0px',
    overflow: 'hidden',
  },
  tableHead: {
    backgroundColor: GHOST_WHITE,
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 11,
    lineHeight: '160%',
    textTransform: 'capitalize',
    color: ECHO_BLUE,
    height: '54px',
  },
  tableCell: {
    paddingLeft: '30px !important',
  },
  clickableColumn: {
    cursor: 'pointer',
  },
  centerAlign: {
    padding: '0 6px 0 16px !important',
  },
  commandButton: {
    paddingLeft: 0,
  },
  iconColumn: {
    paddingLeft: '18px !important',
  },
  toolbarWrapper: {},
  toolbar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 70,
    borderTop: `1px solid ${SOLITUDE}`,
  },
  filterButton: {
    position: 'absolute',
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
    right: 0,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '160%',
    color: RAVEN,
    backgroundColor: '#FFFFFF',
    padding: '8px 13px',
    textTransform: 'initial',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '28px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  checkboxFilterWrapper: {
    position: 'relative',
    marginLeft: '15px',
    zIndex: 1,
  },
  checkBoxFilterButton: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '160%',
    color: RAVEN,
    backgroundColor: '#FFFFFF',
    padding: '8px 13px',
    textTransform: 'initial',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '28px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  filterActiveButton: {
    color: NEON_BLUE_LIGHT,
    borderColor: NEON_BLUE_LIGHT,
  },
  filterIconActive: {
    transform: 'rotate(180deg)',
    '& > path': {
      stroke: NEON_BLUE_LIGHT,
    },
  },
  filterBar: {
    width: '100%',
    opacity: 1,
    transition: 'opacity 0.3s ease-in 0.3s, width 0.3s ease, height 0.3s ease',
  },
  fadeOut: {
    opacity: 0,
    height: 0,
    width: 0,
    transition: 'opacity 0.3s ease-out, width 0.3s ease 0.3s, height 0.3s ease 0.3s',
  },
  filterParameters: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  filteredValues: {
    backgroundColor: ALICE_BLUE,
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '160%',
    maxHeight: '30px',
    marginBottom: '5px',
    borderRadius: '4px',
    '& > span': {
      marginRight: '3px',
    },
  },
  columnField: {
    textTransform: 'capitalize',
    color: RAVEN,
  },
  operatorValue: {
    color: ECHO_BLUE,
  },
  filterValue: {
    color: RAVEN,
  },
  removeFilter: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  removeIcon: {
    transform: 'rotate(45deg)',
    '& > path': {
      stroke: RAVEN,
    },
  },
  newFilterWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  plusButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: '5px',
    backgroundColor: GHOST_WHITE,
    cursor: 'pointer',
  },
  filterGroup: {
    position: 'absolute',
    top: 0,
    left: 40,
    display: 'flex',
    alignItems: 'flex-start',
  },
  filterGroupLeft: {
    left: -510,
    width: 500,
    '& > ul': {
      order: 1,
      marginLeft: 'auto',
    },
  },
  filterList: {
    minWidth: '160px',
    backgroundColor: '#FFFFFF',
    border: `1px solid ${SOLITUDE}`,
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    zIndex: 1,
  },
  hidden: {
    display: 'none',
  },
  filterListItem: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    padding: '6px 25px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  activeFilterItem: {
    color: NEON_BLUE_LIGHT,
    backgroundColor: ALICE_BLUE,
  },
  filterItemWindow: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${SOLITUDE}`,
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    marginLeft: '10px',
    zIndex: 1,
    padding: '20px 20px 70px',
    minWidth: '320px',
    height: 'fit-content',
    position: 'relative',
  },
  filterItemTitle: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    textTransform: 'capitalize',
  },
  radioGroup: {
    marginTop: 15,
  },
  radioLabel: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
  },
  input: {
    marginTop: '18px',
    '& > div > input': {
      fontFamily: POPPINS,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '20px',
      color: MIDNIGHT_EXPRESS,
    },
  },
  actionButtons: {
    marginTop: 17,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 20,
    backgroundColor: 'white',
    width: '277px',
  },
  button: {
    textTransform: 'capitalize',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '100%',
  },
  cancelButton: {
    maxWidth: '102px',
    marginRight: '10px',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  applyButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  rowDetailsWrapper: {
    backgroundColor: '#FFFFFF',
  },
  mainDetails: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pickupDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '400px',
    borderRight: `1px solid ${SOLITUDE}`,
  },
  addressDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  detailsHeaderWrap: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${SOLITUDE}`,
    padding: '15px 25px',
  },
  detailsHeader: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
  },
  detailsBody: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 25px 25px',
    height: '100%',
  },
  pickupStatus: {
    marginLeft: 'auto',
  },
  detailsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:first-child)': {
      marginTop: '15px',
    },
  },
  detailsGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '355px',
    '&:not(:first-child)': {
      marginLeft: '20px',
    },
  },
  detailsType: {
    fontFamily: POPPINS,
    fontSize: 13,
    lineHeight: '150%',
    color: RAVEN,
  },
  detailsData: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '160%',
    color: MIDNIGHT_EXPRESS,
    paddingLeft: '15px',
  },
  iconButton: {
    padding: 0,
    marginLeft: '11px',
  },
  packagesInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  packagesHeaderWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${SOLITUDE}`,
    padding: '15px 25px',
    backgroundColor: GHOST_WHITE,
    borderTop: `1px solid ${SOLITUDE}`,
  },
  plusIconButton: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    padding: 0,
    backgroundColor: NEON_BLUE_LIGHT,
    marginRight: '5px',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  plus: {
    '& > path': {
      stroke: '#FFFFFF',
    },
  },
  iconCell: {
    display: 'flex',
    alignItems: 'center',
  },
  noDataCelll: {
    padding: '10px 25px',
  },
  icon: {
    '& > path': {
      stroke: ECHO_BLUE,
    },
  },
  imageFormatterImage: {
    height: '28px',
    margin: '0 auto',
  },
  imageFormatterImageHandler: {
    display: 'inline-block',
    background: 'white',
    borderRadius: '3px',
    width: '30px',
    height: '30px',
    margin: '-8px 8px -8px 0',
    textAlign: 'center',
  },
  imageFormatterWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  linkFormatterWrapper: {},
  linkFormatterHyperlink: {
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
  },
  checkboxFilterPaper: {
    position: 'absolute',
    top: 42,
    zIndex: 11,
    width: 150,
    left: -10,
    background: '#FFFFFF',
    boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.16)',
  },
  checkboxWrapper: {
    padding: '0 5px',
  },
  checkbox: {
    color: NEON_BLUE_LIGHT,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: NEON_BLUE_LIGHT,
    },
  },
  checkboxWrapperLabels: {
    fontFamily: POPPINS,
    fontSize: 13,
    lineHeight: '150%',
    color: RAVEN,
  },
  disabledButton: {
    background: '#FFFFFF !important',
    color: 'rgba(146, 155, 172, 0.9) !important',
    border: '1px solid rgba(146, 155, 172, 0.4) !important',
  },
  selectedRow: {
    backgroundColor: ALICE_BLUE,
    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.07)',
    position: 'relative',
    '& > td': {
      position: 'relative',
      borderBottom: `1px solid ${NEON_BLUE_LIGHT}`,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '1px',
        backgroundColor: NEON_BLUE_LIGHT,
      },
    },
    '& > td:first-child': {
      borderLeft: `1px solid ${NEON_BLUE_LIGHT}`,
    },
    '& > td:last-child': {
      borderRight: `1px solid ${NEON_BLUE_LIGHT}`,
    },
  },
  calendarIcon: {
    width: 18,
    height: 18,
  },
  calendarWrapper: {
    marginLeft: 'auto',
    position: 'relative',
  },
  tooltip: {
    fontFamily: POPPINS,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '160%',
    color: '#FFFFFF',
    backgroundColor: 'rgba(102, 109, 120, 0.94)',
    marginTop: 15,
  },
  datePicker: {
    position: 'absolute',
    left: '-320px',
    top: 0,
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  modalButtons: {
    display: 'flex',
    margin: '0 25px 20px',
  },
  spinnerWrapper: {
    height: '100%',
    minHeight: 300,
    position: 'relative',
  },
  spinner: {},
}))

export default useStyles
