interface Props {
  className?: string
}

const ActivePrinter = ({ className }: Props): JSX.Element => (
  <svg
    width="22"
    height="22"
    className={className}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.41662C0 4.89784 1.23122 3.66663 2.75 3.66663H19.25C20.7688 3.66663 22 4.89784 22 6.41663V13.75C22 15.2687 20.7688 16.5 19.25 16.5H18.3333V12.8333H3.66667V16.5H2.75C1.23122 16.5 0 15.2687 0 13.75V6.41662Z"
      fill="#635DF6"
    />
    <path
      d="M4.58325 13.75H17.4166V21.0833C17.4166 21.5896 17.0062 22 16.4999 22H5.49992C4.99366 22 4.58325 21.5896 4.58325 21.0833V13.75Z"
      fill="#635DF6"
    />
    <path
      d="M17.4167 2.75L4.58342 2.75L4.58342 0.916667C4.58342 0.410406 4.99382 1.06046e-07 5.50008 1.50305e-07L16.5001 1.11196e-06C17.0063 1.15621e-06 17.4167 0.410407 17.4167 0.916668L17.4167 2.75Z"
      fill="#635DF6"
    />
  </svg>
)

export default ActivePrinter
