import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import {
  Carrier,
  Hub,
  Partner,
  PartnerDestination,
} from '@returnmates/client-core/src/graphql/generated/api'
import { getCarriers } from '@returnmates/client-core/src/selectors/admin'
import { getHubs } from '@returnmates/client-core/src/selectors/hubs'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import AutocompleteInput from '@returnmates/ui-core/src/components/AutocompleteInput'
import ErrorBlock from '@returnmates/ui-core/src/components/ErrorBlock'
import { HTMLAttributes, memo, useCallback, useEffect, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import CalendarIcon from '../../../components/images/icons/calendar'
import SingleDatePicker from '../../../components/SingleDatePicker'
import useEnabledPartners from '../../../hooks/useEnabledPartners'
import useStyles from '../styles'
import NewAddressDropdown from './NewAddressDropdown'
import PaperComponentCustom from './PaperComponentCustom'

function InboundConsolidationModalDetails() {
  const classes = useStyles()
  const [error, setError] = useState<string>('')
  const [
    partnerDestionations,
    setPartnerDestinations,
  ] = useState<Array<PartnerDestination | null> | null>([])
  const [partnerDestinationValue, setPartnerDestinationValue] = useState<PartnerDestination | null>(
    null,
  )
  const [carrierValue, setCarrierValue] = useState(null)
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const [isDestinationOpen, setIsDestinationOpen] = useState(false)
  const hubs = useSelector(getHubs)
  const carriers = useSelector(getCarriers)
  const partners = useEnabledPartners().filter(partner => partner.isConsolidationEnabled)
  const dispatch = useDispatch()
  const { input: hubInput } = useField('hubId')
  const { input: partnerDestinationInput } = useField('partnerDestinationId')
  const { input: carrierInput } = useField('carrierId')
  const { input: partnerInput } = useField('partner')

  const fetchPartners = useCallback(async () => {
    try {
      await createAsyncAction(dispatch, actions.getPartners.request({}))
    } catch (err) {
      const { message } = err as Error
      setError(message)
    }
  }, [dispatch])

  const filterPartnerOptions = useCallback(
    (arr: Array<Partner>, { inputValue }) =>
      arr.filter(
        partner =>
          partner.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
          partner.code?.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [],
  )
  const filterHubOptions = useCallback(
    (arr: Array<Hub>, { inputValue }) =>
      arr.filter(
        hub =>
          hub.name?.toLowerCase().includes(inputValue.toLowerCase()) ||
          hub.id?.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [],
  )

  const onPartnerDestinationSelect = useCallback(
    (_, value) => {
      if (value) {
        partnerDestinationInput.onChange(value.id)
        setPartnerDestinationValue(value)
        setIsDestinationOpen(false)
      } else {
        partnerDestinationInput.onChange('')
        setPartnerDestinationValue(null)
      }
    },
    [partnerDestinationInput],
  )

  const onCarrierSelect = useCallback(
    (_, value) => {
      if (value) {
        carrierInput.onChange(value.id)
        setCarrierValue(value)
      } else {
        carrierInput.onChange('')
        setCarrierValue(null)
      }
    },
    [carrierInput],
  )

  const onPartnerSelect = useCallback(
    (_, value: Partner) => {
      if (value) {
        partnerInput.onChange(value.code)
        setPartnerDestinations(value.destinations || [])
      } else {
        partnerInput.onChange(null)
        setPartnerDestinations([])
      }
    },
    [partnerInput],
  )

  const handleOpenAddressModal = useCallback(() => {
    setIsAddressModalOpen(true)
    setIsDestinationOpen(true)
  }, [])

  const handlePartnerDestinationOpen = useCallback(() => {
    setIsDestinationOpen(true)
  }, [])

  const handlePartnerDestinationClose = useCallback(() => {
    if (!isAddressModalOpen) {
      setIsDestinationOpen(false)
      setIsAddressModalOpen(false)
    }
  }, [isAddressModalOpen])

  const handleAddressDropdownClose = useCallback(() => {
    setIsDestinationOpen(false)
    setIsAddressModalOpen(false)
  }, [])

  const onHubSelect = useCallback(
    (_, value) => {
      if (value) {
        hubInput.onChange(value.id)
      } else {
        hubInput.onChange('')
      }
    },
    [hubInput],
  )

  useEffect(() => {
    fetchPartners()
  }, [fetchPartners])

  useEffect(() => {
    if (partners && partners.length && partnerInput.value) {
      const destinations = partners.find((p: Partner) => p.code === partnerInput.value)
        ?.destinations

      setPartnerDestinations(destinations || [])
    }
  }, [partnerDestinationInput, partnerInput.value, partners])

  return (
    <>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Hub</p>
        <ErrorBlock message={error} />
        <Field name="hubId">
          {({ meta }) => (
            <AutocompleteInput
              options={hubs || []}
              filterOptions={filterHubOptions}
              onChange={onHubSelect}
              getOptionLabel={(option: Hub) => option.name || ''}
              renderOption={(events: HTMLAttributes<HTMLElement>, option: Hub) => (
                <p
                  {...events}
                  key={option.id}
                  className={`${events.className} ${classes.optionPrimary}`}
                >
                  {option.name}
                </p>
              )}
              placeholder="Select hub..."
              meta={meta}
            />
          )}
        </Field>
      </div>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Partner</p>
        <Field name="partner">
          {({ meta }) => (
            <AutocompleteInput
              options={partners || []}
              filterOptions={filterPartnerOptions}
              onChange={onPartnerSelect}
              getOptionLabel={(option: Partner) => option.name || ''}
              renderOption={(events: HTMLAttributes<HTMLElement>, option: Partner) => (
                <p
                  {...events}
                  key={option.code}
                  className={`${events.className} ${classes.optionPrimary}`}
                >
                  {option.name},&nbsp;<span className={classes.optionSecondary}>{option.code}</span>
                </p>
              )}
              placeholder="Select partner..."
              meta={meta}
            />
          )}
        </Field>
      </div>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Partner Destination</p>
        <Field name="partnerDestinationId">
          {({ meta }) => (
            <AutocompleteInput
              options={partnerDestionations || []}
              onChange={onPartnerDestinationSelect}
              onOpen={handlePartnerDestinationOpen}
              onClose={handlePartnerDestinationClose}
              getOptionLabel={(option: PartnerDestination) =>
                `${option.address?.street}, ${option.address?.city}, ${option.address?.state}, ${option.address?.zipCode}`
              }
              renderOption={(events: HTMLAttributes<HTMLElement>, option: PartnerDestination) => (
                <p
                  {...events}
                  key={option.id}
                  className={`${events.className} ${classes.optionSecondary}`}
                >
                  {option.address?.street}, {option.address?.city}, {option.address?.state},{' '}
                  {option.address?.zipCode}
                </p>
              )}
              inputValue={partnerDestinationValue || ''}
              value={partnerDestinationValue || null}
              placeholder="Select partner destination..."
              noOptionsText="Select a Partner to Populate Destinations"
              meta={meta}
              isOpen={isDestinationOpen}
              PaperComponent={options =>
                isAddressModalOpen ? (
                  <NewAddressDropdown
                    className={options.className}
                    onCloseDropdown={handleAddressDropdownClose}
                    partnerCode={partnerInput.value}
                    setPartnerDestinationValue={setPartnerDestinationValue}
                  />
                ) : (
                  <PaperComponentCustom
                    className={options.className}
                    children={options.children}
                    showAddButton={Boolean(partnerInput.value)}
                    onButtonClick={handleOpenAddressModal}
                  />
                )
              }
            />
          )}
        </Field>
      </div>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Carrier</p>
        <Field name="carrierId">
          {({ meta }) => (
            <AutocompleteInput
              options={carriers || []}
              onChange={onCarrierSelect}
              getOptionLabel={(option: Carrier) => `${option.name}`}
              renderOption={(events: HTMLAttributes<HTMLElement>, option: Carrier) => (
                <p
                  {...events}
                  key={option.id}
                  className={`${events.className} ${classes.optionSecondary}`}
                >
                  {option.name}
                </p>
              )}
              inputValue={carrierValue || ''}
              placeholder="Select carrier..."
              meta={meta}
              disablePortal={false}
            />
          )}
        </Field>
      </div>
      <div className={classes.modalDetailsRow}>
        <p className={classes.modalDetailsTitle}>Expected Departure Date</p>
        <Field name="expectedDeliveryDate">
          {({ input, meta }) => (
            <SingleDatePicker
              placeholder="MM-DD-YYYY"
              value={input.value}
              onChange={input.onChange}
              disablePast
              fullWidth
              keyboardIcon={CalendarIcon}
              className={classes.datePicker}
              error={meta.touched && meta.error}
              helperText={meta.error}
            />
          )}
        </Field>
      </div>
    </>
  )
}

export default memo(InboundConsolidationModalDetails)
