import { Column } from '@devexpress/dx-react-grid'

export enum FilterPanelType {
  INPUT = 'input',
  RADIO_GROUP = 'radioGroup',
}

export enum ColumnTypes {
  TEXT = 'text',
  IMAGE = 'image',
  LINK = 'link',
}

export interface TableColumn extends Column {
  searchable?: boolean
  filterable?: boolean
  hidden?: boolean
  filterPanelType?: FilterPanelType.INPUT | FilterPanelType.RADIO_GROUP
  filterRadioOptions?: {
    [key: string]: string
  }
  sortingEnabled?: boolean
  align?: string
  width: string | number
}

export enum OptionType {
  TRIP = 'TRIP',
  PACKAGE = 'PACKAGE',
}
