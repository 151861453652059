import {
  OrderType,
  PackageOrderFields,
  PackageStatus,
} from '@returnmates/client-core/src/graphql/generated/api'

export const directionMap = {
  asc: OrderType.ASC,
  desc: OrderType.DESC,
}

export const sortingMap = {
  status: PackageOrderFields.STATUS,
  tripName: PackageOrderFields.NAME,
  retailer: PackageOrderFields.RETAILER,
  trackingId: PackageOrderFields.TRACKING,
  partnerCode: PackageOrderFields.PARTNER,
  date: PackageOrderFields.DATE,
  packageDate: PackageOrderFields.PACKAGE_DATE,
  expectedDate: PackageOrderFields.EXPECTED_DATE,
  locationName: PackageOrderFields.LOCATION_NAME,
  polybagId: PackageOrderFields.POLYBAG,
}

export const defaultSorting = { columnName: 'tripName', direction: 'asc' as const }

export const canLinkPackageToConsolidationStatuses = [
  PackageStatus.PENDING,
  PackageStatus.PICKED_UP,
  PackageStatus.RECEIVED,
  PackageStatus.CONSOLIDATED,
]

export const notRemovePackageFromConsolidationStatuses = [
  PackageStatus.DELIVERED,
  PackageStatus.DEPARTED,
]

export const notUpdatePackageReturnDestinationIdStatuses = [
  PackageStatus.CONSOLIDATED,
  PackageStatus.DELIVERED,
  PackageStatus.DEPARTED,
]
