import {
  NEON_BLUE_LIGHT,
  SHAMROCK,
  YELLOW_SEA,
} from '@returnmates/client-core/src/constants/colors'
import { LabelType } from '@returnmates/client-core/src/graphql/generated/api'

export const LABEL_TYPE_COLORS_MAP = {
  [LabelType.PHYSICAL]: NEON_BLUE_LIGHT,
  [LabelType.DIGITAL]: SHAMROCK,
  [LabelType.AMAZON]: YELLOW_SEA,
  [LabelType.CONSOLIDATED]: SHAMROCK,
}
