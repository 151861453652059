import { IconButton, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { PackageStatus } from '@returnmates/client-core/src/graphql/generated/api'
import { getBarcodeScan } from '@returnmates/client-core/src/selectors/admin'
import { getPackages } from '@returnmates/client-core/src/selectors/packages'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import AutocompleteInput from '@returnmates/ui-core/src/components/AutocompleteInput/index'
import Check from '@returnmates/ui-core/src/components/images/check'
import { HTMLAttributes, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from '../../images/icons/close'
import useStyles from '../styles'

interface Props {
  handleSubmit: () => void
  partnerCode?: string
  form: {
    resetFieldState: (name: string) => void
    mutators: { [key: string]: () => void }
  }
  setPackageSubmitError?: (val: { [key: string]: string } | null) => void
  failedPackageIds: { [key: string]: string }
  succedPackageIds?: string[]
}

const statusToFilter = [
  PackageStatus.PENDING,
  PackageStatus.PICKED_UP,
  PackageStatus.RECEIVED,
  PackageStatus.CONSOLIDATED,
  PackageStatus.DRIVER_LAUNCHED,
  PackageStatus.DRIVER_ARRIVING,
  PackageStatus.IN_CUSTODY,
  PackageStatus.DELAYED,
]

function PackageModalDetails({
  handleSubmit,
  form,
  setPackageSubmitError,
  failedPackageIds,
  succedPackageIds,
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [error, setError] = useState<string>()
  const [query, setQuery] = useState('')
  const packages = useSelector(getPackages)?.filter(pack => statusToFilter.includes(pack.status))
  const { input: packageInput } = useField<string | string[]>('consolidationPackageId')
  const [isOpen, setIsOpen] = useState(false)
  const [isHandleSubmit, setIsHandleSubmit] = useState(false)
  const barcodeScan = useSelector(getBarcodeScan)
  const packageIdRef = useRef<boolean>(false)

  const options = useMemo(() => packages?.map(pack => pack.id), [packages])
  const [value, setValue] = useState<string | null>(null)

  const fetchPackages = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        actions.getPackages.request({
          where: {
            search: query,
          },
          pagination: { page: 0, size: 5 },
        }),
      )
    } catch (err) {
      const { message } = err as Error
      setError(message)
    }
  }, [dispatch, query])

  const onPackageSelect = useCallback(
    (_, value?: string) => {
      if (value) {
        setValue(value)
        packageInput.onChange(value)
        setIsOpen(false)
        setIsHandleSubmit(true)
      } else {
        packageInput.onChange(null)
      }
    },
    [packageInput],
  )

  const onQueryChange = useCallback(
    (_, value) => {
      if (value.length) {
        setIsOpen(true)
        setQuery(value)
      } else {
        setIsOpen(false)
        setValue(null)
      }
      form.resetFieldState('consolidationPackageId')
      setPackageSubmitError?.(null)
    },
    [form, setPackageSubmitError],
  )

  const filterOptions = useCallback(arr => arr, [])

  const failedPackageIdsArr = useMemo(() => Object.keys(failedPackageIds), [failedPackageIds])

  useEffect(() => {
    fetchPackages()
  }, [fetchPackages])

  useEffect(() => {
    const scannedLabelId = barcodeScan?.packageId || barcodeScan?.scannedId
    if (scannedLabelId) {
      setQuery(scannedLabelId)
    } else {
      packageIdRef.current = false
    }
  }, [barcodeScan?.packageId, barcodeScan?.scannedId])

  useEffect(() => {
    const scannedLabelId = barcodeScan?.packageId || barcodeScan?.scannedId
    const selectedPackage = packages?.find(pack => pack.id === scannedLabelId)
    const scannedLabelIds = barcodeScan?.packageIds?.filter(p => p && p.trim().length)

    if (scannedLabelIds?.length) {
      packageInput.onChange(scannedLabelIds)
      setIsHandleSubmit(true)
      setValue(null)
    }

    if (selectedPackage && !packageIdRef.current) {
      onPackageSelect(null, scannedLabelId)
      packageIdRef.current = true
    }
  }, [barcodeScan, onPackageSelect, packageInput, packages])

  useEffect(() => {
    if (isHandleSubmit && packageInput.value) {
      setIsOpen(false)
      handleSubmit()
      form.mutators.setTouched()

      setIsHandleSubmit(false)

      dispatch(
        actions.scanBarcode.request({
          packageId: null,
          scannedId: null,
          packageIds: null,
        }),
      )
    }
  }, [dispatch, form, form.mutators, handleSubmit, isHandleSubmit, packageInput.value])

  return (
    <>
      <div className={classes.modalDetailsRow}>
        <Field name="consolidationPackageId">
          {({ meta }) => (
            <>
              <AutocompleteInput
                isOpen={isOpen}
                options={options || []}
                value={value}
                filterOptions={filterOptions}
                onInputChange={onQueryChange}
                onChange={onPackageSelect}
                renderOption={(events: HTMLAttributes<HTMLElement>, option: string) => (
                  <p
                    {...events}
                    key={option}
                    className={`${events.className} ${classes.optionPrimary}`}
                  >
                    {option}
                  </p>
                )}
                placeholder="Enter your Package ID..."
                meta={meta}
                disablePortal={false}
              />
            </>
          )}
        </Field>

        {Array.isArray(packageInput.value) && packageInput.value.length ? (
          <div className={classes.scannedListBlock}>
            <p className={classes.scannedListTitle}>Scanned package IDs:</p>
            <div className={classes.scannedIdsBlock}>
              {packageInput.value.map(packId => (
                <div key={packId} className={classes.scannedIdRow}>
                  <p className={classes.scannedId}>{packId}</p>
                  {!failedPackageIdsArr.includes(packId) && !succedPackageIds?.includes(packId) ? (
                    <CircularProgress className={classes.circularProgress} size={14} />
                  ) : null}
                  {failedPackageIdsArr?.includes(packId) ? (
                    <Tooltip title={failedPackageIds[packId]} placement="top-end">
                      <IconButton className={classes.iconButtonSecond}>
                        <CloseIcon className={classes.redCross} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {succedPackageIds?.includes(packId) ? (
                    <Tooltip title="Package tied to consolidation successfully" placement="top-end">
                      <IconButton className={classes.iconButtonSecond}>
                        <Check className={classes.greenCheck} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {error ? <p className={classes.error}>{error}</p> : null}
    </>
  )
}

export default memo(PackageModalDetails)
