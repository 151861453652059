import { Table } from '@devexpress/dx-react-grid-material-ui'
import { memo } from 'react'

import useStyles from '../styles'

const NoDataCellComponent = (props: Table.NoDataCellProps) => {
  const classes = useStyles()

  return <Table.NoDataCell className={classes.noDataCelll} {...props} />
}

export default memo(NoDataCellComponent)
