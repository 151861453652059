import { addDays, endOfDay, isSameDay, startOfDay } from 'date-fns'

interface Range {
  startDate: Date
  endDate: Date
}

interface staticRangeProps {
  label: string
  range: () => Range
  isSelected: (range: Range) => boolean
}

const defineds = {
  startOfToday: new Date(),
  endOfToday: new Date(),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfTomorrow: startOfDay(addDays(new Date(), +1)),
  endOfTomorrow: endOfDay(addDays(new Date(), +1)),
  startOfSevenDays: startOfDay(addDays(new Date(), +0)),
  endOfSevenDays: endOfDay(addDays(new Date(), +6)),
}

export const staticRanges = [
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
    isSelected(range) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
    isSelected(range) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: 'Tomorrow',
    range: () => ({
      startDate: defineds.startOfTomorrow,
      endDate: defineds.endOfTomorrow,
    }),
    isSelected(range) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: '7 Days',
    range: () => ({
      startDate: defineds.startOfSevenDays,
      endDate: defineds.endOfSevenDays,
    }),
    isSelected(range) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
] as staticRangeProps[]

export const todayDateFilter = [
  {
    startDate: defineds.startOfToday,
    endDate: defineds.endOfToday,
    key: 'selection',
  },
]

export function isToday(date: Date | undefined): boolean {
  return new Date().toDateString() === date?.toDateString()
}
