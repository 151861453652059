import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { INBOUND_CONSOLIDATION_STATUS_MAP } from '@returnmates/client-core/src/constants/trip'
import {
  Carrier,
  ConsolidationStatus,
  Partner,
} from '@returnmates/client-core/src/graphql/generated/api'
import { getCarriers, getPartners } from '@returnmates/client-core/src/selectors/admin'
import Input from '@returnmates/ui-core/src/components/Input'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useSelector } from 'react-redux'

import LocationScansRow from '../../EditPackageForm/components/LocationScansRow'
import CalendarIcon from '../../images/icons/calendar'
import Select from '../../Select'
import SingleDatePicker from '../../SingleDatePicker'
import WrappedSelect from '../../WrappedSelect'
import useStyles from '../styles'
import ScansRow from './ScansRow'

const emptyArray: Carrier[] = []

interface Props {
  consolidationId?: string
}

function EditConsolidationFormInner({ consolidationId }: Props) {
  const classes = useStyles()
  const partners = useSelector(getPartners)
  const carriers = useSelector(getCarriers)
  const { input: statusInput } = useField('status')
  const { input: trackingIdInput } = useField('trackingId')
  const { input: carrierInput } = useField('carrier')
  const { input: scans } = useField('scans')
  const { input: locationScans } = useField('locationScans')
  const [isCarrierManuallySelected, setIsCarrierManuallySelected] = useState(false)

  const autoDetectCarrier = useCallback(
    value => {
      const detectedCarrier = carriers?.find(carrier => {
        if (carrier.regex) {
          const regex = new RegExp(carrier.regex)
          return regex.test(value.replace(/ /g, ''))
        }
      })

      carrierInput.onChange(detectedCarrier)
    },
    [carriers, carrierInput],
  )

  const onTrackingIdChange = useCallback(
    val => {
      trackingIdInput.onChange(val)
      if (!isCarrierManuallySelected) {
        autoDetectCarrier(val)
      }
    },
    [autoDetectCarrier, isCarrierManuallySelected, trackingIdInput],
  )

  const onCarrierChange = useCallback(
    val => {
      carrierInput.onChange(val)
      setIsCarrierManuallySelected(true)
    },
    [carrierInput],
  )

  const onNoLabelClick = useCallback(() => {
    const newTrackingId = consolidationId
      ?.split('-')
      .join('')
      .toUpperCase()
    trackingIdInput.onChange(newTrackingId)
    autoDetectCarrier(newTrackingId)
  }, [autoDetectCarrier, consolidationId, trackingIdInput])

  const trackingAutoFocus = useMemo(() => !trackingIdInput.value, [trackingIdInput.value])

  useEffect(() => {
    if (trackingIdInput && !carrierInput.value) {
      autoDetectCarrier(trackingIdInput.value)
    }
  }, [trackingIdInput, carrierInput.value, autoDetectCarrier])

  useEffect(() => {
    if (carrierInput.value && typeof carrierInput.value === 'object') {
      setIsCarrierManuallySelected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Status</p>
        <Field name="status">
          {({ input }) => (
            <Select
              value={input.value}
              onChange={input.onChange}
              values={INBOUND_CONSOLIDATION_STATUS_MAP}
              className={classes.select}
              showItemIcon
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Created Date</p>
        <Field name="date">
          {({ input, meta }) => (
            <SingleDatePicker
              placeholder="Select date"
              fullWidth
              value={input.value}
              onChange={input.onChange}
              keyboardIcon={CalendarIcon}
              className={classes.datePicker}
              disabled
              error={meta.touched && meta.error}
              helperText={meta.error}
              disablePast
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Expected Departure Date</p>
        <Field name="expectedDeliveryDate">
          {({ input, meta }) => (
            <SingleDatePicker
              placeholder="Select date"
              fullWidth
              value={input.value}
              onChange={input.onChange}
              keyboardIcon={CalendarIcon}
              className={classes.datePicker}
              disabled={statusInput.value === ConsolidationStatus.DEPARTED}
              error={meta.touched && meta.error}
              helperText={meta.error}
              disablePast
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Weight</p>
        <Field name="weight">
          {({ input, meta }) => (
            <Input
              placeholder="Weight"
              classes={{
                textField: classes.input,
              }}
              onChange={input.onChange}
              value={input.value}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
              type="number"
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}># of Packages</p>
        <Field name="packageCount">
          {({ input, meta }) => (
            <Input
              classes={{
                textField: classes.input,
              }}
              value={input.value || 0}
              fullWidth
              helperText={meta.error}
              error={meta.touched && meta.error}
              disabled
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Partner:</p>
        <Field name="partner">
          {({ input }) => (
            <WrappedSelect<'code', Partner>
              value={input.value as Partner}
              onChange={input.onChange}
              values={partners as Partner[]}
              objectKey="code"
              placeholder="Choose partner..."
              fullWidth
              disabled
              popupIconStyles={classes.disableIcon}
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Carrier:</p>
        <Field name="carrier">
          {({ input }) => (
            <WrappedSelect
              value={input.value}
              onChange={onCarrierChange}
              values={carriers || emptyArray}
              objectKey="id"
              placeholder="Choose carrier"
              fullWidth
            />
          )}
        </Field>
      </div>
      <div className={classes.detailsRow}>
        <p className={classes.inputHead}>Tracking Id:</p>
        <Field name="trackingId">
          {({ input, meta }) => (
            <div className={classes.trackingInput}>
              <Input
                placeholder="Add tracking Id"
                classes={{
                  textField: clsx(classes.input, classes.trackingField),
                }}
                onChange={onTrackingIdChange}
                value={input.value}
                autoFocus={trackingAutoFocus}
                fullWidth
                helperText={meta.error || meta.submitError}
                error={
                  (!meta.touched && meta.error) || (!meta.dirtySinceLastSubmit && meta.submitError)
                }
              />
              <div className={classes.noLabelButton} onClick={onNoLabelClick}>
                Generate Tracking ID
              </div>
            </div>
          )}
        </Field>
      </div>
      {scans.value.length ? (
        <div className={clsx(classes.detailsBlock, classes.tableWrap)}>
          <p className={clsx(classes.inputHead)}>Barcode Scans</p>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.headerCell}>Date</TableCell>
                <TableCell className={classes.headerCell}>Tracking ID</TableCell>
                <TableCell className={classes.headerCell}>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="scans">
                {({ fields }) =>
                  fields.map(fieldName => <ScansRow fieldName={fieldName} key={fieldName} />)
                }
              </FieldArray>
            </TableBody>
          </Table>
        </div>
      ) : null}
      {locationScans.value.length ? (
        <div className={clsx(classes.detailsBlock, classes.tableWrap)}>
          <p className={clsx(classes.inputHead)}>Location Scans</p>
          <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headerRow}>
                <TableCell className={classes.headerCell}>Date</TableCell>
                <TableCell className={classes.headerCell}>Location</TableCell>
                <TableCell className={classes.headerCell}>User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="locationScans">
                {({ fields }) =>
                  fields.map(fieldName => (
                    <LocationScansRow fieldName={fieldName} key={fieldName} />
                  ))
                }
              </FieldArray>
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  )
}

export default memo(EditConsolidationFormInner)
