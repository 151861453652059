import { makeStyles } from '@mui/styles'
import {
  GRAYISH_BLUE,
  NEON_BLUE,
  NEON_BLUE_LIGHT,
  RAVEN,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  checkboxFilterWrapper: {
    position: 'relative',
    marginLeft: '15px',
  },
  checkBoxFilterButton: {
    width: '130px',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '160%',
    color: RAVEN,
    backgroundColor: '#FFFFFF',
    padding: '8px 13px',
    textTransform: 'initial',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '28px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: `1px solid ${NEON_BLUE}`,
    },
  },
  filterActiveButton: {
    color: NEON_BLUE_LIGHT,
    borderColor: NEON_BLUE_LIGHT,
  },
  disabledButton: {
    background: '#FFFFFF !important',
    color: 'rgba(146, 155, 172, 0.9) !important',
    border: '1px solid rgba(146, 155, 172, 0.4) !important',
  },
  filterIconActive: {
    transform: 'rotate(180deg)',
    '& > path': {
      stroke: NEON_BLUE_LIGHT,
    },
  },
  checkboxFilterPaper: {
    position: 'absolute',
    top: 42,
    zIndex: 101,
    width: 'max-content',
    minWidth: 200,
    maxHeight: 500,
    overflowY: 'auto',
    left: -10,
    background: '#FFFFFF',
    boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.16)',
    opacity: 1,
  },
  checkboxWrapper: {
    padding: '0 5px',
  },
  checkbox: {
    color: NEON_BLUE_LIGHT,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: NEON_BLUE_LIGHT,
    },
  },
  checkboxWrapperLabels: {
    fontFamily: POPPINS,
    fontSize: 13,
    lineHeight: '150%',
    color: RAVEN,
  },
  isAllSelected: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 5,
  },
  searchInput: {
    margin: '0 15px',
    maxWidth: 250,
    position: 'sticky',
    top: 42,
    backgroundColor: '#FFFFFF',
    zIndex: 5,
    '& input': {
      padding: '10px 20px',
    },
  },
  searchIcon: {
    minWidth: 24,
  },
  closeIcon: {
    '& path': {
      stroke: GRAYISH_BLUE,
    },
  },
}))

export default useStyles
