import { Address } from '@returnmates/client-core/src/graphql/generated/api'
import { PickupDetails } from '@returnmates/client-core/src/type/pickups'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import detailsValidate from '@returnmates/client-core/src/utils/validation/pickupHelpers/details'
import Button from '@returnmates/ui-core/src/components/Button'
import ErrorBlock from '@returnmates/ui-core/src/components/ErrorBlock'
import Details from '@returnmates/ui-core/src/components/PickupDetails'
import useOutsideClick from '@returnmates/ui-core/src/hooks/useOutsideClick'
import clsx from 'clsx'
import { FORM_ERROR } from 'final-form'
import { memo, useCallback, useRef, useState } from 'react'
import { Form } from 'react-final-form'

import useStyles from './styles'

interface Props {
  className?: string
  onCloseDropdown: () => void
  createAddress: (val: PickupDetails) => Promise<Address>
  reset: (res: Address) => void
  addNewAddress?: (address: Address) => void
  isZipCodeWithoutValidation?: boolean
}

function AddNewAddress({
  className,
  onCloseDropdown,
  createAddress,
  reset,
  addNewAddress,
  isZipCodeWithoutValidation,
}: Props) {
  const classes = useStyles()
  const addressDropdownRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleAddressCreate = useCallback(
    async (val: PickupDetails) => {
      setIsLoading(true)
      try {
        const res = await createAddress(val)

        if (res) {
          reset(res)
          addNewAddress?.(res)
          onCloseDropdown()
        }
      } catch (err) {
        const { message } = err as Error

        return { [FORM_ERROR]: errorMapper(message || (err as string)) }
      } finally {
        setIsLoading(false)
      }
    },
    [onCloseDropdown, createAddress, reset, addNewAddress],
  )

  const validate = useCallback(
    val => detailsValidate(val, { email: true, zipCode: Boolean(isZipCodeWithoutValidation) }),
    [isZipCodeWithoutValidation],
  )

  useOutsideClick(addressDropdownRef, onCloseDropdown)

  return (
    <div className={clsx(className, classes.newAddressDropdown)} ref={addressDropdownRef}>
      <Form
        onSubmit={handleAddressCreate}
        validate={validate}
        render={({ handleSubmit, submitError }) => (
          <>
            <ErrorBlock message={submitError} className={classes.submitError} />
            <Details isModal showNotificationWarning={false} autoFocus />
            <div className={classes.modalButtons}>
              <Button
                className={clsx(classes.button, classes.cancelButton, classes.modalCancelButton)}
                onClick={onCloseDropdown}
                label="Cancel"
                isLoading={isLoading}
              />
              <Button
                className={clsx(classes.button, classes.saveButton, classes.modalSaveButton)}
                onClick={handleSubmit}
                label="Save address"
                isLoading={isLoading}
              />
            </div>
          </>
        )}
      />
    </div>
  )
}

export default memo(AddNewAddress)
