import { acceptUploadTypes, imageTypes } from '@returnmates/client-core/src/constants'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { LabelType } from '@returnmates/client-core/src/graphql/generated/api'
import checkIsBucket from '@returnmates/client-core/src/utils/checkIsBucket'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { digitalLabels } from '../EditPackageForm/constants'
import File from '../MediaData/components/File'
import useStyles from './styles'

interface Props {
  labelType?: {
    value: string
    onChange?: (newValue: string) => void
  }
  labelUrl: {
    value?: string
    onChange: (newValue: string) => void
  }
  setError: (val: string) => void
  labelClassName?: string
  setIsLabelUploaded?: (val: boolean) => void
  error?: boolean | string
  helperText?: string
  bucket?: string
  folder?: string
  region?: string
  acceptUploadTypes?: Array<string>
  dropZoneLabel?: JSX.Element
  dndStyles?: string
  onRemoveFile?: () => void
}

function UploadLabelField({
  labelType = { value: LabelType.DIGITAL },
  labelUrl,
  setError,
  setIsLabelUploaded,
  error,
  bucket,
  folder,
  region,
  acceptUploadTypes: acceptUploadTypesProps,
  dropZoneLabel,
  dndStyles,
  onRemoveFile,
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const uploadInput = useRef<HTMLInputElement>(null)
  const [isImage, setIsImage] = useState<boolean>(false)
  const [signedUrl, setSignedUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const inputAcceptTypes = useMemo(
    () => `.${(acceptUploadTypesProps || acceptUploadTypes).join(',.')}`,
    [acceptUploadTypesProps],
  )

  const signUrl = useCallback(
    async (url: string) => {
      setError('')
      try {
        const extension = url.split('.').pop() || ''
        setIsImage(imageTypes.includes(extension))
        const isBucket = checkIsBucket(url)
        if (!isBucket) {
          setSignedUrl(url)

          setIsLabelUploaded?.(true)

          return
        }

        const signedUrl: string = await createAsyncAction(dispatch, actions.signUrl.request(url))

        setSignedUrl(signedUrl)
        setIsLabelUploaded?.(true)
      } catch (err) {
        setSignedUrl(null)
        setIsLabelUploaded?.(false)

        const { message } = err as Error

        setError(message)
      }
    },
    [dispatch, setError, setIsLabelUploaded],
  )

  const removeFile = useCallback(() => {
    if (onRemoveFile) {
      setSignedUrl(null)
      onRemoveFile()
    } else {
      labelUrl.onChange('')
      setSignedUrl(null)
      setIsLabelUploaded?.(false)
    }
  }, [labelUrl, onRemoveFile, setIsLabelUploaded])

  const clickOnDndZone = useCallback(() => {
    if (!isLoading) {
      setError('')
      uploadInput.current?.click()
    }
  }, [isLoading, setError, uploadInput])

  const uploadFile = useCallback(
    async (file: File, name: string) => {
      setIsLoading(true)
      try {
        const uploadUrl: string = await createAsyncAction(
          dispatch,
          actions.createUploadUrl.request({
            fileName: folder ? `${folder}/${name}` : name,
            contentType: file.type,
            bucket,
            region,
          }),
        )

        const buff = await file.arrayBuffer()

        if (uploadUrl) {
          const s3Res = await fetch(uploadUrl, {
            method: 'PUT',
            body: buff,
            headers: {
              'Content-Type': file.type,
            },
          })

          if (s3Res.status >= 400) {
            throw new Error('Error occurred during file upload')
          }

          const getUrl = uploadUrl.split('?')[0]
          labelUrl.onChange(getUrl)

          signUrl(getUrl)
        }
      } catch (err) {
        const { message } = err as Error
        setError(message)
      } finally {
        setIsLoading(false)
      }

      if (uploadInput.current) {
        uploadInput.current.value = ''
      }
    },
    [dispatch, labelUrl, setError, signUrl, bucket, folder, region],
  )

  const parseFile = useCallback(
    (file: File) => {
      const type =
        file.name
          .split('.')
          .pop()
          ?.toLowerCase() || ''

      if (!(acceptUploadTypesProps || acceptUploadTypes).includes(type)) {
        return
      }

      if (file.size > 1024 * 1024 * 25) {
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.addEventListener('load', () => {
        uploadFile(file, `${uuidv4()}.${type}`)
      })
    },
    [uploadFile, acceptUploadTypesProps],
  )

  const onInputChange = useCallback(() => {
    const uploadFiles = uploadInput.current?.files

    if (uploadFiles?.length) {
      parseFile(uploadFiles[0])
    }
  }, [parseFile])

  const onDrop = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      const uploadFiles = e?.dataTransfer?.files

      if (uploadFiles?.length) {
        parseFile(uploadFiles[0])
      }
    },
    [parseFile],
  )

  const onDragOver = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  useEffect(() => {
    if (labelUrl.value) {
      signUrl(labelUrl.value)
    }
  }, [labelUrl.value, signUrl])

  return (
    <>
      {digitalLabels.includes(labelType.value as LabelType) ? (
        <>
          {signedUrl ? (
            <div className={classes.fileWrapper}>
              <File
                signedUrl={signedUrl}
                isImage={isImage}
                onRemove={removeFile}
                originUrl={signedUrl}
              />
            </div>
          ) : null}
          {!labelUrl.value ? (
            <div
              className={clsx(classes.dnd, dndStyles)}
              onClick={clickOnDndZone}
              onDrop={onDrop}
              onDragOver={onDragOver}
            >
              {isLoading ? <Spinner /> : null}
              {dropZoneLabel ? (
                dropZoneLabel
              ) : (
                <>
                  Drag & Drop file here or <span> Browse file</span>
                </>
              )}
            </div>
          ) : null}
        </>
      ) : null}
      <input
        type="file"
        className={classes.uploadInput}
        ref={uploadInput}
        accept={inputAcceptTypes}
        onChange={onInputChange}
      />
      {error ? <p className={classes.error}>{error}</p> : null}
    </>
  )
}

export default memo(UploadLabelField)
