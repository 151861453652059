import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { INTER, POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  calendarWrapper: {
    position: 'relative',
    zIndex: 102,
  },
  tooltip: {
    fontFamily: POPPINS,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '160%',
    color: '#FFFFFF',
    backgroundColor: 'rgba(102, 109, 120, 0.94)',
    marginTop: 15,
  },
  iconButton: {
    padding: 0,
    marginLeft: '15px',
  },
  calendarIcon: {
    width: 18,
    height: 18,
  },
  modalButtons: {
    display: 'flex',
    margin: '0 25px 20px',
  },
  toolbarDatePicker: {
    position: 'absolute',
    left: '-320px',
    top: 0,
    boxShadow: '0px 19px 24px rgba(0, 0, 0, 0.12)',
    border: `1px solid ${SOLITUDE}`,
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  button: {
    textTransform: 'capitalize',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '130%',
    borderRadius: '4px',
    padding: '10px 0',
    width: '130px',
  },
  cancelButton: {
    marginRight: '10px',
    marginLeft: 'auto',
    backgroundColor: ALICE_BLUE,
    border: `1px solid ${SOLITUDE}`,
    color: MIDNIGHT_EXPRESS,
    '&:hover': {
      backgroundColor: ALICE_BLUE,
    },
  },
  applyButton: {
    backgroundColor: NEON_BLUE_LIGHT,
    border: `1px solid ${NEON_BLUE_LIGHT}`,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: NEON_BLUE_LIGHT,
    },
  },
  scheduleContainer: {
    margin: '-60px 25px 20px',
  },
  scheduleText: {
    fontSize: 12,
    fontFamily: INTER,
    fontWeight: 400,
  },
  hidden: {
    visibility: 'hidden',
  },
}))

export default useStyles
