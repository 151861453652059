import { Sorting } from '@devexpress/dx-react-grid'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { AdminPackage, Trip } from '@returnmates/client-core/src/graphql/generated/api'
import {
  getPackageTableColumnWidth,
  getPartners as getPartnersSelector,
} from '@returnmates/client-core/src/selectors/admin'
import { CustomAdminPackage } from '@returnmates/client-core/src/type/pickups'
import checkIsBucket from '@returnmates/client-core/src/utils/checkIsBucket'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import moment from 'moment'
import { ElementType, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EditType } from '../../pages/Main/types'
import { Filter } from '../../pages/Packages'
import GridTable from '../GridTable'
import { OptionType, TableColumn } from '../GridTable/types'
import Toolbar from './components/Toolbar'
import {
  defaultPackageColumnWidths,
  formattedColumnNames,
  packageColumns as basicPackageColumns,
  pickupsPackageColumns,
  sortDescByName,
  tableColumnExtensions,
} from './constants'
import ProviderFormatter from './ProviderFormatter'

interface Props {
  packages: AdminPackage[] | CustomAdminPackage[]
  columns?: Array<TableColumn>
  isNestedTable?: boolean
  showToolbar: boolean
  showDetails?: boolean
  showPaging?: boolean
  showSorting?: boolean
  isLoading: boolean
  canEditRow?: boolean
  canDeleteRow?: boolean
  detailsComponent?: ElementType
  editOnRowClick?: boolean
  setEditableRow?: (val: Trip | AdminPackage) => void
  onEditPackageClick?: (row: AdminPackage) => void
  onPageSizeChange?: (size: number) => void
  onCurrentPageChange?: (page: number) => void
  currentPageNumber?: number
  totalCount?: number
  onSortingChange?: (sort: Sorting) => void
  onFilterUpdate?: (val: Filter) => void
  setExpandedRowIds?: (val: string[]) => void
  onRemovePackage?: (id: string) => void
  expandedRowIds?: string[]
  defaultFilter?: {
    [key: string]: boolean
  }
  setSelectedRows?: (values: string[]) => void
  selectedRows?: string[]
  refreshPackages?: () => void
  canSelectRow?: boolean
  tabIndex?: EditType
}

function PackagesTable({
  columns,
  packages,
  showToolbar,
  showDetails = false,
  showPaging,
  showSorting,
  canEditRow = false,
  canDeleteRow = false,
  isLoading,
  detailsComponent: DetailsComponent,
  editOnRowClick,
  onEditPackageClick,
  onCurrentPageChange,
  currentPageNumber,
  setEditableRow,
  onPageSizeChange,
  totalCount,
  onSortingChange,
  onFilterUpdate,
  setExpandedRowIds,
  defaultFilter,
  expandedRowIds,
  onRemovePackage,
  setSelectedRows,
  selectedRows,
  refreshPackages,
  canSelectRow = true,
  tabIndex,
}: Props) {
  const [packagesData, setPackagesData] = useState([])
  const [packageColumns, setPackageColumns] = useState<TableColumn[]>(basicPackageColumns)
  const [idForRemove, setIdForRemove] = useState('')
  const [isTripUpdatedLoading, setIsTripUpdateLoading] = useState(false)
  const packageTableColumnWidth = useSelector(getPackageTableColumnWidth)
  const partners = useSelector(getPartnersSelector)
  const dispatch = useDispatch()

  const columnSize = useMemo(
    () => (packageTableColumnWidth ? packageTableColumnWidth : defaultPackageColumnWidths),
    [packageTableColumnWidth],
  )

  const updatePackageData = useCallback(
    async (packages: Array<AdminPackage>) => {
      if (packages) {
        const newPackages = await Promise.all(
          packages.map(async pack => {
            if (pack.labelUrl && checkIsBucket(pack.labelUrl)) {
              const signedUrl = await createAsyncAction(
                dispatch,
                actions.signUrl.request(pack.labelUrl),
              )

              return {
                ...pack,
                labelType: {
                  label: pack.labelType,
                  url: signedUrl,
                },
                partnerCode:
                  partners.find(partner => partner.code === pack.partnerCode)?.name ||
                  'Returnmates',
                partner: pack.partnerCode,
                carrier: pack.carrierName,
                retailer: pack.retailerName,
                driver: pack.worker?.name || '',
                locationName: pack.locationName || '',
                date: moment(pack.date).format('MM-DD-yyyy'),
                packageDate: pack.packageDate
                  ? moment(pack.packageDate).format('MM-DD-yyyy')
                  : null,
                expectedDate: pack.expectedDate
                  ? moment(pack.expectedDate).format('MM-DD-yyyy')
                  : null,
              }
            }

            return {
              ...pack,
              labelType: {
                label: pack.labelType,
                url: pack.labelUrl,
              },
              partnerCode:
                partners.find(partner => partner.code === pack.partnerCode)?.name || 'Returnmates',
              partner: pack.partnerCode,
              carrier: pack.carrierName,
              retailer: pack.retailerName,
              driver: pack.worker?.name || '',
              locationName: pack.locationName || '',
              date: moment(pack.date).format('MM-DD-yyyy'),
              packageDate: pack.packageDate ? moment(pack.packageDate).format('MM-DD-yyyy') : null,
              expectedDate: pack.expectedDate
                ? moment(pack.expectedDate).format('MM-DD-yyyy')
                : null,
            }
          }),
        )

        // @ts-ignore
        setPackagesData(newPackages)
      }
    },
    [dispatch, partners],
  )

  const onColumnWidthsChange = useCallback(
    newTableSize => dispatch(actions.setPackageTableColumnWidth.request(newTableSize)),
    [dispatch],
  )

  useEffect(() => {
    if (packages && packages.length) {
      updatePackageData(packages)
    }

    return () => {
      setPackagesData([])
    }
  }, [updatePackageData, packages])

  useEffect(() => {
    if (!columns) {
      if (tabIndex == EditType.Pickup) {
        return setPackageColumns(pickupsPackageColumns)
      } else {
        return setPackageColumns(basicPackageColumns)
      }
    } else {
      setPackageColumns(columns as TableColumn[])
    }
  }, [columns, tabIndex])

  useEffect(() => {
    if (idForRemove && onRemovePackage) {
      onRemovePackage(idForRemove)
      return
    }
  }, [idForRemove, onRemovePackage])

  return (
    <>
      {showToolbar ? (
        <Toolbar
          selectedRowsProp={selectedRows}
          setSelectedRowsProp={setSelectedRows}
          onFilterUpdate={onFilterUpdate}
          refreshPackages={refreshPackages}
          packagesData={packagesData}
          defaultFilter={defaultFilter}
          isLoading={isLoading}
          isTripUpdatedLoading={isTripUpdatedLoading}
          setIsTripUpdateLoading={setIsTripUpdateLoading}
          tabIndex={tabIndex}
        />
      ) : null}

      <GridTable
        columns={packageColumns}
        data={packagesData || []}
        formattedColumnNames={formattedColumnNames}
        tableColumnExtensions={tableColumnExtensions}
        isLoading={isLoading || isTripUpdatedLoading}
        setEditableRow={setEditableRow}
        canEditRow={canEditRow}
        editOnRowClick={editOnRowClick}
        canDeleteRow={canDeleteRow}
        setIdForRemove={setIdForRemove}
        showDetails={showDetails}
        detailsComponent={DetailsComponent}
        formatterComponent={ProviderFormatter}
        detailsProps={{
          onEditPackageClick: onEditPackageClick,
        }}
        showPaging={showPaging}
        showSorting={showSorting}
        onPageSizeChange={onPageSizeChange}
        onCurrentPageChange={onCurrentPageChange}
        currentPageNumber={currentPageNumber}
        totalCount={totalCount}
        canSelectRow={canSelectRow}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        isNetworkFiltering
        onSortingChange={onSortingChange}
        expandedRowIds={expandedRowIds}
        setExpandedRowIds={setExpandedRowIds}
        defaultColumnWidths={columnSize}
        onColumnWidthsChange={onColumnWidthsChange}
        sortDescByName={sortDescByName}
        optionType={OptionType.PACKAGE}
      />
    </>
  )
}

export default memo(PackagesTable)
