import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import Badge from '@mui/material/Badge'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import Popper from '@mui/material/Popper'
import { AsyncJobData, AsyncJobDataType } from '@returnmates/client-core/src/graphql/generated/api'
import { memo, useCallback, useMemo, useState } from 'react'
import { Range } from 'react-date-range'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import {
  AUDITS,
  COMMUNICATIONS,
  CONSOLIDATIONS,
  DASHBOARD,
  LOCATIONS,
  MAIN,
  MANIFEST,
  OPERATIONS,
  PACKAGE_LOG,
  PARTNERS,
  PRINT,
  QR,
  TRIPS_LOG,
  USERS,
  ZIP_CODES,
} from '../../constants/paths'
import DatePicker from '../DatePicker'
import HubDropdown from '../HubDropdown'
import Hamburger from '../images/icons/hamburger'
import SmallLogo from '../images/smallLogo'
import NotificationListItem from './components/NotificationListItem'
import useStyles from './styles'
interface Props {
  currentHubId: string | null
  setCurrentHubId: (val: string) => void
  setCurrentDateRange: (range: Range[]) => void
  currentDateRange: Range[]
  notifications: Array<AsyncJobData | null> | null
}

function Header({
  currentHubId,
  setCurrentHubId,
  setCurrentDateRange,
  currentDateRange,
  notifications,
}: Props) {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const pathname = useMemo(() => '/'.concat(location.pathname.split('/')[1]), [location.pathname])

  const isOperationPage = useMemo(() => pathname === OPERATIONS, [pathname])
  const isAuditPage = useMemo(() => pathname === AUDITS, [pathname])

  const classes = useStyles({ isOperationPage, isAuditPage })

  notifications =
    notifications?.filter(
      asyncJobData =>
        asyncJobData?.type !== AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION &&
        asyncJobData?.type !== AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION_INITIAL,
    ) || []

  notifications =
    notifications?.filter(
      asyncJobData =>
        asyncJobData?.type !== AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION &&
        asyncJobData?.type !== AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION_INITIAL,
    ) || []

  notifications =
    notifications?.filter(
      asyncJobData =>
        asyncJobData?.type !== AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION &&
        asyncJobData?.type !== AsyncJobDataType.ON_WORKER_ROUTE_PRICING_CALCULATION_INITIAL,
    ) || []

  const handleOpenNotificationList = useCallback(event => {
    setAnchorEl(event.currentTarget)
    setOpen(prev => !prev)
  }, [])

  const handleClickAway = useCallback(() => {
    setOpen(false)
  }, [])

  const title = useMemo(() => {
    switch (pathname) {
      case DASHBOARD:
        return 'Dashboard'
      case TRIPS_LOG:
        return 'Trips'
      case PACKAGE_LOG:
        return 'Packages'
      case CONSOLIDATIONS:
        return 'Consolidations'
      case USERS:
        return 'Users'
      case ZIP_CODES:
        return 'Zip Codes'
      case QR:
        return 'Barcode Scanner'
      case COMMUNICATIONS:
        return 'Communications'
      case PARTNERS:
        return 'Partners'
      case PRINT:
        return 'Print'
      case MANIFEST:
        return 'Manifests'
      case OPERATIONS:
        return 'Operations'
      case LOCATIONS:
        return 'Locations'
      case AUDITS:
        return 'Audits'
      default:
        return 'Dashboard'
    }
  }, [pathname])

  return (
    <header className={classes.root}>
      <div className={classes.main}>
        <div className={classes.logoWrapper}>
          <Link to={MAIN}>
            <SmallLogo />
          </Link>
        </div>
        <div className={classes.leftPart}>
          <Hamburger />
          <span className={classes.title}>{title}</span>
        </div>

        <div className={classes.rightPart}>
          {!isAuditPage ? (
            <>
              <DatePicker
                setCurrentDateRange={setCurrentDateRange}
                currentDateRange={currentDateRange}
                isOperationPage={isOperationPage}
              />

              {!isOperationPage ? (
                <HubDropdown currentHubId={currentHubId} setCurrentHubId={setCurrentHubId} />
              ) : null}
            </>
          ) : null}
        </div>

        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.notificationBlock}>
            <IconButton onClick={handleOpenNotificationList}>
              <Badge badgeContent={notifications?.length} color="error">
                <NotificationsNoneOutlinedIcon className={classes.notificationIcon} />
              </Badge>
            </IconButton>

            <Popper
              open={open}
              anchorEl={anchorEl}
              transition
              placement={'bottom-end'}
              className={classes.poppover}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <div className={classes.listWrap}>
                    <List className={classes.notificationList}>
                      {notifications?.length ? (
                        notifications?.map(asyncJobData => (
                          <NotificationListItem
                            key={asyncJobData?.id}
                            asyncJobData={asyncJobData}
                          />
                        ))
                      ) : (
                        <p className={classes.emptyState}>The Notification List Is Empty</p>
                      )}
                    </List>
                  </div>
                </Fade>
              )}
            </Popper>
          </div>
        </ClickAwayListener>
      </div>
    </header>
  )
}

export default memo(Header)
