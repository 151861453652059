export const MAIN = '/'
export const DASHBOARD = '/dashboard'
export const TRIPS_LOG = '/trips'
export const PACKAGE_LOG = '/packages'
export const USERS = '/users'
export const USER_PAGE = '/users/:id'
export const QR = '/qr'
export const MANIFEST = '/manifests'
export const MANIFEST_PAGE = '/manifests/:manifestId'
export const OPERATIONS = '/operations'
export const ZIP_CODES = '/zip-codes'
export const COMMUNICATIONS = '/communications'
export const PARTNERS = '/partners'
export const CONSOLIDATIONS = '/consolidations'
export const PRINT = '/print'
export const LOCATIONS = '/locations'
export const OAUTH = '/oauth'
export const AUDITS = '/audits'
