import { Auth, CognitoHostedUIIdentityProvider } from '@returnmates/client-core/src/amplify'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { SnackBarStatuses } from '@returnmates/client-core/src/type'
import errorMapper from '@returnmates/client-core/src/utils/errorMapper'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import qs from 'query-string'
import { memo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { MAIN } from '../../constants/paths'
import useStyles from './styles'

function OauthLogin() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const login = useCallback(async () => {
    try {
      await createAsyncAction(dispatch, actions.refresh.request())
      navigate(MAIN)
    } catch (err) {
      const { message } = err as Error

      dispatch(
        actions.addSnackBar.request({
          type: SnackBarStatuses.ERROR,
          message: errorMapper(message || (err as string)),
        }),
      )
      navigate(MAIN)
    }
  }, [dispatch, navigate])

  useEffect(() => {
    if (qs.parse(location.search)?.code) {
      login()
    } else if (
      qs.parse(location.search)?.error_description?.includes('Already found an entry for username')
    ) {
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
    } else {
      navigate(MAIN)
    }
  }, [dispatch, navigate, location, login])

  return (
    <div className={classes.spinnerHandler}>
      <Spinner />
    </div>
  )
}

export default memo(OauthLogin)
