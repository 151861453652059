import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import useStyles from './styles'

interface Props {
  handleIsActive: (location: string, path: string, index: number) => boolean
  item: {
    icon: ({ className }: { className: string }) => JSX.Element
    activeIcon: ({ className }: { className: string }) => JSX.Element
    path: string
  }
  index: number
  newActiveLink: number
  onClick: () => void
}

function NavLinkWrap({ item: Item, index, newActiveLink, handleIsActive, onClick }: Props) {
  const classes = useStyles()
  const location = useLocation()

  const checkIsActive = useCallback(
    ({ isActive }) => clsx(classes.link, { [classes.active]: isActive }),
    [classes.active, classes.link],
  )

  const isActive = useMemo(() => newActiveLink !== index, [index, newActiveLink])

  useEffect(() => {
    handleIsActive(location.pathname, Item.path, index)
  }, [Item.path, handleIsActive, index, location.pathname])

  return (
    <NavLink key={Item.path} onClick={onClick} to={Item.path} className={checkIsActive}>
      {isActive ? (
        <Item.icon className={classes.linkIcon} />
      ) : (
        <Item.activeIcon className={clsx(classes.linkIcon, classes.activeIcon)} />
      )}
    </NavLink>
  )
}

export default memo(NavLinkWrap)
