interface Props {
  className?: string
}

const PendingIcon = ({ className }: Props): JSX.Element => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="11.25" stroke="#FFD75E" strokeWidth="1.5" />
    <path
      d="M11 8V14L15 16"
      stroke="#FFD75E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PendingIcon
