import {
  AUDITS,
  COMMUNICATIONS,
  CONSOLIDATIONS,
  DASHBOARD,
  MAIN,
  MANIFEST,
  OPERATIONS,
  PACKAGE_LOG,
  QR,
  TRIPS_LOG,
  USERS,
} from './paths'

export const cognitoGroups = {
  admin: 'admin',
  partner: 'partner',
  manager: 'manager',
  operations: 'operations',
  warehouse: 'warehouse',
  driver: 'driver',
  auditor: 'auditor',
  saver: 'saver',
}

export const connectPartnerAccessRoles = [
  cognitoGroups.admin,
  cognitoGroups.partner,
  cognitoGroups.manager,
]

export const managerPaths = [
  MAIN,
  DASHBOARD,
  TRIPS_LOG,
  PACKAGE_LOG,
  USERS,
  QR,
  MANIFEST,
  OPERATIONS,
  COMMUNICATIONS,
  CONSOLIDATIONS,
  AUDITS,
]
export const operationsPaths = [
  MAIN,
  DASHBOARD,
  TRIPS_LOG,
  PACKAGE_LOG,
  USERS,
  QR,
  CONSOLIDATIONS,
  COMMUNICATIONS,
  AUDITS,
]
export const warehousePaths = [
  AUDITS,
  MAIN,
  DASHBOARD,
  TRIPS_LOG,
  PACKAGE_LOG,
  QR,
  CONSOLIDATIONS,
  USERS,
  COMMUNICATIONS,
  AUDITS,
]

export const auditorPaths = [AUDITS]

export const saverPaths = [AUDITS]
