import { acceptUploadTypes, imageTypes } from '@returnmates/client-core/src/constants'
import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { base64 } from '@returnmates/client-core/src/constants/regExp'
import { Media } from '@returnmates/client-core/src/graphql/generated/api'
import checkIsBucket from '@returnmates/client-core/src/utils/checkIsBucket'
import { createAsyncAction } from '@returnmates/client-core/src/utils/reduxUtils'
import Button from '@returnmates/ui-core/src/components/Button'
import Spinner from '@returnmates/ui-core/src/components/Spinner'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import useIsMobile from '../../hooks/useIsMobile'
import Camera from '../images/icons/camera'
import File from './components/File'
import useStyles from './styles'

interface Props {
  onMediaChange: (media: Array<Media>) => void
  media: Array<Media>
  isOpen: boolean
}

const width = 1280
const height = 720

function MediaData({ media, onMediaChange, isOpen }: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [localUrls, setLocalUrls] = useState<
    Array<{ originUrl: string; signedUrl: string; isImage: boolean }>
  >([])
  const [isUploadStart, setIsUploadStart] = useState(false)
  const [isCanvasEnabled, setIsCanvasEnabled] = useState(false)
  const [isVideoEnabled, setIsVideoEnabled] = useState(true)
  const [data, setData] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [stream, setStream] = useState<MediaStream>()
  const [error, setError] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMobile = useIsMobile()
  const cashedUrls = useRef<Array<{ originUrl: string; signedUrl: string; isImage: boolean }>>()

  const signUrls = useCallback(
    async (media: Array<Media>) => {
      if (typeof media !== 'object') {
        return
      }

      const newLocalUrls = await Promise.all(
        media.map(async mediaItem => {
          try {
            const existLocalUrl = cashedUrls.current?.find(url => url.originUrl === mediaItem.url)
            if (existLocalUrl) {
              return existLocalUrl
            }

            const isBucket = checkIsBucket(mediaItem.url || '')
            const extension = mediaItem.url?.split('.').pop() || ''
            const isImage = imageTypes.includes(extension)

            if (!isBucket) {
              return {
                isImage,
                signedUrl: mediaItem.url,
                originUrl: mediaItem.url,
              }
            }

            const signedUrl: string = await createAsyncAction(
              dispatch,
              actions.signUrl.request(mediaItem.url),
            )

            return {
              isImage,
              signedUrl,
              originUrl: mediaItem.url,
            }
          } catch (err) {
            const { message } = err as Error
            setError(message)
          }
        }),
      )

      if (newLocalUrls) {
        const filteredUrls = newLocalUrls.filter(val => val) as Array<{
          originUrl: string
          signedUrl: string
          isImage: boolean
        }>

        cashedUrls.current = filteredUrls

        setLocalUrls(filteredUrls)
      }
    },
    [dispatch],
  )

  const startUpload = useCallback(() => {
    setIsUploadStart(true)
  }, [])

  const takePicture = useCallback(() => {
    if (canvasRef.current) {
      setIsCanvasEnabled(true)
      const context = canvasRef.current.getContext('2d')

      canvasRef.current.width = width
      canvasRef.current.height = height
      context?.drawImage(videoRef.current as HTMLVideoElement, 0, 0, width, height)

      const data = canvasRef.current.toDataURL('image/png')

      setData(data.replace(base64, ''))

      stream?.getTracks().forEach(function(track) {
        track.stop()
      })
      setIsVideoEnabled(false)
    }
  }, [stream])

  const removeUrl = useCallback(
    url => {
      onMediaChange(media.filter(mediaItem => mediaItem.url !== url))
    },
    [onMediaChange, media],
  )

  const returnToVideo = useCallback(() => {
    setIsCanvasEnabled(false)
    setIsVideoEnabled(true)
  }, [])

  const upload = useCallback(async () => {
    setIsLoading(true)

    try {
      const name = uuidv4()
      const uploadUrl: string = await createAsyncAction(
        dispatch,
        actions.createUploadUrl.request({ fileName: `${name}.png`, contentType: 'image/png' }),
      )

      const buf = Buffer.from(data || '', 'base64')

      if (uploadUrl) {
        const s3Res = await fetch(uploadUrl, {
          method: 'PUT',
          body: buf,
          headers: {
            'Content-Type': 'image/png',
          },
        })

        if (s3Res.status >= 400) {
          throw new Error('Error occurred during file upload')
        }

        const getUrl = uploadUrl.split('?')[0]
        onMediaChange([...media, { url: getUrl } as Media])

        setIsUploadStart(false)
        setIsCanvasEnabled(false)
        setIsVideoEnabled(true)
      }
    } catch (err) {
      const { message } = err as Error
      setError(message)
    } finally {
      setIsLoading(false)
    }
  }, [dispatch, setError, data, media, onMediaChange])

  const uploadFile = useCallback(
    async (file: File, name: string) => {
      try {
        const uploadUrl: string = await createAsyncAction(
          dispatch,
          actions.createUploadUrl.request({ fileName: name, contentType: file.type }),
        )

        const buff = await file.arrayBuffer()

        if (uploadUrl) {
          const s3Res = await fetch(uploadUrl, {
            method: 'PUT',
            body: buff,
            headers: {
              'Content-Type': file.type,
            },
          })

          if (s3Res.status >= 400) {
            throw new Error('Error occurred during file upload')
          }

          const getUrl = uploadUrl.split('?')[0]

          onMediaChange([...media, { url: getUrl } as Media])
        }
      } catch (err) {
        const { message } = err as Error

        setError(message)
      } finally {
        setIsLoading(false)

        if (inputRef?.current?.value) {
          inputRef.current.value = ''
        }
      }
    },
    [dispatch, onMediaChange, media],
  )

  const makePhoto = useCallback(() => {
    if (isMobile) {
      inputRef.current?.click()
    } else {
      startUpload()
    }
  }, [isMobile, startUpload])

  const clickOnDndZone = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const cancelPhoto = useCallback(() => {
    setIsUploadStart(false)
  }, [])

  const parseFile = useCallback(
    (file: File) => {
      setIsLoading(true)
      setError('')
      const type =
        file.name
          .split('.')
          .pop()
          ?.toLowerCase() || ''

      if (!acceptUploadTypes.includes(type)) {
        setError('Wrong file type')
        return
      }

      if (file.size > 1024 * 1024 * 25) {
        setError('File size should be less than 25mb')
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.addEventListener('load', () => {
        uploadFile(file, `${uuidv4()}.${type}`)
      })
    },
    [uploadFile],
  )

  const onInputChange = useCallback(() => {
    const uploadFiles = inputRef.current?.files

    if (uploadFiles?.length) {
      for (let i = 0; i < uploadFiles.length; i++) {
        parseFile(uploadFiles[i])
      }
    }
  }, [parseFile])

  const onDrop = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      const uploadFiles = e?.dataTransfer?.files

      if (uploadFiles?.length) {
        parseFile(uploadFiles[0])
      }
    },
    [parseFile],
  )

  const onDragOver = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  useEffect(() => {
    signUrls(media)
  }, [media, signUrls])

  useEffect(() => {
    if (isUploadStart && isVideoEnabled) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: { min: 1280 }, height: { min: 720 } }, audio: false })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream
            videoRef.current.play()
            setStream(stream)
          }
        })
        .catch(err => {
          setError(err?.message)
        })
      setTimeout(() => {
        navigator.mediaDevices.enumerateDevices()
      }, 300)
    }
  }, [isUploadStart, isVideoEnabled])

  useEffect(() => {
    if (!isOpen) {
      setIsUploadStart(false)
      setIsCanvasEnabled(false)
      setIsVideoEnabled(true)
      setError('')

      stream?.getTracks()?.forEach(track => {
        track.stop()
      })
    }
  }, [stream, isOpen])

  useEffect(() => {
    return () => {
      stream?.getTracks()?.forEach(track => {
        track.stop()
      })
    }
  }, [stream, isUploadStart])

  return (
    <>
      <div>
        {localUrls?.map(localUrl => (
          <File
            key={localUrl.signedUrl}
            signedUrl={localUrl.signedUrl}
            isImage={localUrl.isImage}
            onRemove={removeUrl}
            originUrl={localUrl.originUrl}
          />
        ))}
        <div className={classes.cameraWrapper}>
          {isLoading ? <Spinner size={28} withLogo={false} /> : null}
          <Camera className={classes.cameraIcon} onClick={makePhoto} />
        </div>
      </div>
      {isUploadStart ? (
        <div className={classes.videoHandler}>
          <canvas
            ref={canvasRef}
            className={clsx(classes.image, { [classes.notVisible]: !isCanvasEnabled })}
          />
          <video
            ref={videoRef}
            className={clsx(classes.image, { [classes.notVisible]: !isVideoEnabled })}
          >
            Video stream not available.
          </video>
          {isVideoEnabled ? (
            <div className={classes.mediaButtonWrapper}>
              <Button
                label="Cancel"
                onClick={cancelPhoto}
                className={clsx(classes.mediaButtonCancel, classes.mediaButton)}
              />
              <Button label="Make a photo" onClick={takePicture} className={classes.mediaButton} />
            </div>
          ) : null}
          {isCanvasEnabled ? (
            <div className={classes.mediaButtonWrapper}>
              <Button
                label="Cancel"
                onClick={returnToVideo}
                className={clsx(classes.mediaButtonCancel, classes.mediaButton)}
                isLoading={isLoading}
              />
              <Button
                label="Save"
                onClick={upload}
                className={classes.mediaButton}
                isLoading={isLoading}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={classes.dnd} onClick={clickOnDndZone} onDrop={onDrop} onDragOver={onDragOver}>
        {isLoading ? <Spinner /> : null}
        Drag & Drop files here or <span> Browse files</span>
      </div>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        className={classes.uploadInput}
        ref={inputRef}
        onChange={onInputChange}
      />
      {error ? <p className={classes.error}>{error}</p> : null}
    </>
  )
}

export default memo(MediaData)
