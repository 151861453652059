import { Consolidation } from '@returnmates/client-core/src/graphql/generated/api'
import { returnmatesUrl } from '@returnmates/ui-core/src/constants/paths'
import moment from 'moment'

import {
  containerLabelZplImageDpi203,
  containerLabelZplImageDpi300,
  printDensityOptions,
  zplTemplateImage,
} from '../constants'

interface Props {
  row: Consolidation & { date: string }
}

const containerLabelDpi300 = ({ row }: Props) => `
^XA

^FX Fourth section (the two boxes on the bottom).
^FO0,0^GB1218,1827,60^FS

^FO440,1200 
^BQN,2,10
^FDQA,${JSON.stringify({ container_id: row.id })}
^FS

^CF0,150
^FWB^FO150,150^FD${row.hubOriginSortCode}^FS

^CF0,435
^FWB^FO480,270^FD${row.hubDestinationSortCode}^FS

^CF0,60
^FWB^FO1012,360^FD${row.id}^FS
${containerLabelZplImageDpi300}
^XZ`

const containerLabelDpi203 = ({ row }: Props) => `
^XA

^FX Fourth section (the two boxes on the bottom).
^FO0,0^GB812,1218,40^FS

^FO240,730
^BQN,2,10
^FDQA,${JSON.stringify({ container_id: row.id })}
^FS

^CF0,100
^FWB^FO100,100^FD${row.hubOriginSortCode}^FS

^CF0,290
^FWB^FO320,180^FD${row.hubDestinationSortCode}^FS

^CF0,40
^FWB^FO675,240^FD${row.id}^FS
${containerLabelZplImageDpi203}
^XZ`

const rmLabelDpi300 = ({ row }: Props, formattedID: string, qrCodeUrl: string) => `
^XA
^LH10,10
^LS0
^MTD
^PW1200
^FX Pickup Details
^FWN,0
^CF0,60
^FO20,50
^FD${row.partner.name}
^FS
^CF0,40
^FO20,133
^FD${row.address.name}
^FS
^FO20,192
^FD${row.address.street} ${row.address.unit ? row.address.unit : ''}
^FS^FO20,251
^FD${row.address.city}, ${row.address.state} ${row.address.zipCode}
^FS
^FO20,325
^GB1141,4,4
^FS
^FO20,384
^FDCARRIER: ${row.carrier?.name ? row.carrier?.name : '-'}
^FS
^FO20,473
^FDCREATED: ${moment.unix(row.createdAt).format('MM-DD-yyyy')}
^FS
^FO20,562
^FDDEPARTURE DATE: ${row.expectedDeliveryDate ? row.expectedDeliveryDate : '-'}
^FS
^FO20,1330
^FDID: ${row.id}
^FS^FO20,1389
^BY3,2,120
^BC,,N
^FD${formattedID}
^FS
^FO900,700
^BQN,2,6
^FDQA,${qrCodeUrl}
^FS
^FO700,1650
^GFA,5600,5600,56,${zplTemplateImage}
^FS
^XZ`

const rmLabelDpi203 = ({ row }: Props, formattedID: string, qrCodeUrl: string) => `
^XA
^LH10,10
^LS0
^MTD
^PW812
^FX Pickup Details
^FWN,0
^CF0,40
^FO20,30
^FD${row.partner.name}
^FS
^CF0,30
^FO20,90
^FD${row.address.name}
^FS
^FO20,130
^FD${row.address.street} ${row.address.unit ? row.address.unit : ''}
^FS^FO20,170
^FD${row.address.city}, ${row.address.state} ${row.address.zipCode}
^FS
^FO20,220
^GB812,3,3
^FS
^FO20,260
^FDCARRIER: ${row.carrier?.name ? row.carrier?.name : '-'}
^FS
^FO20,320
^FDCREATED: ${moment.unix(row.createdAt).format('MM-DD-yyyy')}
^FS
^FO20,380
^FDDEPARTURE DATE: ${row.expectedDeliveryDate ? row.expectedDeliveryDate : '-'}
^FS
^FO20,890
^FDID: ${row.id}
^FS^FO20,930
^BY2,2,80
^BC,,N
^FD${formattedID}
^FS
^FO600,470
^BQN,2,4
^FDQA,${qrCodeUrl}
^FS
^FO200,1070
^GFA,5600,5600,56,${zplTemplateImage}
^FS
^XZ`

export const getRMLabel = ({ row }: Props, printDensity: printDensityOptions): string => {
  const formattedID = row.id.split('-').join('')
  const qrCodeUrl = `${returnmatesUrl}/consolidation/${row.id}`

  let rmLabel
  switch (printDensity) {
    case printDensityOptions.dpi203:
      rmLabel = rmLabelDpi203({ row }, formattedID, qrCodeUrl)
      break
    case printDensityOptions.dpi300:
      rmLabel = rmLabelDpi300({ row }, formattedID, qrCodeUrl)
      break
    default:
      rmLabel = rmLabelDpi203({ row }, formattedID, qrCodeUrl)
  }

  return rmLabel
}

export const getContainerLabel = ({ row }: Props, printDensity: printDensityOptions): string => {
  let containerLabel
  switch (printDensity) {
    case printDensityOptions.dpi203:
      containerLabel = containerLabelDpi203({ row })
      break
    case printDensityOptions.dpi300:
      containerLabel = containerLabelDpi300({ row })
      break
    default:
      containerLabel = containerLabelDpi203({ row })
  }

  return containerLabel
}
